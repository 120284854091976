import { Box, Heading, Text } from '@chakra-ui/layout'
import { captureException } from '@sentry/minimal'
import React from 'react'
import { Trans } from 'react-i18next'

type DashboardErrorBoundaryProps = {}

type DashboardErrorBoundaryState = {
  hasError: boolean
}

class DashboardErrorBoundary extends React.Component<
  DashboardErrorBoundaryProps,
  DashboardErrorBoundaryState
> {
  state: DashboardErrorBoundaryState = {
    hasError: false,
  }
  constructor(props: DashboardErrorBoundaryProps) {
    super(props)
    this.state.hasError = false
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('ERROR: Captured an unhandled error, reporting it...')
    captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box p={{ base: '16px', md: '30px', lg: '40px' }}>
          <Heading mb={4}>
            <Trans i18nKey="dashboard-error-boundary.something-went-wrong.title">
              Something went wrong
            </Trans>
          </Heading>
          <Text>
            <Trans i18nKey="dashboard-error-boundary.something-went-wrong.description">
              The error has been captured and reported to our systems, please
              try to refresh the page and try again. If the error persists, you
              can contact us by e-mail at info@wemuda.com, or on the phone at
              +45 61 79 66 92.
            </Trans>
          </Text>
        </Box>
      )
    }

    return this.props.children
  }
}

export default DashboardErrorBoundary
