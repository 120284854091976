import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, VStack } from '@chakra-ui/react'
import { HiMail } from 'react-icons/hi'
import {
  PageHeader,
  ActionContainer,
  CButton,
} from '../../../../components/index'
import { FaPhone } from 'react-icons/fa'

interface CitizensScreenProps {}

const CitizensScreen: React.FC<CitizensScreenProps> = () => {
  const { t } = useTranslation()

  return (
    <Box p={{ base: '16px', md: '30px', lg: '40px' }}>
      <PageHeader sampleText="Support" title={t('support.title')} />
      <ActionContainer
        title={t('support.contact.title')}
        description={t('support.contact.description')}
        alignItems="flex-end"
      >
        <VStack alignItems="flex-end">
          <CButton
            btnType="tinted"
            onClick={() => {
              var mail = `mailto:lq@kibodan.dk?subject=${t(
                'support.contact.email-subject'
              )}`
              var a = document.createElement('a')
              a.href = mail
              a.target = '_blank'
              a.click()
            }}
            leftIcon={<HiMail size="16px" />}
          >
            Email
          </CButton>
          <CButton
            btnType="tinted"
            onClick={() => {
              var tel = `tel:+45599100`
              var a = document.createElement('a')
              a.href = tel
              a.target = '_blank'
              a.click()
            }}
            leftIcon={<FaPhone size="14px" />}
          >
            +45 25 59 91 00
          </CButton>
        </VStack>
      </ActionContainer>
    </Box>
  )
}

export default CitizensScreen
