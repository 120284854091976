import { Box, Center, VStack } from '@chakra-ui/layout'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../components/FormInput'
import * as yup from 'yup'
import { useForgotPasswordMutation } from '../../../redux/services/mio-api'
import { useToast } from '@chakra-ui/toast'
import { CButton, CLink, CText } from '../../../components/index'

interface ForgotPasswordScreenProps {}

interface ForgotPasswordFormData {
  email: string
}

const ForgotPasswordScreen: React.FC<ForgotPasswordScreenProps> = () => {
  const { t } = useTranslation()
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()
  const toast = useToast()

  const schema: yup.SchemaOf<ForgotPasswordFormData> = yup.object({
    email: yup
      .string()
      .email(t('validation.must-be-valid-email'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({ resolver: yupResolver(schema) })

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = data => {
    forgotPassword({
      email: data.email,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('forgot-password.toast.title'),
          description: t('forgot-password.toast.description'),
          duration: 10000,
          isClosable: true,
          status: 'success',
        })
      })
  }

  return (
    <Center w="100%" h="100vh">
      <VStack textAlign="left">
        <Box w={{ base: '90%', md: '350px' }}>
          <CText.HeaderLg as="h1" mb="8px">
            {t('forgot-password.title')}
          </CText.HeaderLg>
          <CText.PrimaryText mb="40px">
            {t('forgot-password.sub-title')}
          </CText.PrimaryText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label={t('forgot-password.email')}
              placeholder={t('forgot-password.email')}
              error={errors.email}
              registeredForm={register('email')}
              inputMode="email"
              id="email"
              tinted={true}
            />
            <CButton
              btnType="mio"
              mt={4}
              w="full"
              colorScheme="blue"
              type="submit"
              isLoading={isLoading}
            >
              {t('forgot-password.submit-button')}
            </CButton>
          </form>
        </Box>
        <Box mt="30px !important">
          <CLink title="Back to login" href="/" />
        </Box>
      </VStack>
    </Center>
  )
}

export default ForgotPasswordScreen
