import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../constants'
import { Role, UserType } from '../../types/domainTypes'
import { RootState } from '../store'
import {
  endSession,
  updateFetchingToken,
  updateTokens,
} from '../slices/sessionSlice'
import i18n from '../../i18n'
import { StringLocale } from 'yup/lib/locale'

export interface RefreshTokenResponse {
  refreshToken: string
  accessToken: string
  expires: string
}

export interface User {
  id: number
  email: string
  password: string
  firstName: string
  lastName: string
  userType: UserType
  phoneNumber: string
  roles: string[]
  employee?: Employee
  organisation?: Organisation
}

export interface Organisation {
  email: string
  name: string
  phoneNumber: string
  cvr: string
  language?: string
  configured: boolean
}

export interface Employee {
  token: string
  districts?: District[]
}

export interface District {
  id: number
  name: string
  phoneNumber?: string
  citizensCount: number
  citizens: Citizen[]
}

export interface Device {
  id: number
  imei: string
  battery: number
  status: 'live'
  citizenId: number
  citizenFirstName: string
  citizenLastName: string
  box: boolean
}

export interface Citizen {
  id: number
  firstName: string
  lastName: string
  address: string
  phoneNumber: string
  districtId: number
  districtName: string
  deviceId: number
  deviceImei: string
  note: string
  mioStatus: {
    pillInRooms: boolean[]
    battery: number
    box: boolean
    isFlat?: boolean
    rooms: mioRoom[]
    configuration: {
      rooms: MioRoomConfig[]
      buzzerVolume: number
    }
    isAlive?: boolean
    lastUpdate: string
  }
}

export interface Status {
  pillInRooms: boolean[]
  battery: number
  box: boolean
  rooms: mioRoom[]
  configuration: {
    rooms: MioRoomConfig[]
    buzzerVolume: number
  }
  isAlive?: boolean
  lastUpdate: string
}

export interface mioRoom {
  timeToTake: string
  timeTaken?: string
  status: mioRoomStatus
}

export interface MioRoomConfig {
  hour: number
  min: number
  warning: number
  alarm: number
}

export interface Invitation {
  id: number
  email: string
}

export type mioRoomStatus =
  | 'taken'
  | 'notTaken'
  | 'overdue'
  | 'empty'
  | 'takenLate'
  | 'takenEarly'


export type Config = { 
  buzzerVolume: number
  rooms: MioRoomConfig[]
  timestamp: string
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, api) => {
    // @ts-ignore
    const state: RootState = api.getState()
    const token = state.session.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    var language = i18n.language === 'en' ? 'en-GB' : 'da-DK'
    headers.set('Accept-Language', language)
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
    // @ts-ignore
    const state: RootState = api.getState()
    const refreshToken = state.session.refreshToken

    if (state.session.tokenExpires && !state.session.isFetchingToken) {
      const expires = new Date(state.session.tokenExpires)

      const now = new Date()

      api.dispatch(updateFetchingToken(true))

      if (expires < now) {
        const refreshResult = await baseQuery(
          {
            url: '/user/refresh-token',
            method: 'POST',
            body: {
              refreshToken: refreshToken,
            },
          },
          api,
          extraOptions
        )

        if (refreshResult.data) {
          const data = refreshResult.data as RefreshTokenResponse

          api.dispatch(
            updateTokens({
              token: data.accessToken,
              refreshToken: data.refreshToken,
              tokenExpires: data.expires,
            })
          )

          api.dispatch(updateFetchingToken(false))

          result = await baseQuery(args, api, extraOptions)
        }
      } else {
        // await baseQueryWithReauth(args, api, extraOptions)

        api.dispatch(endSession())
      }
    } else {
      await baseQueryWithReauth(args, api, extraOptions)
      // api.dispatch(endSession())
    }
  }
  return result
}

export const mioApi = createApi({
  reducerPath: 'mioApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    // User related endpoints
    register: builder.mutation<
      { id: number; token: string; email: string },
      (Omit<Organisation, 'configured'> | Employee) &
      Omit<User, 'id' | 'email' | 'roles' | 'employee' | 'organisation'>
    >({
      query: body => ({
        url: '/user/',
        method: 'POST',
        body,
      }),
    }),
    confirmEmail: builder.query<User, string>({
      query: code => `user/verify-email?token=${code}`,
    }),
    login: builder.mutation<
      User & {
        accessToken: string
        refreshToken: string
        expires: Date
        roles: [Role]
      },
      Pick<User, 'email' | 'password'>
    >({
      query: body => ({
        url: '/user/authenticate',
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation<null, { email: string }>({
      query: body => ({
        url: '/user/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<null, { token: string; password: string }>({
      query: ({ token, ...body }) => ({
        url: `/user/reset-password?token=${token}`,
        method: 'POST',
        body,
      }),
    }),
    updatePassword: builder.mutation<
      null,
      { currentPassword: string; password: string }
    >({
      query: body => ({
        url: `/user/update-password`,
        method: 'POST',
        body,
      }),
    }),
    updateOrganisationConfigured: builder.mutation<
      null,
      { configured: boolean }
    >({
      query: body => ({
        url: '/user/configured',
        method: 'POST',
        body,
      }),
    }),
    //Employees
    getUserById: builder.query<User, number>({
      query: id => `user/${id}`,
    }),
    getAllUsers: builder.query<{ users: User[] }, null>({
      query: () => `/user`,
    }),
    inviteEmployee: builder.mutation<
      null,
      { email: string; districtId?: number }
    >({
      query: body => ({
        url: '/invitation/invite',
        method: 'POST',
        body,
      }),
    }),
    deleteInvitation: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/invitation/${id}`,
        method: 'DELETE',
      }),
    }),
    resendInvite: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/invitation/resend/${id}`,
        method: 'POST',
      }),
    }),
    getAllInvites: builder.query<{ invitations: Invitation[] }, null>({
      query: () => `/invitation`,
    }),
    deleteUser: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
    }),
    updateUser: builder.mutation<
      User,
      Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber'> & {
        role?: Role
        language?: string
        organisationName?: string
        cvr?: string
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/user/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    // Districts
    getAllDistricts: builder.query<{ districts: District[] }, null>({
      query: () => `/district`,
    }),
    getDistrictById: builder.query<District, number>({
      query: id => `/district/${id}`,
    }),
    createDistrict: builder.mutation<
      District,
      { name: string; phoneNumber?: string }
    >({
      query: body => ({
        url: '/district/',
        method: 'POST',
        body,
      }),
    }),
    updateDistrict: builder.mutation<
      District,
      { id: number; name: string; phoneNumber?: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/district/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    assignEmployeeDistrict: builder.mutation<
      null,
      { districtId: number; employeeId: number; delete?: boolean }
    >({
      query: body => ({
        url: '/district/assign-employee',
        method: 'POST',
        body,
      }),
    }),
    deleteDistrict: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/district/${id}`,
        method: 'DELETE',
      }),
    }),
    // Devices
    getAllDevices: builder.query<{ devices: Device[] }, null>({
      query: () => `/device`,
    }),
    getDeviceById: builder.query<Device, number>({
      query: id => `device/${id}`,
    }),
    createDevice: builder.mutation<
      Device,
      { imei: string; citizenId?: number }
    >({
      query: body => ({
        url: '/device/',
        method: 'POST',
        body,
      }),
    }),
    assignDevice: builder.mutation<
      null,
      { deviceId: number; citizenId: number }
    >({
      query: body => ({
        url: '/device/assign-citizen',
        method: 'POST',
        body,
      }),
    }),
    deleteDevice: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/device/${id}`,
        method: 'DELETE',
      }),
    }),
    resetDevice: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/device/${id}`,
        method: 'POST',
      }),
    }),
    updateDeviceConfiguration: builder.mutation<
      null,
      {
        id: number
        buzzerVolume: number
        roomConfigs: MioRoomConfig[]
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/device/configuration/${id}`,
        method: 'POST',
        body,
      }),
    }),
    // Citizens
    getAllCitizens: builder.query<{ citizens: Citizen[] }, null>({
      query: () => `/citizen`,
    }),
    getLastSeenCitizens: builder.query<{ citizens: Citizen[] }, null>({
      query: () => `/citizen/last-seen`,
    }),
    createCitizen: builder.mutation<
      Citizen,
      | Pick<
        Citizen,
        'firstName' | 'lastName' | 'address' | 'phoneNumber' | 'districtId'
      >
      | { deviceId: number | undefined }
    >({
      query: body => ({
        url: '/citizen/',
        method: 'POST',
        body,
      }),
    }),
    getCitizenById: builder.query<Citizen & { favorite: boolean }, number>({
      query: id => `citizen/${id}`,
    }),
    favoriteCitizen: builder.query<{ favorite: boolean }, number>({
      query: id => `citizen/favorite/${id}`,
    }),
    getFavoriteCitizens: builder.query<{ citizens: Citizen[] }, null>({
      query: () => `citizen/favorites`,
    }),
    updateCitizen: builder.mutation<
      District,
      {
        id: number
        firstName?: string
        lastName?: string
        phoneNumber?: string
        address?: string
        deviceId?: number
        districtId?: number
        note?: string
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/citizen/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteCitizen: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `/citizen/${id}`,
        method: 'DELETE',
      }),
    }),
    getCitizinHistory: builder.mutation<{ statuses: Status[], configs: Config[] }, { id: string, startDate: string, endDate?: string }>({
      query: ({ id, startDate, endDate }) => ({
        url: `/citizen/history/${id}/${startDate}/${endDate}`,
        method: "GET"
      })
    }),
  }),
})

export const {
  useRegisterMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useUpdateOrganisationConfiguredMutation,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useConfirmEmailQuery,
  useLazyConfirmEmailQuery,
  useGetAllDistrictsQuery,
  useLazyGetAllDistrictsQuery,
  useGetDistrictByIdQuery,
  useCreateDistrictMutation,
  useUpdateDistrictMutation,
  useAssignEmployeeDistrictMutation,
  useDeleteDistrictMutation,
  useInviteEmployeeMutation,
  useDeleteInvitationMutation,
  useResendInviteMutation,
  useGetAllInvitesQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetAllDevicesQuery,
  useLazyGetAllDevicesQuery,
  useGetDeviceByIdQuery,
  useCreateDeviceMutation,
  useAssignDeviceMutation,
  useDeleteDeviceMutation,
  useResetDeviceMutation,
  useUpdateDeviceConfigurationMutation,
  useGetAllCitizensQuery,
  useLazyGetAllCitizensQuery,
  useGetLastSeenCitizensQuery,
  useCreateCitizenMutation,
  useGetCitizenByIdQuery,
  useFavoriteCitizenQuery,
  useLazyFavoriteCitizenQuery,
  useGetFavoriteCitizensQuery,
  useUpdateCitizenMutation,
  useDeleteCitizenMutation,
  useGetCitizinHistoryMutation
} = mioApi
