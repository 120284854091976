import {
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
} from '@chakra-ui/react'
import React from 'react'
import { CText } from '.'
import CButton from './CButton'

interface SettingsDrawerProps {
  isOpen: boolean
  onClose: () => void
  title: string
  bodyRender: () => JSX.Element
  cancelButtonTitle: string
  submitButtonTitle: string
  onSubmit: () => void
  onSubmitLoading: boolean
  formOnSubmit?: React.FormEventHandler<HTMLFormElement>
  underTitle?: string
}

const SettingsDrawer: React.FC<SettingsDrawerProps> = ({
  isOpen,
  onClose,
  title,
  bodyRender,
  cancelButtonTitle,
  submitButtonTitle,
  onSubmit,
  onSubmitLoading,
  formOnSubmit,
  underTitle,
}) => {
  const drawerContent = (
    <DrawerContent data-test={`drawer-${title}`}>
      <DrawerCloseButton />
      <DrawerHeader>
        {title}
        {underTitle && (
          <CText.PrimaryText mt="6px">{underTitle}</CText.PrimaryText>
        )}
      </DrawerHeader>
      <DrawerBody p="24px">{bodyRender()}</DrawerBody>

      <DrawerFooter p="30px">
        <HStack>
          <CButton btnType="regular" onClick={onClose}>
            {cancelButtonTitle}
          </CButton>
          <CButton
            btnType="dark"
            isLoading={onSubmitLoading}
            onClick={onSubmit}
            type={formOnSubmit ? 'submit' : 'button'}
          >
            {submitButtonTitle}
          </CButton>
        </HStack>
      </DrawerFooter>
    </DrawerContent>
  )

  return (
    <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />

      {formOnSubmit ? (
        <form onSubmit={formOnSubmit}>{drawerContent}</form>
      ) : (
        drawerContent
      )}
    </Drawer>
  )
}

export default SettingsDrawer
