import { Stack, Text } from '@chakra-ui/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface NotFoundScreenProps {}

const NotFoundScreen: React.FC<NotFoundScreenProps> = () => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Text>{t('not-found-screen')}</Text>
    </Stack>
  )
}

export default NotFoundScreen
