import { Table, HStack } from '@chakra-ui/react'
import {
  useTable,
  useSortBy,
  useFilters,
  TableState,
  usePagination,
} from 'react-table'
import TableHeader from './TableHeader'
import { Column } from 'react-table'
import TableBody from './TableBody'
import { useEffect } from 'react'
import { PaginationButton } from '../../components'
import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io'
import { BsFillSkipForwardFill, BsFillSkipBackwardFill } from 'react-icons/bs'

interface LoadingGenericTableProps<T> {
  data?: T[]
  sampleData: T[]
  columns: ({
    show?: boolean
    accessor: keyof T
  } & Column)[]
  isLoading: boolean
  initialState?: Partial<TableState<{}>>
}

const LoadingGenericTable = <T extends object>({
  data,
  sampleData,
  columns,
  isLoading,
  initialState,
}: LoadingGenericTableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setHiddenColumns,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data || sampleData,
      initialState: { pageSize: 5, ...initialState },
    },
    useFilters,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    setHiddenColumns(
      columns
        .filter(column => column.show === false)
        .map(column => column.accessor as string)
    )
  }, [columns])

  return (
    <>
      <Table
        {...getTableProps()}
        //px={{ base: '16px', md: '30px', lg: '40px' }}
      >
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          rows={page}
          prepareRow={prepareRow}
          isLoading={isLoading || !data}
        />
      </Table>
      {data ? (
        data.length > pageSize && (
          <HStack mt="16px">
            <PaginationButton
              label="Go to first page"
              onClick={() => gotoPage(0)}
              //disabled={!canPreviousPage}
            >
              <BsFillSkipBackwardFill size="14px" color="#3d494d" />
            </PaginationButton>
            <PaginationButton
              label="Go to previous page"
              onClick={() => previousPage()}
              //disabled={!canPreviousPage}
            >
              <IoMdArrowDropleft size="22px" color="#3d494d" />
            </PaginationButton>

            <HStack
              shadow="base"
              h="30px"
              px="12px"
              color="mio-white"
              borderRadius={8}
              focusBorderColor="#7C7C7C"
              display="flex"
              justifyContent="center"
              bg="mio-headline"
              alignItems="center"
              cursor="pointer"
              fontSize="13px"
              fontWeight="700"
              mx="5px !important"
            >
              <span>
                {pageIndex + 1}/{pageOptions.length}
              </span>
            </HStack>
            <PaginationButton
              label="Go to next page"
              onClick={() => nextPage()}
              //disabled={!canNextPage}
            >
              <IoMdArrowDropright size="22px" color="#3d494d" />
            </PaginationButton>
            <PaginationButton
              label="Go to last page"
              onClick={() => gotoPage(pageCount - 1)}
              //disabled={!canNextPage}
            >
              <BsFillSkipForwardFill size="14px" color="#3d494d" />
            </PaginationButton>
            {/* <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </HStack>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default LoadingGenericTable
