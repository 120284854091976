import { configureStore } from '@reduxjs/toolkit'
import { pokemonApi } from './services/pokemon'
import { reqresApi } from './services/reqres'
import counterReducer from './slices/counterSlice'
import sessionReducer from './slices/sessionSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'
import { mioApi } from './services/mio-api'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    session: sessionReducer,
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [reqresApi.reducerPath]: reqresApi.reducer,
    [mioApi.reducerPath]: mioApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      pokemonApi.middleware,
      reqresApi.middleware,
      mioApi.middleware,
      rtkQueryErrorLogger,
    ]),
})
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
