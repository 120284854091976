import { Flex } from '@chakra-ui/layout'
import React from 'react'
import CInput from './CInput'
import { IoIosSearch } from 'react-icons/io'
import { InputGroup, InputLeftElement } from '@chakra-ui/input'
import { useIsDesktop } from '../utils/useIsDesktop'

interface ListSearchProps {
  query: string
  setQuery: (val: string) => void
  placeholder: string
}

const ListSearch: React.FC<ListSearchProps> = ({
  query,
  setQuery,
  placeholder,
}) => {
  const isDesktop = useIsDesktop()

  return (
    <Flex>
      <InputGroup w={isDesktop ? '250px' : '100%'}>
        {isDesktop && (
          <InputLeftElement
            pointerEvents="none"
            children={<IoIosSearch color="mio-primaryText" />}
          />
        )}

        <CInput
          placeholder={placeholder}
          value={query}
          onChange={e => setQuery(e.target.value)}
          tinted={true}
        />
      </InputGroup>
    </Flex>
  )
}

export default ListSearch
