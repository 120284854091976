import React from 'react'
import { Link as ChakraLink, LinkProps as ChakraProps } from '@chakra-ui/react'

interface LinkProps {
  title: string
}

const CLink: React.FC<LinkProps & ChakraProps> = (
  props: LinkProps | ChakraProps
) => (
  <ChakraLink
    color="#738084"
    _hover={{ color: '#3D494D', textDecoration: 'underline' }}
    fontSize="14"
    fontWeight="medium"
    data-test={`input-${props.title}`}
    {...props}
  >
    {props.title}
  </ChakraLink>
)

export default CLink
