import { Box, VStack } from '@chakra-ui/react'

import React from 'react'
import CLink from '../../../../../components/CLink'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PageHeader } from '../../../../../components/index'

interface StatisticsScreenProps {}

const StatisticsScreen: React.FC<StatisticsScreenProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <>
      <VStack
        alignItems="flex-start"
        p={{ base: '16px', md: '30px', lg: '40px' }}
      >
        <Box display={{ base: 'none', lg: 'block' }} mb="10px">
          <CLink
            title={t('global.back-button')}
            onClick={() => history.goBack()}
          />
        </Box>
        <PageHeader
          title={`🚧 ${t('global.under-development')} 🚧`}
          sampleText="Denne side er under ombygning"
        />
      </VStack>
    </>
  )
}

export default StatisticsScreen
