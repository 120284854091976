import React from 'react'
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraProps,
  Tooltip,
} from '@chakra-ui/react'

export interface IconButtonProps {
  tooltip?: string
  tinted?: boolean
}

const tintedStyles: React.CSSProperties = {
  borderColor: '#FFFFFF',
  backgroundColor: '#FFFFFF',
  color: '#3D494D',
}
const regularStyles: React.CSSProperties = {
  borderColor: '#F2F5F6',
  backgroundColor: '#F2F5F6',
  color: '#3D494D',
}

const IconButton: React.FC<IconButtonProps & ChakraProps> = (
  props: IconButtonProps & ChakraProps
) => {
  const IconComponent = (
    <ChakraIconButton
      style={props.tinted ? tintedStyles : regularStyles}
      h={{ base: '46px !important', lg: '40px !important' }}
      w={{ base: '46px !important', lg: '40px !important' }}
      borderRadius={{ base: 16, lg: 10 }}
      boxShadow={props.tinted ? 'base' : 'none'}
      focusBorderColor="#7C7C7C"
      borderWidth="1"
      data-testid="IconButton"
      {...props}
    ></ChakraIconButton>
  )
  if (!props.tooltip) {
    return IconComponent
  }
  return <Tooltip label={props.tooltip}>{IconComponent}</Tooltip>
}

export default IconButton
