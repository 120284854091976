import { HStack, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  mioRoomStatus,
  useGetDistrictByIdQuery,
} from '../../../../../redux/services/mio-api'
import { useHistory } from 'react-router-dom'
import { fullName } from '../../../../../utils/utils'

import { useIsDesktop } from '../../../../../utils/useIsDesktop'

import { CText, AddNew, CitizenBox } from '../../../../../components/index'
import EntitySearchView from '../../../../../containers/EntitySearchView'
import { Column } from 'react-table'
import DistrictSettingsModal from '../containers/DistrictSettingsModal'
import useRoleCheck from '../../../../../utils/useRoleCheck'
import { Role } from '../../../../../types/domainTypes'
import AddCitizenModal from '../containers/AddCitizenModal'

const sampleData = [
  {
    id: 33,
    firstName: 'Alexander',
    lastName: 'Magnus',
    address: 'Nordre Frihavnsgade 17A st.tv',
    phoneNumber: '+45 22 67 80 25',
    mioStatus: {
      rooms: [
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
      ],
    },
    // districtName: 'NORD1',
    // deviceStatus: [1, 1, 2, 0],
    // deviceImei: '1502-8374-0183-9273',
  },
]

interface CitizenScreenProps {}

const DistrictScreen: React.FC<CitizenScreenProps> = () => {
  const { id } = useParams<{ id: string }>()
  // eslint-disable-next-line
  const { data, isFetching, refetch } = useGetDistrictByIdQuery(parseInt(id), {
    refetchOnMountOrArgChange: true,
  })

  const { t } = useTranslation()
  const history = useHistory()

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  const isDesktop = useIsDesktop()

  const addCitizen = useDisclosure()

  const columns: ({
    accessor: keyof typeof sampleData[0]
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: t('districts.header.name') as string,
        accessor: 'firstName',
      },
    ],
    [data]
  )

  return (
    <>
      <EntitySearchView
        header={data?.name}
        searchPlaceholder={t('district.search-placeholder')}
        sampleData={sampleData}
        goBack
        columns={columns}
        data={data?.citizens}
        isFetching={isFetching}
        contentDescription={
          <HStack alignItems="center" mb="14px" spacing="10px">
            <CText.SectionHeaderLg>
              {t('district.citizens-in')}
            </CText.SectionHeaderLg>
            <AddNew onClick={addCitizen.onOpen} />
          </HStack>
        }
        cardRender={elm => (
          <CitizenBox
            name={fullName(elm.firstName, elm.lastName)}
            address={elm.address}
            phone={elm.phoneNumber}
            w={{ base: 'auto', lg: '350px' }}
            status={elm?.mioStatus?.rooms.map(r => r.status as mioRoomStatus)}
            onClick={() => history.push('/dashboard/citizens/' + elm.id)}
          />
        )}
        keyFn={elm => elm.id.toString()}
        filterFn={(search, elm) =>
          fullName(elm.firstName, elm.lastName)
            .toLowerCase()
            .startsWith(search.toLowerCase())
        }
        settingsRender={
          isAdminRoles
            ? (isOpen, onOpen, onClose) => (
                <>
                  {data && (
                    <DistrictSettingsModal
                      isOpen={isOpen}
                      onOpen={onOpen}
                      onClose={onClose}
                      refetch={refetch}
                      name={data.name}
                      phoneNumber={data.phoneNumber}
                      emptyDistrict={data.citizens.length === 0}
                      id={data.id}
                    />
                  )}
                </>
              )
            : undefined
        }
        renderOnlyCards={true}
        wrapCards={isDesktop}
        settingsIcon
      />
      <AddCitizenModal
        districtId={id}
        isOpen={addCitizen.isOpen}
        onOpen={addCitizen.onOpen}
        onClose={addCitizen.onClose}
        refetch={refetch}
      />
    </>
  )
}

export default DistrictScreen
