import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'react-table'
import EntitySearchView from '../../../../containers/EntitySearchView'
import CitizensAddModal from './containers/CitizensAddModal'
import MioStatusCircles from './components/MioStatusCircles'

import { Box, Flex, HStack } from '@chakra-ui/layout'
import useAsyncAlert from '../../../../utils/useAsyncAlert'
import AsyncAlert from '../../../../containers/AsyncAlert'
import { Skeleton, useToast } from '@chakra-ui/react'
import {
  CitizenBox,
  InitialsBox,
  CText,
  CBadge,
} from '../../../../components/index'
import {
  useGetAllCitizensQuery,
  useGetAllDevicesQuery,
  Citizen,
  mioRoom,
  mioRoomStatus,
  useDeleteCitizenMutation,
} from '../../../../redux/services/mio-api'
import { fullName } from '../../../../utils/utils'
import { useHistory } from 'react-router-dom'
import useRoleCheck from '../../../../utils/useRoleCheck'
import { Role } from '../../../../types/domainTypes'
import { CgPhone } from 'react-icons/cg'
import { IoIosWarning } from 'react-icons/io'

const sampleData = [
  {
    id: 33,
    fullName: 'Alexander Magnus',
    firstName: 'Alexander',
    lastName: 'Magnus',
    address: 'Nordre Frihavnsgade 17A st.tv',
    phoneNumber: '+45 22 67 80 25',
    districtName: 'NORD1',
    mioStatus: {
      rooms: [
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
      ],
      box: true,
    },
    deviceImei: '1502-8374-0183-9273',
  },
  {
    id: 333,
    fullName: 'Alexander Magnus',
    firstName: 'Alexander',
    lastName: 'Magnus',
    address: 'Fiskedamsgade 20 1.th',
    phoneNumber: '+45 40 43 20 93',
    districtName: 'NORD2',
    mioStatus: {
      rooms: [
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
        {
          timeToTake: '12:55',
          status: 'empty',
        },
      ],
      box: false,
    },
    deviceImei: '3928-4027-3048-2037',
  },
]

interface CitizensScreenProps {}

const CitizensScreen: React.FC<CitizensScreenProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const toast = useToast()
  const citizens = useGetAllCitizensQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const citizensData = citizens.data?.citizens.map(c => ({
    fullName: fullName(c.firstName, c.lastName),
    ...c,
  }))

  const devices = useGetAllDevicesQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const [deleteCitizen] = useDeleteCitizenMutation()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      name: string
    },
    any
  >()

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  const columns: ({
    accessor: keyof typeof sampleData[0]
    show?: boolean
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        show: false,
      },
      {
        Header: t('citizens.table-header.fullName') as string,
        accessor: 'fullName',
        Cell: props => {
          const citizen = props.cell.row.original as Citizen

          return (
            <HStack alignItems="center">
              <InitialsBox
                fullName={fullName(citizen.firstName, citizen.lastName)}
                onPress={() => {
                  history.push(history.location.pathname + '/' + citizen.id)
                }}
              />
              {/* <CText.TableText
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(
                    history.location.pathname + '/' + props.row.values.id
                  )
                }}
              >
                {props.value}
              </CText.TableText> */}
            </HStack>
          )
        },
      },
      {
        Header: t('citizens.table-header.address') as string,
        accessor: 'address',
      },
      {
        Header: t('citizens.table-header.phone-number') as string,
        accessor: 'phoneNumber',
      },
      {
        Header: t('citizens.table-header.district') as string,
        accessor: 'districtName',
      },
      {
        Header: t('citizens.table-header.mio-status') as string,
        accessor: 'mioStatus',
        sortType: (rowA: Row, rowB: Row) => {
          const mioStatusA = (rowA.values as Citizen).mioStatus
          const mioStatusB = (rowB.values as Citizen).mioStatus

          // console.log(mioStatusA, mioStatusB)

          if (mioStatusA === null && mioStatusB !== null) {
            return -1
          }

          if (mioStatusA !== null && mioStatusB === null) {
            return 1
          }

          if (mioStatusA !== null && mioStatusB !== null) {
            const overdueAmountA = mioStatusA.rooms.filter(r => {
              return r.status === 'overdue'
            }).length

            const overdueAmountB = mioStatusB.rooms.filter(r => {
              return r.status === 'overdue'
            }).length

            if (overdueAmountA > overdueAmountB) {
              return 1
            }

            if (overdueAmountA < overdueAmountB) {
              return -1
            }

            const yellowAmountA = mioStatusA.rooms.filter(r => {
              return r.status === 'takenEarly' || r.status === 'takenLate'
            }).length

            const yellowAmountB = mioStatusB.rooms.filter(r => {
              return r.status === 'takenEarly' || r.status === 'takenLate'
            }).length

            if (yellowAmountA > yellowAmountB) {
              return 1
            }

            if (yellowAmountA < yellowAmountB) {
              return -1
            }

            const takenAmountA = mioStatusA.rooms.filter(r => {
              return r.status === 'taken'
            }).length

            const takenAmountB = mioStatusB.rooms.filter(r => {
              return r.status === 'taken'
            }).length

            if (takenAmountA > takenAmountB) {
              return 1
            }

            if (takenAmountA < takenAmountB) {
              return -1
            }
          }

          return 0
        },
        Cell: props => {
          var rooms = props?.value?.rooms as mioRoom[]
          return <MioStatusCircles status={rooms?.map(r => r.status)} />
        },
      },
      {
        Header: t('devices.table-header.status') as string,
        //@ts-ignore
        accessor: originalRow => {
          const rowData = originalRow as Citizen
          if (
            rowData?.mioStatus?.box === undefined ||
            rowData?.mioStatus?.box === null
          ) {
            return 'empty'
          }
          return rowData.mioStatus.box
        },
        Cell: props => {
          const rowData = props.row.original as Citizen
          if (rowData?.mioStatus?.isAlive === false) {
            return (
              <Flex>
                <IoIosWarning
                  size="18px"
                  color="F8C925"
                  style={{
                    justifyContent: 'center',
                  }}
                />
              </Flex>
            )
          }
          return rowData ? (
            rowData?.mioStatus?.box === true ||
            rowData?.mioStatus?.box === false ? (
              <CBadge pillBoxIn={rowData.mioStatus.box} />
            ) : (
              <CText.PrimaryText>No device</CText.PrimaryText>
            )
          ) : (
            <Skeleton>
              <CBadge pillBoxIn={true}>Pillbox in</CBadge>
            </Skeleton>
          )
        },
      },
      // {
      //   Header: ' ',
      //   // @ts-ignore
      //   accessor: 'null_0',
      //   disableSortBy: true,
      //   show: isAdminRoles,
      //   Cell: props => {
      //     const rows = [
      //       {
      //         title: 'Delete citizen',
      //         onPress: async () => {
      //           const citizen = props.cell.row.original as Citizen
      //           await openAlertAndAwaitConfirmation(
      //             {
      //               name: fullName(citizen.firstName, citizen.lastName),
      //             },
      //             { id: citizen.id }
      //           )
      //         },
      //         warning: true,
      //       },
      //     ]

      //     return <TableSettings rows={rows} />
      //   },
      // },
    ],
    [devices]
  )

  return (
    <>
      <EntitySearchView
        header={t('citizens.title')}
        searchPlaceholder={t('citizens.search-placeholder')}
        sampleData={sampleData}
        columns={columns}
        tableInitialState={{ hiddenColumns: ['id'] }}
        data={citizensData}
        noDataDescription={t('citizens.no-data')}
        isFetching={citizens.isFetching}
        cardRender={elm => (
          <CitizenBox
            w="100%"
            address={elm.address}
            name={fullName(elm.firstName, elm.lastName)}
            phone={elm.phoneNumber}
            status={elm?.mioStatus?.rooms?.map(r => r.status as mioRoomStatus)}
            district={elm.districtName}
            onClick={() => {
              history.push(history.location.pathname + '/' + elm.id)
            }}
          />
        )}
        keyFn={elm => elm.id.toString()}
        filterFn={(search, elm) =>
          elm.firstName.toLowerCase().startsWith(search.toLowerCase())
        }
        modalRender={(isOpen, onOpen, onClose) => (
          <CitizensAddModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            refetch={citizens.refetch}
          />
        )}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data => `${t('citizens.alert.delete', { name: data?.name })}`}
        cancelButtonText={t('citizens.alert.cancel-button')}
        submitButtonText={t('citizens.alert.delete-button')}
        isWarning
        onClick={async payload => {
          deleteCitizen({
            id: payload.id,
          })
            .unwrap()
            .then(() => {
              citizens.refetch()
              toast({
                title: t('citizen.modal.toast.title'),
                description: t('citizen.modal.toast.description'),
                duration: 5000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...alertProps}
      />
    </>
  )
}

export default CitizensScreen
