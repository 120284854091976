import React from 'react'
import { Box, BoxProps, Tooltip } from '@chakra-ui/react'

export interface CButtonProps {
  label: string
  disabled?: boolean
}

const PaginationButton: React.FC<CButtonProps & BoxProps> = props => {
  const { label, children, disabled, ...restProps } = props

  return (
    <Tooltip label={label}>
      <Box
        h="34px"
        w="34px"
        borderRadius={10}
        focusBorderColor="#7C7C7C"
        display="flex"
        justifyContent="center"
        alignItems="center"
        m="0px !important"
        cursor="pointer"
        transition="0.3s all"
        {...restProps}
      >
        {children}

        {/* <IoMdArrowDropright size="26px" color="#3d494d" /> */}
      </Box>
    </Tooltip>
  )
}

// @ts-ignore
PaginationButton.id = 'PaginationButton'

export default PaginationButton
