import { Box, Stack } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CLink from '../../../components/CLink'
import { useRegisterMutation } from '../../../redux/services/mio-api'
import AccountHolderForm from './containers/AccountHolderForm'
import OrganisationForm from './containers/OrganisationForm'
import PasswordForm from './containers/PasswordForm'
import { UserType } from '../../../types/domainTypes'
import { useHistory } from 'react-router-dom'

interface SignupScreenProps {}

export interface CompleteSignupFormData {
  organisation?: string
  cvr?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  password?: string
  confirmpassword?: string
  termsOfUse?: boolean
}

const SignupScreen: React.FC<SignupScreenProps> = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState<
    'organisation' | 'account-holder' | 'password'
  >('organisation')
  const [completeFormData, setCompleteFormData] =
    useState<CompleteSignupFormData>()
  const toast = useToast()
  const [register, { isLoading: isUpdating }] = useRegisterMutation()
  const history = useHistory()

  return (
    <Stack height="inherit" alignItems="center" justifyContent="center">
      {page === 'organisation' && (
        <OrganisationForm
          data={completeFormData}
          onSubmit={data => {
            setPage('account-holder')
            setCompleteFormData({
              ...completeFormData,
              ...data,
            })
          }}
        />
      )}
      {page === 'account-holder' && (
        <AccountHolderForm
          data={completeFormData}
          onSubmit={data => {
            setPage('password')
            setCompleteFormData({
              ...completeFormData,
              ...data,
            })
          }}
        />
      )}
      {page === 'password' && (
        <PasswordForm
          isSubmitting={isUpdating}
          onSubmit={data => {
            const lastCompleteFormData = { ...completeFormData, ...data }
            if (
              lastCompleteFormData.email &&
              lastCompleteFormData.password &&
              lastCompleteFormData.organisation &&
              lastCompleteFormData.cvr &&
              lastCompleteFormData.phone &&
              lastCompleteFormData.firstName &&
              lastCompleteFormData.lastName
            ) {
              register({
                email: lastCompleteFormData.email,
                password: lastCompleteFormData.password,
                userType: 'Organisation' as UserType,
                name: lastCompleteFormData.organisation,
                cvr: lastCompleteFormData.cvr,
                phoneNumber: lastCompleteFormData.phone,
                firstName: lastCompleteFormData.firstName,
                lastName: lastCompleteFormData.lastName,
              })
                .unwrap()
                .then(() => {
                  toast({
                    status: 'success',
                    description: t('signup.toast.account-success.description'),
                    title: t('signup.toast.account-success.title'),
                    isClosable: true,
                  })
                  history.push('/')
                })
            }
          }}
        />
      )}
      {page === 'organisation' && (
        <CLink title="Back to login" href="/" mt="20px !important" />
      )}
      {page !== 'organisation' && (
        <Box display={{ base: 'none', lg: 'block' }} mt="20px !important">
          <CLink
            title={t('global.back-button')}
            onClick={() => {
              if (page === 'account-holder') {
                setPage('organisation')
              } else if (page === 'password') {
                setPage('account-holder')
              }
            }}
          />
        </Box>
      )}
      {/*  */}
    </Stack>
  )
}

export default SignupScreen
