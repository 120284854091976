export type Role = 'superUser' | 'employeeAdmin' | 'employee'

export type UserType = 'Organisation' | 'Employee'

export enum RoleEnum {
  SUPERUSER = 0,
  EMPLOYEEADMIN = 1,
  EMPLOYEE = 2,
}

export interface Option {
  value: number
  name: string
}
