import React from 'react'
import { Center, CenterProps } from '@chakra-ui/react'
import { CText } from './index'
import { useTranslation } from 'react-i18next'

export interface Props {}

const AddNew: React.FC<Props & CenterProps> = (props: Props & CenterProps) => {
  const { t } = useTranslation()
  return (
    <Center
      role="group"
      p="4px 10px"
      rounded="10px"
      aria-label={t('tooltip.add-new')}
      data-test="add-new-entity-button"
      cursor="pointer"
      borderColor="mio-headline"
      borderWidth="2px"
      transition="0.3s all"
      _hover={{
        bg: 'mio-headline',
      }}
      {...props}
    >
      <CText.PrimaryText
        fontWeight="600"
        color="mio-headline"
        _groupHover={{ color: '#fff' }}
      >
        {t('global.add-new')}
      </CText.PrimaryText>
    </Center>
  )
}

export default AddNew
