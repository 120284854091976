import { Box, HStack, Stack } from '@chakra-ui/layout'
import React from 'react'
import { CText } from './index'
import { Skeleton } from '@chakra-ui/react'

interface OnboardingStepProps {
  progress: number
  isLoading: boolean
}

const OnboardingProgress: React.FC<OnboardingStepProps> = ({
  progress,
  isLoading,
}) => {
  return (
    <Stack direction={{ base: 'column', md: 'row' }} mt="30px">
      <CText.PrimaryTextBig mr="10px">
        {progress === 1
          ? '25'
          : progress === 2
          ? '50'
          : progress === 3
          ? '75'
          : progress === 4
          ? '100'
          : '0'}
        % complete
      </CText.PrimaryTextBig>
      <HStack>
        {[1, 2, 3, 4].map((item, index) => (
          <>
            {isLoading ? (
              <Skeleton>
                <Box
                  bg={item <= progress ? '#51ADCE' : 'mio-gray'}
                  h="6px"
                  w="60px"
                >
                  {' '}
                </Box>
              </Skeleton>
            ) : (
              <Box
                bg={item <= progress ? '#59c385' : 'mio-gray'}
                h="6px"
                w="60px"
              >
                {' '}
              </Box>
            )}
          </>
        ))}
      </HStack>
    </Stack>
  )
}

export default OnboardingProgress
