import React from 'react'
import {
  Select as ChakraSelect,
  SelectProps as ChakraProps,
} from '@chakra-ui/react'

interface SelectProps {
  tinted?: boolean
}

const CSelect = React.forwardRef<HTMLSelectElement, SelectProps & ChakraProps>(
  (props, ref) => {
    const tintedStyles: React.CSSProperties = {
      borderColor: '#FFF',
      backgroundColor: '#FFFFFF',
      color: '#3D494D',
    }
    const regularStyles: React.CSSProperties = {
      borderColor: '#F2F5F6',
      backgroundColor: '#F2F5F6',
      color: 'mio-primaryText',
    }

    const { tinted, ...restProps } = props

    return (
      <ChakraSelect
        style={props.tinted ? tintedStyles : regularStyles}
        placeholder="Gender"
        data-testid="Select"
        h={{ base: '48px', lg: '40px' }}
        minWidth="fit-content"
        boxShadow={props.tinted ? 'base' : 'none'}
        iconColor="#7C7C7C"
        cursor="pointer"
        ref={ref}
        color="mio-secondaryText"
        fontSize={{ base: '16px', lg: '13px' }}
        borderRadius={{ base: '14px', lg: '10px' }}
        borderColor="#150303"
        borderWidth="1"
        focusBorderColor="#7C7C7C"
        data-test={`select-${props.placeholder}`}
        {...restProps}
      ></ChakraSelect>
    )
  }
)

export default CSelect
