import React, { useState } from 'react'
import CSelect from '../components/CSelect'
import { Option } from '../types/domainTypes'

interface AssignEntitySelectProps {
  options: Option[] | undefined
  confirmChoice?: (option: Option) => Promise<boolean>
  placeholder?: string
  value?: string
  tinted?: boolean
  width?: any
  disabled?: boolean
}

const AssignEntitySelect: React.FC<AssignEntitySelectProps> = ({
  options,
  confirmChoice,
  placeholder,
  value: propValue,
  tinted,
  width,
  disabled,
}) => {
  const [value, setValue] = useState(propValue || '')

  return (
    <CSelect
      placeholder={placeholder}
      tinted={tinted}
      isDisabled={disabled}
      w={width}
      onChange={async e => {
        e.stopPropagation()
        const choiceValue = e.target.value
        const { options, selectedIndex } = e.target
        const choiceName = options[selectedIndex].innerHTML

        if (confirmChoice) {
          const confirmed = await confirmChoice({
            value: parseInt(choiceValue),
            name: choiceName,
          })
          if (confirmed) setValue(choiceValue)
        } else {
          setValue(choiceValue)
        }
      }}
      value={value}
    >
      {options?.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </CSelect>
  )
}

export default AssignEntitySelect
