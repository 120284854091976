import { useEffect, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  HStack,
} from '@chakra-ui/react'
import * as CText from '../../../../../components/CText'
import { useTranslation } from 'react-i18next'
import CButton from '../../../../../components/CButton'
import VolumeSlider from './VolumeSlider'
import {
  MioRoomConfig,
  useUpdateDeviceConfigurationMutation,
} from '../../../../../redux/services/mio-api'
import { useToast } from '@chakra-ui/toast'

interface Props {
  isOpen: boolean
  onClose: () => void
  volume: number
  id: number
  configuration: MioRoomConfig[]
  refetch: () => void
}

export default function EditVolumeDrawer({
  isOpen,
  onClose,
  volume,
  configuration,
  id,
  refetch,
}: Props) {
  const { t } = useTranslation()
  const [slider, setSlider] = useState(volume)

  const [updateDeviceConfiguration, { isLoading }] =
    useUpdateDeviceConfigurationMutation()

  const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  const reg = /^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/

  const toast = useToast()

  useEffect(() => {
    setSlider(volume)
  }, [isOpen])

  return (
    <div>
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader p="24px">
            {t('citizen.drawer.volume-drawer.title')}
            <CText.PrimaryText>
              {t('citizen.drawer.volume-drawer.description')}
            </CText.PrimaryText>
          </DrawerHeader>

          <DrawerBody p="0px">
            <Box p="24px">
              <HStack justifyContent="space-between" alignItems="center">
                <CText.SectionHeader>
                  {t('citizen.drawer.volume')}
                </CText.SectionHeader>
                <CText.SectionHeader>{slider}</CText.SectionHeader>
              </HStack>

              <Box w="100%" mt="6px">
                <VolumeSlider
                  defaultValue={slider}
                  onChange={e => setSlider(e)}
                />
                <CText.PrimaryText>
                  {t('citizen.drawer.volume-description')}
                </CText.PrimaryText>
              </Box>
            </Box>
          </DrawerBody>

          <DrawerFooter alignItems="center">
            <HStack>
              <CButton btnType="regular" onClick={onClose}>
                {t('citizen.drawer.cancel')}
              </CButton>
              <CButton
                type="submit"
                disabled={volume === slider}
                btnType="dark"
                isLoading={isLoading}
                onClick={async () => {
                  console.log(id, slider, configuration.length)
                  await updateDeviceConfiguration({
                    id: id,
                    buzzerVolume: slider,
                    roomConfigs: configuration,
                  }).unwrap()

                  toast({
                    title: t('citizen.drawer.volume-drawer.success-title'),
                    duration: 5000,
                    isClosable: true,
                    status: 'success',
                  })
                  refetch()
                  onClose()
                }}
              >
                {t('citizen.drawer.save')}
              </CButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
