import { IconButton, Stack, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormSelect,
  SettingsDrawer,
  FormInput,
} from '../../../../../components/index'
import {
  useInviteEmployeeMutation,
  useGetAllDistrictsQuery,
} from '../../../../../redux/services/mio-api'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'

interface EmployeeAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
}

type EmployeeAddModalFormProps = {
  email: string
  district?: number
}

const EmployeeAddModal: React.FC<EmployeeAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
}) => {
  const { t } = useTranslation()
  const [addEmployee, { isLoading }] = useInviteEmployeeMutation()
  const toast = useToast()

  const { data } = useGetAllDistrictsQuery(null)

  useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])

  const schema: yup.SchemaOf<EmployeeAddModalFormProps> = yup.object({
    email: yup
      .string()
      .email(t('validation.must-be-valid-email'))
      .required(t('validation.required')),
    district: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EmployeeAddModalFormProps>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<EmployeeAddModalFormProps> = data => {
    addEmployee({
      email: data.email,
      districtId: data.district,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('employees.modal.toast.title'),
          description: t('employees.modal.toast.description'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        })
        onClose()
        refetch()
      })
  }

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('employees.modal.title')}
      underTitle={t('employees.modal.under-title')}
      bodyRender={() => (
        <>
          {data && (
            <Stack width="100%" p="1px">
              <FormInput
                label={t('employees.modal.email')}
                error={errors.email}
                placeholder={t('employees.modal.email')}
                registeredForm={register('email')}
              />
              <FormSelect
                label={t('employees.modal.district')}
                error={errors.district}
                placeholder={t('employees.modal.district')}
                registeredForm={register('district')}
                options={data.districts.map(d => ({
                  value: d.id,
                  name: d.name,
                }))}
              />
            </Stack>
          )}
        </>
      )}
      cancelButtonTitle={t('employees.modal.cancel-button')}
      submitButtonTitle={t('employees.modal.invite-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default EmployeeAddModal
