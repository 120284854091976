import { FormControl } from '@chakra-ui/react'
import React from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { Option } from '../types/domainTypes'
import CSelect from './CSelect'
import { CText } from './index'

interface FormSelectProps {
  label?: string
  placeholder: string
  error?: FieldError
  registeredForm: UseFormRegisterReturn
  id?: string
  options: Option[]
  tinted?: boolean
  description?: string
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  placeholder,
  error,
  registeredForm,
  id,
  options,
  tinted,
  description,
}) => {
  const tintedStyles: React.CSSProperties = {
    borderColor: '#FFFFFF',
    backgroundColor: '#F2F5F6',
  }
  const regularStyles: React.CSSProperties = {
    borderColor: '#F2F5F6',
    backgroundColor: '#F2F5F6',
    color: '#738084',
  }
  return (
    <FormControl isInvalid={!!error}>
      {label && <CText.SectionHeader mb="1">{label}</CText.SectionHeader>}
      <CSelect
        placeholder={placeholder}
        style={tinted ? tintedStyles : regularStyles}
        {...registeredForm}
      >
        {options.map(option => (
          <option defaultChecked value={option.value}>
            {option.name}
          </option>
        ))}
      </CSelect>
      <CText.Warning>{error && error.message}</CText.Warning>
      {description && (
        <CText.PrimaryText mt="4px">{description}</CText.PrimaryText>
      )}
    </FormControl>
  )
}

export default FormSelect
