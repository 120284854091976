import { Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput, SettingsDrawer } from '../../../../../components/index'
import * as yup from 'yup'
import { useCreateDistrictMutation } from '../../../../../redux/services/mio-api'

interface DistrictsAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
}

type DistrictsAddModalFormData = {
  name: string
  phoneNumber?: string
}

const DistrictsAddModal: React.FC<DistrictsAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
}) => {
  const { t } = useTranslation()
  const [addDistrict, { isLoading }] = useCreateDistrictMutation()

  const toast = useToast()

  const schema: yup.SchemaOf<DistrictsAddModalFormData> = yup.object({
    name: yup.string().required(t('validation.required')),
    phoneNumber: yup.string().matches(/^\d{8}$/, {
      message: t('validation.must-be-valid-phone'),
      excludeEmptyString: true,
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DistrictsAddModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<DistrictsAddModalFormData> = data => {
    addDistrict({
      name: data.name,
      phoneNumber: data.phoneNumber,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('districts.modal.toast.title'),
          description: t('districts.modal.toast.description'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        })
        onClose()
        refetch()
      })
  }

  useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('districts.modal.title')}
      underTitle={t('districts.modal.under-title')}
      bodyRender={() => (
        <Stack width="100%">
          <FormInput
            label={t('district.modal.name')}
            error={errors.name}
            placeholder={t('districts.modal.name')}
            registeredForm={register('name')}
          />
          <FormInput
            error={errors.phoneNumber}
            label={t('district.modal.phone') + ' ' + t('global.optional')}
            inputMode="tel"
            placeholder={t('district.modal.phone')}
            registeredForm={register('phoneNumber')}
            description={t('district.modal.phone-description')}
          />
        </Stack>
      )}
      cancelButtonTitle={t('districts.modal.cancel-button')}
      submitButtonTitle={t('districts.modal.add-new-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default DistrictsAddModal
