import { Box, Flex, HStack, Spacer } from '@chakra-ui/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../../../../components/Card'
import CBadge from '../../../../../components/CBadge'
import { Option } from '../../../../../types/domainTypes'
import { CText } from '../../../../../components/index'
import { formattedImei } from '../../../../../utils/utils'

interface DevicesCardProps {
  battery: number
  status: boolean
  code: string
  assignedTo?: string
  confirmChoice: (option: Option) => Promise<boolean>
  options: Option[]
  onClick?: React.MouseEventHandler<HTMLDivElement>
  isPillIn?: boolean
}

const DevicesCard: React.FC<DevicesCardProps> = ({
  battery,
  status,
  code,
  assignedTo,
  confirmChoice,
  options,
  onClick,
  isPillIn,
}) => {
  const { t } = useTranslation()

  return (
    <Card onClick={onClick} cursor="pointer">
      <Flex mb="10px">
        <CText.SectionHeaderLg>{formattedImei(code)}</CText.SectionHeaderLg>
        <Spacer />
        {isPillIn !== undefined && <CBadge pillBoxIn={isPillIn} />}
      </Flex>
      <HStack spacing="40px">
        <Box>
          <CText.SecondaryText mb="2">
            {t('devices.table-header.assigned-to')}
          </CText.SecondaryText>
          <CText.SectionHeaderLg>
            {assignedTo || 'Not assigned'}
          </CText.SectionHeaderLg>
        </Box>
        <Box>
          <CText.SecondaryText mb="2">
            {t('devices.table-header.battery')}
          </CText.SecondaryText>
          <CText.Battery>{battery}%</CText.Battery>
        </Box>
      </HStack>
    </Card>
  )
}

export default DevicesCard
