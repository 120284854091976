import { HStack, Box } from '@chakra-ui/react'
import React from 'react'
import { mioRoomStatus } from '../../../../../redux/services/mio-api'

import { colorByRoomStatus } from '../../../../../utils/utils'
import { CText } from '../../../../../components'

interface MioStatusCirclesProps {
  status?: mioRoomStatus[]
}

const MioStatusCircles: React.FC<MioStatusCirclesProps> = ({ status }) => {
  return (
    <HStack spacing="1">
      {status ? (
        status.map((room: mioRoomStatus, index) => (
          <Box
            key={index}
            w="4"
            h="4"
            bgColor={colorByRoomStatus(room)}
            borderRadius="full"
          ></Box>
        ))
      ) : (
        <CText.PrimaryText>No device</CText.PrimaryText>
      )}
    </HStack>
  )
}

export default MioStatusCircles
