import { Box, Flex, Spacer } from '@chakra-ui/layout'
import React from 'react'
import { CText } from './index'
import { Icon } from '@chakra-ui/react'
import { MdChevronRight } from 'react-icons/md'
import { useHistory } from 'react-router'
import { RootState } from '../redux/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetUserByIdQuery } from '../redux/services/mio-api'

interface OnboardingContinueProps {}

const OnboardingContinue: React.FC<OnboardingContinueProps> = ({}) => {
  const history = useHistory()
  const session = useSelector((state: RootState) => state.session)
  const { data, isFetching } = useGetUserByIdQuery(session.id)

  const { t } = useTranslation()

  return (
    <>
      {data?.organisation?.configured === false && (
        <Box
          padding="10px"
          borderRadius="10px"
          bg={'#3D85E2'}
          cursor="pointer"
          role="group"
          w={{ base: 'full', lg: '400px' }}
          _hover={{ shadow: 'lg' }}
          transition="0.4s all"
          mb="10px"
          pl="16px"
          onClick={() => history.push('/dashboard/get-started')}
        >
          <Flex alignItems="center">
            <CText.SecondaryText color="mio-white">
              {t('home.continue-with-setup')}
            </CText.SecondaryText>
            <Spacer />
            <Icon
              _groupHover={{ transform: 'translateX(4px)' }}
              transition="0.5s all"
              color="mio-white"
              h="22px"
              w="22px"
              as={MdChevronRight}
            />
          </Flex>
        </Box>
      )}
    </>
  )
}

export default OnboardingContinue
