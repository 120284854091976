import { FormControl, FormErrorMessage, Checkbox } from '@chakra-ui/react'
import React from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface FormCheckboxProps {
  label?: string | JSX.Element
  error?: FieldError
  registeredForm: UseFormRegisterReturn
  id?: string
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  label,
  error,
  registeredForm,
  id,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <Checkbox {...registeredForm}>{label}</Checkbox>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}

export default FormCheckbox
