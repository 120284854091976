import React, { useEffect } from 'react'
import { Link, Text, VStack } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import LoginForm from './containers/LoginForm'
import { useToast } from '@chakra-ui/toast'
import { useLazyConfirmEmailQuery } from '../../../redux/services/mio-api'
import { Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

interface LoginScreenProps {
  isConfirmingEmail?: boolean
}

interface locationState {
  email: string
}

const LoginScreen: React.FC<LoginScreenProps> = ({ isConfirmingEmail }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const toast = useToast()
  const location = useLocation<locationState>()
  const [confirmEmail, result] = useLazyConfirmEmailQuery()

  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('token')

  useEffect(() => {
    if (isConfirmingEmail && code && result.isUninitialized) {
      toast({
        status: 'info',
        title: t('login.toast.confirm-email-pending.title'),
        // description: 'Your e-mail have been confirmed, you may now login!',
        duration: 5000,
        isClosable: true,
      })
      confirmEmail(code)
    }
    if (result.isSuccess && result.data.email) {
      toast({
        status: 'success',
        title: t('login.toast.confirmed-email.title'),
        description: t('login.toast.confirmed-email.description'),
        duration: 10000,
        isClosable: true,
      })
      history.push('/', { email: result.data.email })
    }
  }, [result])

  return (
    <VStack
      height="inherit"
      justifyContent="center"
      w="full"
      textAlign="center"
    >
      <Box w={{ base: '90%', md: '350px' }}>
        <LoginForm email={location.state?.email} />
      </Box>

      <VStack>
        <Text fontSize="13px" mt="20px" color="mio-primaryText">
          {t('login.forgot-password')}{' '}
          <Link
            fontWeight="bold"
            color="mio-headline"
            onClick={() => {
              history.push('/forgot-password')
            }}
          >
            {t('login.reset')}
          </Link>
        </Text>
        <Text fontSize="13px" color="mio-primaryText">
          {t('login.dont-have-an-account')}{' '}
          <Link
            fontWeight="bold"
            color="mio-headline"
            onClick={() => {
              history.push('/sign-up')
            }}
          >
            {t('login.sign-up')}
          </Link>
        </Text>
      </VStack>
    </VStack>
  )
}

export default LoginScreen
