import React from 'react'
import {
  Button as ChakraButton,
  ButtonProps as ChakraProps,
} from '@chakra-ui/react'

export interface CButtonProps {
  btnType: 'mio' | 'tinted' | 'regular' | 'warning' | 'dark' | 'tinted-warning'
}

const CButton = React.forwardRef<HTMLButtonElement, CButtonProps & ChakraProps>(
  (props, ref) => {
    const { btnType, ...restProps } = props

    const getType = () => {
      switch (btnType) {
        case 'mio':
          return {
            bg: 'mio',
            hover: 'mio-light',
            color: '#fff',
            shadow: 'base',
          }
        case 'regular':
          return {
            bg: 'mio-background',
            hover: 'mio-darkBackground',
            color: 'mio-headline',
            shadow: 'none',
          }
        case 'tinted':
          return {
            bg: '#fff',
            hover: '#fff',
            color: 'mio-headline',
            shadow: 'base',
          }
        case 'tinted-warning':
          return {
            bg: '#fff',
            hover: '#fff',
            color: 'mio-red',
            shadow: 'base',
          }
        case 'warning':
          return {
            bg: 'mio-red',
            hover: '#ED6464',
            color: '#fff',
            shadow: 'none',
          }
        case 'dark':
          return {
            bg: 'mio-headline',
            hover: 'mio-headline',
            color: '#fff',
            shadow: 'none',
          }
        default: {
          return {
            bg: 'mio-background',
            hover: 'mio-darkBackground',
            color: 'mio-headline',
            shadow: 'none',
          }
        }
      }
    }

    return (
      <ChakraButton
        ref={ref}
        data-test={`button-${props.children?.valueOf()}`}
        backgroundColor={getType()?.bg}
        shadow={getType().shadow}
        color={getType().color}
        fontSize={{ base: '14px', lg: '13px' }}
        h={{ base: '46px', lg: '40px' }}
        borderRadius={12}
        focusBorderColor="#7C7C7C"
        _hover={{ bg: getType()?.hover }}
        _active={{ bg: getType()?.bg }}
        {...restProps}
      >
        {props.children}
      </ChakraButton>
    )
  }
)

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
CButton.id = 'Button'

export default CButton
