import { UseToastOptions } from '@chakra-ui/toast'
import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import { captureException } from '@sentry/minimal'
import { TFunction } from 'i18next'

let toast: ((options: UseToastOptions) => any) | null = null
let t: TFunction | null = null

export const bindHooksToRTKErrorLogger = (_toast: any, _t: TFunction) => {
  toast = _toast
  t = _t
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      console.error(
        'WARNING: Redux async middleware rejected an action - the error is logged to Sentry.'
      )
      if (toast !== null && t !== null && action.payload.status !== 401) {
        captureException(new Error(JSON.stringify(action)))
        toast({
          title: t('rtk-error.toast.title'),
          description:
            action.payload?.data?.message ||
            t('rtk-error.toast.unknown-error') +
              (action.payload?.status ? ' - ' + action.payload.status : ''),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }

    return next(action)
  }
