import Icon from '@chakra-ui/icon'
import { Flex, Spacer, Text } from '@chakra-ui/layout'
import React from 'react'
import { MdChevronRight } from 'react-icons/md'

interface DrawerMenuItemProps {
  text: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const DrawerMenuItem: React.FC<DrawerMenuItemProps> = ({
  active,
  text,
  onClick,
  children,
}) => {
  return (
    <Flex
      onClick={onClick}
      width="100%"
      //py="12px"
      pl="16px"
      h="50px"
      borderRadius="16"
      fontSize="16px"
      mb="4px"
      fontWeight={active ? '600' : 'normal'}
      _hover={{ cursor: 'pointer' }}
      alignItems="center"
      justifyContent="space-between"
      //color={!active ? 'mio-secondaryText' : 'inherit'}
      color={active ? 'mio-headline' : 'mio-secondaryText'}
      data-test={`menuItem-${text}`}
      backgroundColor={active ? 'mio-darkBackground' : 'white'}
    >
      <Text fontWeight={active ? 'bold' : 'normal'}>{text}</Text>
      {/* <Spacer />
      <Icon h="28px" w="28px" as={MdChevronRight} /> */}
      {children}
    </Flex>
  )
}

export default DrawerMenuItem
