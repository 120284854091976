// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../constants'
import { RootState } from '../store'

interface User {
  id: number
  email: string
  password: string
}

export const reqresApi = createApi({
  reducerPath: 'reqresApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, api) => {
      // @ts-ignore
      const state: RootState = api.getState()
      const token = state.session.token
      if (token) {
        headers.set('Authentication', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: builder => ({
    register: builder.mutation<
      { id: number; token: string },
      Pick<User, 'email' | 'password'>
    >({
      query: body => ({
        url: '/register',
        method: 'POST',
        body,
      }),
    }),
    login: builder.mutation<
      { token: string },
      Pick<User, 'email' | 'password'>
    >({
      query: body => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    confirmEmail: builder.mutation<
      { name: string; job: string; id: string },
      { name: string; job: string }
    >({
      query: body => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<null, { email: string }>({
      query: body => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    getUserById: builder.query<
      {
        data: {
          id: number
          email: string
          first_name: string
          last_name: string
        }
      },
      number
    >({
      query: id => `/users/${id}`,
    }),
    getAllUsers: builder.query<
      {
        page: number
        per_page: number
        total: number
        total_pages: number
        data: {
          id: number
          email: string
          first_name: string
          last_name: string
          avatar: string
        }[]
        support: { url: string; text: string }
      },
      number
    >({
      query: pageNr => `/users?page=${pageNr}`,
      // query: pageNr => `/usrs?page=${pageNr}`,
      // query: pageNr => `/users/99999`,
    }),
  }),
})

export const {
  useRegisterMutation,
  useLoginMutation,
  useConfirmEmailMutation,
  useLazyGetUserByIdQuery,
  useGetUserByIdQuery,
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useResetPasswordMutation,
} = reqresApi
