import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import EntitySearchView from '../../../../containers/EntitySearchView'
import DevicesCard from './components/DevicesCard'
import DevicesAddModal from './containers/DevicesAddModal'
import AssignEntitySelect from '../../../../containers/AssignEntitySelect'
import { useToast } from '@chakra-ui/react'
import AsyncAlert from '../../../../containers/AsyncAlert'
import useAsyncAlert from '../../../../utils/useAsyncAlert'
import CBadge from '../../../../components/CBadge'
import { CText } from '../../../../components/index'
import { useHistory } from 'react-router'
import {
  Device,
  useAssignDeviceMutation,
  useDeleteDeviceMutation,
  useGetAllCitizensQuery,
  useGetAllDevicesQuery,
  useResetDeviceMutation,
} from '../../../../redux/services/mio-api'
import {
  fullName,
  formattedImei,
  colorByBatteryStatus,
} from '../../../../utils/utils'
import { Skeleton } from '@chakra-ui/skeleton'
import OnboardingContinue from '../../../../components/OnBoardingContinue'

const sampleData = [
  {
    id: 1,
    battery: 100,
    status: 'live',
    imei: '1502-8374-0183-9273',
    citizenId: 3,
    citizenFirstName: 'lol',
    citizenLastName: 'lol',
    box: true,
  },
  {
    id: 2,
    battery: 79,
    status: 'live',
    imei: '3830-1838-2013-7494',
    citizenId: 2,
    citizenFirstName: 'lol',
    citizenLastName: 'lol',
    box: true,
  },
  {
    id: 3,
    battery: 16,
    status: 'live',
    imei: '3928-4027-3048-2037',
    citizenId: 1,
    citizenFirstName: 'lol',
    citizenLastName: 'lol',
    box: true,
  },
]

interface DevicesScreenProps {}

const DevicesScreen: React.FC<DevicesScreenProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { data, isFetching, refetch } = useGetAllDevicesQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const citizens = useGetAllCitizensQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const [assignDevice] = useAssignDeviceMutation()
  const [deleteDevice] = useDeleteDeviceMutation()
  const [resetDevice] = useResetDeviceMutation()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      citizenName: string
      deviceImei: string
    },
    {
      citizenId: number
      deviceId: number
    }
  >()

  //Reset device
  const {
    openAlertAndAwaitConfirmation: openResetAlert,
    alertProps: resetAlertProps,
  } = useAsyncAlert<
    {
      imei: string
    },
    { id: number }
  >()

  //Delete device
  const {
    openAlertAndAwaitConfirmation: openDeleteAlert,
    alertProps: deleteAlertProps,
  } = useAsyncAlert<
    {
      imei: string
    },
    { id: number }
  >()

  const toast = useToast()

  const filteredCitizens =
    citizens.data?.citizens
      .filter(c => !c.deviceId)
      .map(fc => ({
        value: fc.id,
        name: fullName(fc.firstName, fc.lastName),
      })) || []

  const columns: ({
    accessor: keyof typeof sampleData[0]
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: t('devices.table-header.code') as string,
        accessor: 'imei',
        Cell: props => (
          <CText.TableText
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              let rowData = props.row.original as Device

              history.push(history.location.pathname + '/' + rowData.id)
            }}
          >
            {formattedImei(props.cell.value)}
          </CText.TableText>
        ),
      },
      {
        Header: t('devices.table-header.battery') as string,
        accessor: 'battery',
        Cell: props => (
          <CText.TableText color={colorByBatteryStatus(props.cell.value)}>
            {props.cell.value + '%'}
          </CText.TableText>
        ),
      },
      {
        Header: t('devices.table-header.status') as string,
        accessor: 'status',
        Cell: props => {
          const rowData = props.row.original as Device

          return rowData ? (
            <CBadge pillBoxIn={rowData.box} />
          ) : (
            <Skeleton>
              <CBadge pillBoxIn={true}>Pillbox in</CBadge>
            </Skeleton>
          )
        },
      },

      {
        Header: t('devices.table-header.assigned-to') as string,
        // Right now it will sort on ids and not names
        accessor: 'citizenId',
        Cell: props => {
          const rowData = props.row.original as Device

          let name
          if (rowData.citizenFirstName && rowData.citizenLastName) {
            name = fullName(rowData.citizenFirstName, rowData.citizenLastName)
          }

          return (
            <AssignEntitySelect
              placeholder={name ? name : t('devices.table.not-assigned')}
              options={
                props.value
                  ? [
                      ...filteredCitizens,
                      { value: -1, name: t('devices.table.unassign') },
                    ]
                  : filteredCitizens
              }
              value={name}
              tinted
              confirmChoice={async option => {
                return await openAlertAndAwaitConfirmation(
                  {
                    citizenName: option.name,
                    deviceImei: props.row.values.imei,
                  },
                  {
                    citizenId: option.value,
                    deviceId: rowData.id,
                  }
                )
              }}
            ></AssignEntitySelect>
          )
        },
      },
      // {
      //   Header: ' ',
      //   // @ts-ignore
      //   accessor: 'null_0',
      //   disableSortBy: true,
      //   Cell: props => {
      //     const device = props.cell.row.original as Device

      //     const onReset = async () => {
      //       await openResetAlert(
      //         {
      //           imei: device.imei,
      //         },
      //         { id: device.id }
      //       )
      //     }

      //     const onDelete = async () => {
      //       await openDeleteAlert(
      //         {
      //           imei: device.imei,
      //         },
      //         { id: device.id }
      //       )
      //     }

      //     let rows = [
      //       {
      //         title: t('devices.table.reset-device'),
      //         onPress: () => onReset(),
      //       },
      //       {
      //         title: t('devices.table.delete-device'),
      //         onPress: () => onDelete(),
      //         warning: true,
      //       },
      //     ]
      //     return <TableSettings rows={rows} />
      //   },
      // },
    ],
    [citizens]
  )

  return (
    <>
      <EntitySearchView
        header={t('devices.header')}
        searchPlaceholder={t('devices.search-placeholder')}
        sampleData={sampleData}
        columns={columns}
        data={data?.devices}
        isFetching={isFetching}
        noDataDescription={t('devices.no-data')}
        cardRender={elm => (
          <DevicesCard
            onClick={() => {
              history.push(history.location.pathname + '/' + elm.id)
            }}
            options={
              elm.citizenId || !filteredCitizens.length
                ? [
                    ...filteredCitizens,
                    { value: -1, name: t('devices.table.unassign') },
                  ]
                : filteredCitizens
            }
            battery={elm.battery}
            status={true}
            code={elm.imei}
            isPillIn={elm.box}
            assignedTo={
              elm.citizenFirstName && elm.citizenLastName
                ? fullName(elm.citizenFirstName, elm.citizenLastName)
                : undefined
            }
            confirmChoice={async option => {
              return await openAlertAndAwaitConfirmation(
                {
                  citizenName: option.name,
                  deviceImei: elm.imei,
                },
                {
                  citizenId: option.value,
                  deviceId: elm.id,
                }
              )
            }}
          />
        )}
        keyFn={elm => elm.id.toString()}
        filterFn={(search, elm) =>
          elm.imei.toLowerCase().startsWith(search.toLowerCase())
        }
        modalRender={(isOpen, onOpen, onClose) => (
          <DevicesAddModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            refetch={() => {
              citizens.refetch()
              refetch()
            }}
            options={filteredCitizens}
          />
        )}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `${t('devices.alert.assign')} ${formattedImei(data?.deviceImei)} ${t(
            'devices.alert.to'
          )} ${data?.citizenName}`
        }
        cancelButtonText={t('devices.alert.cancel-button')}
        submitButtonText={t('devices.alert.assign-button')}
        onClick={async payload => {
          await assignDevice({
            deviceId: payload?.deviceId as number,
            citizenId: payload?.citizenId as number,
          })
            .unwrap()
            .then(() => {
              citizens.refetch()
              refetch()
              toast({
                title: t('devices.assigned-toast.title'),
                description: t('devices.assigned-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...alertProps}
      />
      {/* <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `You are about to reset a device with the IMEI code: ${formattedImei(
            data?.imei
          )}`
        }
        cancelButtonText="Cancel"
        submitButtonText="Reset"
        onClick={async payload => {
          resetDevice({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              toast({
                title: 'Success',
                description: 'The device has successfully been reset.',
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...resetAlertProps}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `You are about to delete a device with the IMEI code: ${formattedImei(
            data?.imei
          )}`
        }
        isWarning
        cancelButtonText="Cancel"
        submitButtonText="Delete"
        onClick={async payload => {
          deleteDevice({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              toast({
                title: 'Success',
                description: 'The device has successfully been deleted.',
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
              history.push('/dashboard/devices')
            })
        }}
        {...deleteAlertProps}
      /> */}
    </>
  )
}

export default DevicesScreen
