import React from 'react'
import { HStack, VStack, Box, StackProps } from '@chakra-ui/react'
import { CText } from './index'
import { mioRoomStatus } from '../redux/services/mio-api'
import MioStatusCircles from '../screens/Dashboard/screens/Citizens/components/MioStatusCircles'
import { useTranslation } from 'react-i18next'

interface CitizenBoxProps {
  name: string
  address: string
  phone: string
  status?: mioRoomStatus[]
  district?: string
  imei?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactChild
  notDevice?: boolean
}

const CitizenBox: React.FC<CitizenBoxProps & StackProps> = props => {
  const {
    name,
    address,
    phone,
    status,
    onClick,
    district,
    imei,
    children,
    notDevice,
    ...restProps
  } = props

  const { t } = useTranslation()

  return (
    <HStack
      p="20px"
      bg="#fff"
      borderRadius="20px"
      shadow="base"
      justifyContent="space-between"
      alignItems="flex-start"
      transition="0.4s all"
      cursor="pointer"
      _hover={{ shadow: 'lg' }}
      onClick={onClick}
      w={{ base: '100%', lg: '380px' }}
      {...restProps}
    >
      <VStack
        alignItems="flex-start"
        pr="10px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        <CText.SectionHeaderLg>{name}</CText.SectionHeaderLg>
        <CText.SecondaryText>{address}</CText.SecondaryText>
        <CText.SecondaryText m="0px !important">{phone}</CText.SecondaryText>
        {district && (
          <CText.SecondaryText m="0px !important">
            {district}
          </CText.SecondaryText>
        )}
        {imei && (
          <CText.SecondaryText m="0px !important">
            {'#' + imei}
          </CText.SecondaryText>
        )}

        {children}
      </VStack>
      {status && (
        <Box pt="4px">
          <MioStatusCircles status={status}></MioStatusCircles>
        </Box>
      )}
      {!status ||
        (notDevice && (
          <CText.PrimaryText>{t('home.no-device')}</CText.PrimaryText>
        ))}
    </HStack>
  )
}

export default CitizenBox
