import { Stack, Wrap, WrapItem } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { LayoutProps } from '@chakra-ui/styled-system'
import React from 'react'

interface LoadingCardListProps<T> {
  data?: T[]
  isFetching: boolean
  sampleData: T[]
  cardRender: (elm: T) => JSX.Element
  keyFn: (elm: T) => string
  maxH?: LayoutProps['maxH']
  wrap?: boolean
}

const LoadingCardList = <T extends object>({
  data,
  isFetching,
  sampleData,
  cardRender,
  keyFn,
  maxH,
  wrap,
}: LoadingCardListProps<T>) => {
  if (wrap) {
    return (
      <Wrap
        spacing="10px"
        align="center"
        //overflowY={{ base: 'unset', lg: 'auto' }}
        overflowX="hidden"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            width: '0px',
          },
        }}
        //maxH={maxH || '55vh'}
        px={{ base: '16px', md: '30px', lg: '40px' }}
        pb={{ base: '16px', md: '30px', lg: '40px' }}
      >
        {isFetching || !data
          ? sampleData.map(elm => (
              <WrapItem key={keyFn(elm)}>
                <Skeleton borderRadius="20px">{cardRender(elm)}</Skeleton>
              </WrapItem>
            ))
          : data.map(elm => (
              <WrapItem borderRadius="20px" key={keyFn(elm)}>
                {cardRender(elm)}
              </WrapItem>
            ))}
      </Wrap>
    )
  }

  return (
    <Stack
      spacing={'10px'}
      //overflowY="auto"
      px={{ base: '16px', md: '30px', lg: '0px' }}
      pb={{ base: '16px', md: '30px', lg: '40px' }}
      //maxH={{ base: 'auto', lg: maxH || '55vh' }}
    >
      {isFetching || !data
        ? sampleData.map(elm => (
            <Skeleton borderRadius="20px" key={keyFn(elm)}>
              {cardRender(elm)}
            </Skeleton>
          ))
        : data.map(elm => (
            <React.Fragment key={keyFn(elm)}>{cardRender(elm)}</React.Fragment>
          ))}
    </Stack>
  )
}

export default LoadingCardList
