import React from 'react'
import { Badge } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export interface BadgeProps {
  children?: React.ReactNode
  pillBoxIn: boolean
}

const CBadge: React.FC<BadgeProps> = props => {
  const { children, pillBoxIn, ...restProps } = props
  const { t } = useTranslation()

  const getType = () => {
    switch (pillBoxIn) {
      case true:
        return {
          bg: 'mio-greenTint',
          color: '#3CA86A',
          text: t('device.pill-box-in'),
        }
      case false:
        return {
          bg: 'mio-redTint',
          color: 'mio-red',
          text: t('device.pill-box-out'),
        }
    }
  }

  return (
    <Badge
      textTransform="uppercase"
      fontSize="11"
      data-testid="Badge"
      color={getType().color}
      bg={getType().bg}
      display="flex"
      h="25px"
      justifyContent="center"
      alignItems="center"
      px="8px"
      borderRadius="6px"
      {...restProps}
    >
      {getType().text}
    </Badge>
  )
}

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
CBadge.id = 'CBadge'

export default CBadge
