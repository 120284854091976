import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Role, UserType } from '../../types/domainTypes'

export interface SessionState {
  active: boolean
  token?: string
  refreshToken?: string
  tokenExpires?: string
  id: number
  role?: Role
  isFetchingToken: boolean
  isConfigured: boolean
  userType?: UserType
}

const initialState: SessionState = {
  active: false,
  token: undefined,
  refreshToken: undefined,
  tokenExpires: undefined,
  id: -1,
  role: undefined,
  isFetchingToken: false,
  isConfigured: false,
  userType: undefined,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    startSession: (
      state,
      action: PayloadAction<{
        token: string
        refreshToken: string
        tokenExpires: string
        id: number
        role: Role
        userType: UserType
      }>
    ) => {
      state.active = true
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.tokenExpires = action.payload.tokenExpires
      state.id = action.payload.id
      state.role = action.payload.role
      state.userType = action.payload.userType
    },
    endSession: state => {
      state = initialState
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
      window.localStorage.removeItem('tokenExpires')
      window.localStorage.removeItem('userId')
      window.localStorage.removeItem('userRole')
      window.localStorage.removeItem('userType')
      // state.active = false
      // state.token = ''
      // state.uuid = ''
    },
    updateTokens: (
      state,
      action: PayloadAction<{
        token: string
        refreshToken: string
        tokenExpires: string
      }>
    ) => {
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.tokenExpires = action.payload.tokenExpires

      window.localStorage.setItem('token', action.payload.token)
      window.localStorage.setItem('refreshToken', action.payload.refreshToken)
      window.localStorage.setItem('tokenExpires', action.payload.tokenExpires)
    },
    updateFetchingToken: (state, action: PayloadAction<boolean>) => {
      state.isFetchingToken = action.payload
    },
    updateIsConfigured: (state, action: PayloadAction<boolean>) => {
      state.isConfigured = action.payload
    },
  },
})

export const {
  startSession,
  endSession,
  updateTokens,
  updateFetchingToken,
  updateIsConfigured,
} = sessionSlice.actions

export default sessionSlice.reducer
