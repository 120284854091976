import { Center } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import React from 'react'

interface SuspenseLoaderProps {}

const SuspenseLoader: React.FC<SuspenseLoaderProps> = () => {
  return (
    <Center h="100vh">
      <Spinner
        thickness="5px"
        speed="0.65s"
        emptyColor="gray.200"
        color="mio"
        size="xl"
      />
    </Center>
  )
}

export default SuspenseLoader
