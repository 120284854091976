import { Stack } from '@chakra-ui/react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../components/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CButton, Step, CText } from '../../../../components/index'
import { CompleteSignupFormData } from '../SignupScreen'

interface AccountHolderFormData {
  firstName: string
  lastName: string
  email: string
  phone: string
}

interface AccountHolderFormProps {
  onSubmit: SubmitHandler<AccountHolderFormData>
  data?: CompleteSignupFormData
}

const AccountHolderForm: React.FC<AccountHolderFormProps> = ({
  onSubmit,
  data,
}) => {
  const { t } = useTranslation()

  const schema: yup.SchemaOf<AccountHolderFormData> = yup.object({
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.must-be-valid-email')),
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    phone: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AccountHolderFormData>({
    resolver: yupResolver(schema),
  })

  if (data?.email) {
    setValue('email', data.email)
  }

  if (data?.firstName) {
    setValue('firstName', data.firstName)
  }

  if (data?.lastName) {
    setValue('lastName', data.lastName)
  }

  if (data?.phone) {
    setValue('phone', data.phone)
  }

  return (
    <Stack
      w={{ base: '90%', md: '350px' }}
      flexDirection="column"
      textAlign="left"
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Step step={2} of={3} />
        <CText.HeaderLg mb="40px">
          {t('signup.account-holder-title')}
        </CText.HeaderLg>
        <Stack pb="6">
          <FormInput
            label={t('signup.first-name')}
            placeholder={t('signup.first-name')}
            error={errors.firstName}
            registeredForm={register('firstName', {
              required: t('signup.required') as string,
            })}
            id="first_name"
            tinted={true}
          />
          <FormInput
            label={t('signup.last-name')}
            placeholder={t('signup.last-name')}
            error={errors.firstName}
            registeredForm={register('lastName', {
              required: t('signup.required') as string,
            })}
            id="last_name"
            tinted={true}
          />
          <FormInput
            label={t('signup.email')}
            placeholder={t('signup.email')}
            inputMode="email"
            error={errors.email}
            registeredForm={register('email', {
              required: t('signup.required') as string,
            })}
            id="email"
            tinted={true}
          />
          <FormInput
            label={t('signup.phone')}
            placeholder={t('signup.phone')}
            inputMode="tel"
            error={errors.phone}
            registeredForm={register('phone', {
              required: t('signup.required') as string,
            })}
            id="phone"
            tinted={true}
            leftAddon="+45"
          />
        </Stack>

        <CButton btnType="mio" type="submit" w="full">
          {t('signup.continue-button')}
        </CButton>
      </form>
    </Stack>
  )
}

export default AccountHolderForm
