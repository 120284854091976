import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startSession } from '../redux/slices/sessionSlice'
import { Role, UserType } from '../types/domainTypes'

const JWTProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const token = window.localStorage.getItem('token')
    const refreshToken = window.localStorage.getItem('refreshToken')
    const tokenExpires = window.localStorage.getItem('tokenExpires')
    const id = window.localStorage.getItem('userId')
    const role = window.localStorage.getItem('userRole') as Role
    const userType = window.localStorage.getItem('userType') as UserType

    if (token && refreshToken && tokenExpires && id && role) {
      // Received token from previous session
      dispatch(
        startSession({
          token,
          refreshToken,
          tokenExpires: tokenExpires,
          role,
          id: parseInt(id),
          userType,
        })
      )
    }
  }, [])

  return <>{children}</>
}

export default JWTProvider
