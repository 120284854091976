import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCog } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { Box, HStack, Stack } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import {
  CText,
  PageHeader,
  ActionContainer,
  CButton,
  LanguagePicker,
} from '../../../../components/index'
import { useGetUserByIdQuery } from '../../../../redux/services/mio-api'
import { firstCharToUpper, roleToI18nKey } from '../../../../utils/utils'
import { endSession } from '../../../../redux/slices/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import AccountSettingsModal from './containers/AccountSettingsModal'
import ResetPasswordModal from './containers/ResetPasswordModal'
import { RootState } from '../../../../redux/store'
import { Role } from '../../../../types/domainTypes'

interface CitizensScreenProps {}

const CitizensScreen: React.FC<CitizensScreenProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const session = useSelector((state: RootState) => state.session)

  const { data, isFetching, refetch } = useGetUserByIdQuery(session.id)

  const settingsModal = useDisclosure()
  const resetPasswordModal = useDisclosure()

  const userName = [
    {
      title: t('account.first-name'),
      sampleText: 'Fornavn',
      data: firstCharToUpper(data?.firstName || ''),
    },
    {
      title: t('account.last-name'),
      sampleText: 'Efternavn',
      data: firstCharToUpper(data?.lastName || ''),
    },
  ]

  const organisationData = [
    {
      title: t('account.organisation-name'),
      sampleText: 'wemuda ApS',
      data: data?.organisation?.name,
    },
    {
      title: t('account.organisation-cvr'),
      sampleText: '12121212',
      data: data?.organisation?.cvr,
    },
  ]

  const userData = [
    {
      title: t('account.email'),
      sampleText: 'email@example.com',
      data: data?.email,
    },
    {
      title: t('account.phone'),
      sampleText: '12345678',
      data: data?.phoneNumber,
    },
    {
      title: t('account.role'),
      sampleText: 'Superuser',
      data: t(roleToI18nKey(data?.roles[0]) || ''),
    },
  ]

  return (
    <Box p={{ base: '16px', md: '30px', lg: '40px' }}>
      <PageHeader title={t('account.account')} sampleText="Account">
        <>
          <CButton
            btnType="tinted"
            leftIcon={<FaCog />}
            onClick={settingsModal.onOpen}
          >
            {t('account.edit')}
          </CButton>

          <Box>
            <LanguagePicker />
          </Box>

          <CButton
            btnType="tinted-warning"
            onClick={() => {
              dispatch(endSession())
              history.push('/')
            }}
          >
            {t('account.log-out')}
          </CButton>
        </>
      </PageHeader>

      <Stack spacing="30px">
        <HStack spacing="40px">
          {userName.map(item => (
            <Box>
              <CText.SectionHeader>{item.title}</CText.SectionHeader>
              <CText.PrimaryText
                isLoading={isFetching}
                sampleText={item.sampleText}
              >
                {item.data}
              </CText.PrimaryText>
            </Box>
          ))}
        </HStack>
        {data?.userType === 'Organisation' && data.organisation && (
          <HStack spacing="40px">
            {organisationData.map(item => (
              <Box>
                <CText.SectionHeader>{item.title}</CText.SectionHeader>
                <CText.PrimaryText
                  isLoading={isFetching}
                  sampleText={item.sampleText}
                >
                  {item.data}
                </CText.PrimaryText>
              </Box>
            ))}
          </HStack>
        )}
        {userData.map(item => (
          <Box>
            <CText.SectionHeader>{item.title}</CText.SectionHeader>
            <CText.PrimaryText
              isLoading={isFetching}
              sampleText={item.sampleText}
            >
              {item.data}
            </CText.PrimaryText>
          </Box>
        ))}

        <Stack>
          <ActionContainer
            title={t('account.reset-pw.title')}
            description={t('account.reset-pw.description')}
          >
            <CButton onClick={resetPasswordModal.onOpen} btnType="dark">
              {t('account.reset-pw.button')}
            </CButton>
          </ActionContainer>
          <ActionContainer
            title={t('account.delete.title')}
            description={t('account.delete.description')}
          >
            <CButton btnType="warning" disabled>
              {t('account.delete.button')}
            </CButton>
          </ActionContainer>
        </Stack>
      </Stack>
      {data && (
        <AccountSettingsModal
          refetch={refetch}
          isOpen={settingsModal.isOpen}
          onClose={settingsModal.onClose}
          onOpen={settingsModal.onOpen}
          firstName={firstCharToUpper(data?.firstName || '')}
          lastName={firstCharToUpper(data?.lastName || '')}
          phoneNumber={data?.phoneNumber}
          id={data?.id}
          language={data.organisation?.language}
          userType={data.userType}
          organisationName={data.organisation?.name}
          cvr={data.organisation?.cvr}
        />
      )}
      <ResetPasswordModal
        refetch={refetch}
        isOpen={resetPasswordModal.isOpen}
        onClose={resetPasswordModal.onClose}
        onOpen={resetPasswordModal.onOpen}
      />
    </Box>
  )
}

export default CitizensScreen
