import { HStack, Text } from '@chakra-ui/layout'
import { Box, Heading } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import CButton from '../../../components/CButton'
import { endSession } from '../../../redux/slices/sessionSlice'
import { RootState } from '../../../redux/store'
import { useIsDesktop } from '../../../utils/useIsDesktop'

const DashboardAuthorizationChecker: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const session = useSelector((state: RootState) => state.session)
  // const [getUserById] = useLazyGetUserByIdQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (session.active && session.id) {
      // getUserById(session.id)
    }
  }, [])

  if (!session.active || !session.id) {
    return (
      <Box p="12">
        <Heading mb={4}>{t('dashboard-unauthorized.title')}</Heading>
        <Text mb={2} maxW={isDesktop ? '50vw' : '90vw'}>
          {t('dashboard-unauthorized.description')}
        </Text>
        <HStack>
          <CButton
            btnType="warning"
            onClick={() => {
              dispatch(endSession())
              history.push('/')
            }}
          >
            {t('dashboard-unauthorized.logout-button')}
          </CButton>
        </HStack>
      </Box>
    )
  }
  return <>{children}</>
}

export default DashboardAuthorizationChecker
