import { TFunction } from 'i18next'
import * as yup from 'yup'

const validator: (t: TFunction) => yup.StringSchema<string | undefined> = t =>
  yup
    .string()
    .min(8, t('validation.too-short'))
    .max(30, t('validation.too-long'))
    .test(
      'containsUpperCase',
      t('validation.least-one-big-letter'),
      (item: string | undefined) =>
        item !== undefined && item.toLowerCase() !== item
    )
    .test(
      'containsLowerCase',
      t('validation.least-one-small-letter'),
      (item: string | undefined) =>
        item !== undefined && item.toUpperCase() !== item
    )
    .matches(/\d/, t('validation.least-one-number'))

export default validator
