import { Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormTextArea from '../../../../../components/FormTextArea'
import * as yup from 'yup'
import { SettingsDrawer } from '../../../../../components/index'
import { useUpdateCitizenMutation } from '../../../../../redux/services/mio-api'

interface CitizensAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  note?: string
  citizenId: number
}

type CitizenNoteDrawerFormData = {
  note: string | undefined
}

const CitizenNoteDrawer: React.FC<CitizensAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  note,
  citizenId,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [updateCitizen, { isLoading }] = useUpdateCitizenMutation()

  const schema: yup.SchemaOf<CitizenNoteDrawerFormData> = yup.object({
    note: yup.string(),
  })

  const { register, handleSubmit, reset, setValue } =
    useForm<CitizenNoteDrawerFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<CitizenNoteDrawerFormData> = data => {
    updateCitizen({
      id: citizenId,
      note: data.note,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: 'Success',
          description: t('citizen.note-drawer.toast'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) reset()

    if (note) {
      setValue('note', note)
    }
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('citizen.note-drawer.title')}
      underTitle={t('citizen.note-drawer.under-title')}
      bodyRender={() => (
        <Stack width="100%">
          <FormTextArea
            placeholder={t('citizen.note-drawer.placeholder')}
            registeredForm={register('note')}
          />
        </Stack>
      )}
      cancelButtonTitle={t('citizen.note-drawer.cancel-button')}
      submitButtonTitle={t('citizen.note-drawer.save-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default CitizenNoteDrawer
