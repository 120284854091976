import React from 'react'
import { VStack, Flex, Wrap, FlexProps } from '@chakra-ui/react'

import { CText } from './index'
import { useIsDesktop } from '../utils/useIsDesktop'

interface ActionContainerProps {
  title?: string
  description?: string
  children?: React.ReactChild[] | React.ReactChild
  dir?: 'row' | 'column'
}

const ActionContainer: React.FC<ActionContainerProps & FlexProps> = props => {
  const isDesktop = useIsDesktop()

  const { title, description, children, dir, ...restProps } = props

  return (
    <Flex
      flexDir={(!isDesktop && 'column') || dir || 'row'}
      data-testid="TimeSetting"
      maxW={{ md: '100%', lg: '500px' }}
      borderRadius="22"
      bg="transparent"
      borderColor="#D9D9D9"
      borderWidth="0.5px"
      p="18px"
      overflow="hidden"
      justifyContent="space-between"
      {...restProps}
    >
      <VStack alignItems="flex-start" pr="10px">
        <CText.SectionHeader>{title}</CText.SectionHeader>
        <CText.PrimaryText>{description}</CText.PrimaryText>
      </VStack>
      <Wrap
        direction="row"
        marginTop={dir === 'column' || !isDesktop ? '3' : 'none'}
        marginLeft={dir !== 'row' || !isDesktop ? 'none' : '10'}
        alignItems="center"
      >
        {children}
      </Wrap>
    </Flex>
  )
}

export default ActionContainer
