import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useUpdateUserMutation } from '../../../../../redux/services/mio-api'
import { firstCharToUpper } from '../../../../../utils/utils'
import {
  SettingsDrawer,
  FormInput,
  FormSelect,
} from '../../../../../components/index'

interface AccountSettingsModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  firstName?: string
  lastName?: string
  phoneNumber?: string
  id: number
  language?: string
  userType: string
  organisationName?: string
  cvr?: string
}

type AccountSettingsModalForm = {
  firstName: string
  lastName: string
  phoneNumber: string
  language?: string
  organisationName?: string
  cvr?: string
}

const AccountSettingsModal: React.FC<AccountSettingsModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  firstName,
  lastName,
  phoneNumber,
  id,
  language,
  userType,
  organisationName,
  cvr,
}) => {
  const { t } = useTranslation()

  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const toast = useToast()

  const schema: yup.SchemaOf<AccountSettingsModalForm> = yup.object({
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, t('validation.only-digits'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits'))
      .transform(value => (value === '' ? undefined : value))
      .required(t('validation.required')),
    language: yup.string().transform(value => {
      if (parseInt(value) === 0) {
        return 'Dansk'
      } else if (parseInt(value) === 1) {
        return 'English'
      }
      return undefined
    }),
    organisationName: yup.string(),
    cvr: yup
      .string()
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<AccountSettingsModalForm>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<AccountSettingsModalForm> = data => {
    updateUser({
      id: id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      language: data.language,
      cvr: data.cvr,
      organisationName: data.organisationName,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('account.update-toast.title'),
          description: t('account.update-toast.description'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }

    if (firstName) {
      setValue('firstName', firstCharToUpper(firstName))
    }

    if (lastName) {
      setValue('lastName', firstCharToUpper(lastName))
    }

    if (phoneNumber) {
      setValue('phoneNumber', phoneNumber)
    }

    if (organisationName) {
      setValue('organisationName', organisationName)
    }

    if (cvr) {
      setValue('cvr', cvr)
    }
  }, [isOpen])

  let options = [
    { value: 0, name: 'Dansk' },
    { value: 1, name: 'English' },
  ]

  if (language) {
    options = options.filter(l => {
      return l.name !== firstCharToUpper(language)
    })
  }

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('account.settings.title')}
      underTitle={t('account.settings.under-title')}
      bodyRender={() => (
        <VStack>
          <Stack width="100%" mb="30px" spacing="20px">
            <FormInput
              error={errors.firstName}
              label={t('account.settings.first-name')}
              placeholder={t('account.settings.first-name')}
              registeredForm={register('firstName')}
            />
            <FormInput
              error={errors.lastName}
              label={t('account.settings.last-name')}
              placeholder={t('account.settings.last-name')}
              registeredForm={register('lastName')}
            />
            <FormInput
              error={errors.phoneNumber}
              label={t('account.settings.phone')}
              placeholder={t('account.settings.phone')}
              registeredForm={register('phoneNumber')}
            />
            {userType === 'Organisation' && (
              <FormInput
                error={errors.organisationName}
                label={t('account.settings.organisation-name')}
                placeholder={t('account.settings.organisation-name')}
                registeredForm={register('organisationName')}
              />
            )}
            {userType === 'Organisation' && (
              <FormInput
                error={errors.cvr}
                label={t('account.settings.organisation-cvr')}
                placeholder={t('account.settings.organisation-cvr')}
                registeredForm={register('cvr')}
              />
            )}
            {userType === 'Organisation' && (
              <FormSelect
                error={errors.language}
                label={t('account.sms-lang.title')}
                placeholder={
                  language ? firstCharToUpper(language) : t('language')
                }
                registeredForm={register('language')}
                options={options}
                description={t('account.sms-lang.description')}
              />
            )}
          </Stack>
        </VStack>
      )}
      cancelButtonTitle={t('account.settings.cancel-button')}
      submitButtonTitle={t('account.settings.save-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default AccountSettingsModal
