import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  useUpdateCitizenMutation,
  useDeleteCitizenMutation,
} from '../../../../../redux/services/mio-api'
import { firstCharToUpper, fullName } from '../../../../../utils/utils'
import {
  ActionContainer,
  CButton,
  FormInput,
  FormSelect,
  SettingsDrawer,
} from '../../../../../components/index'
import { Option } from '../../../../../types/domainTypes'
import AsyncAlert from '../../../../../containers/AsyncAlert'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import { useHistory } from 'react-router-dom'

interface CitizenSettingsModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  firstName?: string
  lastName?: string
  address?: string
  phoneNumber?: string
  district?: string
  districtId?: number
  deviceImei?: string
  deviceId?: number
  devices: Option[]
  districts: Option[]
  id: number
}

type CitizenSettingsModalForm = {
  firstName: string
  lastName: string
  address?: string
  phoneNumber: string
  districtId?: number
  deviceId?: number
}

const CitizenSettingsModal: React.FC<CitizenSettingsModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  firstName,
  lastName,
  phoneNumber,
  address,
  district,
  districtId,
  deviceImei,
  deviceId,
  devices,
  districts,
  id,
}) => {
  const { t } = useTranslation()

  const [updateCitizen, { isLoading }] = useUpdateCitizenMutation()
  const [deleteCitizen] = useDeleteCitizenMutation()

  const toast = useToast()

  const history = useHistory()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      name: string
    },
    {}
  >()

  const schema: yup.SchemaOf<CitizenSettingsModalForm> = yup.object({
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, t('validation.only-digits'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits'))
      .transform(value => (value === '' ? undefined : value))
      .required(t('validation.required')),
    address: yup.string().required(t('validation.required')),
    districtId: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value)),
    deviceId: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CitizenSettingsModalForm>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<CitizenSettingsModalForm> = data => {
    updateCitizen({
      id: id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      deviceId: data.deviceId,
      districtId: data.districtId,
      address: data.address,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('citizen.update-toast.title'),
          description: t('citizen.update-toast.description'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }

    if (firstName) {
      setValue('firstName', firstCharToUpper(firstName))
    }

    if (lastName) {
      setValue('lastName', firstCharToUpper(lastName))
    }

    if (address) {
      setValue('address', address)
    }

    if (phoneNumber) {
      setValue('phoneNumber', phoneNumber)
    }
  }, [isOpen])

  return (
    <>
      <SettingsDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t('citizen.modal.title')}
        underTitle={t('citizen.modal.under-title')}
        bodyRender={() => (
          <VStack>
            <Stack width="100%" mb="30px" spacing="20px">
              <FormInput
                error={errors.firstName}
                label={t('citizen.modal.firstName')}
                placeholder={t('citizen.modal.firstName')}
                registeredForm={register('firstName')}
              />
              <FormInput
                error={errors.lastName}
                label={t('citizen.modal.lastName')}
                placeholder={t('citizen.modal.lastName')}
                registeredForm={register('lastName')}
              />
              <FormInput
                error={errors.address}
                label={t('citizen.modal.address')}
                placeholder={t('citizen.modal.address')}
                registeredForm={register('address')}
              />

              <FormInput
                error={errors.phoneNumber}
                label={t('citizen.modal.phoneNumber')}
                placeholder={t('citizen.modal.phoneNumber')}
                registeredForm={register('phoneNumber')}
                inputMode="tel"
              />

              <FormSelect
                error={errors.districtId}
                label={t('citizen.modal.district')}
                placeholder={district ? district : t('citizen.modal.district')}
                registeredForm={register('districtId')}
                options={districts}
              />
              <FormSelect
                error={errors.deviceId}
                label={t('citizen.modal.device') + ' ' + t('global.optional')}
                placeholder={
                  deviceImei ? deviceImei : t('citizen.modal.device')
                }
                registeredForm={register('deviceId')}
                options={devices}
              />
            </Stack>
            <ActionContainer
              w="100%"
              title={t('citizen.modal.delete-title')}
              description={t('citizen.modal.delete-description')}
            >
              <CButton
                btnType="warning"
                onClick={async () => {
                  await openAlertAndAwaitConfirmation()
                }}
              >
                {t('citizen.modal.delete-button')}
              </CButton>
            </ActionContainer>
          </VStack>
        )}
        cancelButtonTitle={t('citizen.modal.cancel-button')}
        submitButtonTitle={t('citizen.modal.save-button')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitLoading={isLoading}
        formOnSubmit={handleSubmit(onSubmit)}
      />
      {firstName && lastName && (
        <AsyncAlert
          title="Are you sure?"
          isWarning
          bodyText={`${t('citizen.alert.description', {
            name: fullName(firstName, lastName),
          })}`}
          cancelButtonText={t('citizen.alert.cancel-button')}
          submitButtonText={t('citizen.alert.delete-button')}
          onClick={async () => {
            deleteCitizen({
              id: id,
            })
              .unwrap()
              .then(() => {
                toast({
                  title: t('citizen.toast.title'),
                  description: t('citizen.toast.description'),
                  duration: 5000,
                  isClosable: true,
                  status: 'success',
                })
                onClose()
                history.push('/dashboard/citizens')
              })
          }}
          {...alertProps}
        />
      )}
    </>
  )
}

export default CitizenSettingsModal
