import { Heading } from '@chakra-ui/react'
import React from 'react'
import LegalScreen from './components/LegalScreen'
import LegalSubHeading from './components/LegalSubHeading'

const PrivacyPolicyScreen: React.FC = () => {
  return (
    <LegalScreen>
      <Heading>Privacy Policy</Heading>
      <LegalSubHeading>TBD/WIP</LegalSubHeading>
    </LegalScreen>
  )
}

export default PrivacyPolicyScreen
