import { mioRoomStatus } from '../redux/services/mio-api'
import { Role } from "../types/domainTypes"
import { useTranslation } from 'react-i18next'

export const roleToI18nKey = (role: any) => {
  switch (role) {
    case 'superUser':
      return 'global.superUser'
    case 'employeeAdmin':
      return 'global.employeeAdmin'
    case 'employee':
      return 'global.employee'
  }
}

export const fullName = (firstName: string, lastName: string) => {
  return `${firstCharToUpper(firstName)} ${firstCharToUpper(lastName)}`
}

export const firstCharToUpper = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export const formattedImei = (imei: string | undefined) => {
  let code = imei
  if (!code) return undefined
  return (
    code.slice(0, 4) +
    '-' +
    code.slice(4, 8) +
    '-' +
    code.slice(8, 12) +
    '-' +
    code.slice(12, 16)
  )
}

export const colorByBatteryStatus = (battery: number) => {
  if (battery < 10) return 'mio-red'
  else return 'mio-greenDark'
}

export const colorByRoomStatus = (status: mioRoomStatus) => {
  switch (status) {
    case 'empty':
      return 'mio-gray'
    case 'notTaken':
      return 'mio-gray'
    case 'overdue':
      return 'mio-red'
    case 'taken':
      return 'mio-greenLight'
    case 'takenLate':
      return 'mio-yellowLight'
    case 'takenEarly':
      return 'mio-yellowLight'
  }
}

export const formatTimeString = (hour: number, min: number) => {
  return `${hour}:${min > 9 ? min : '0' + min}`
}

export const timeStringToNumbers = (time: string) => {
  if (time.includes('.')) {
    const split = time.split(".")

    return {
      hour: parseInt(split[0]),
      min: parseInt(split[1]),
    }
  } else if (time.includes(':')) {
    const split = time.split(':')

    return {
      hour: parseInt(split[0]),
      min: parseInt(split[1]),
    }
  }

  else {
    return {
      hour: parseInt(time),
      min: 0,
    }
  }

}

export const initialsFromFullName = (fullName: string) => {
  const initials = fullName
    .split(' ')
    .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
    .join('')
  return initials
}
