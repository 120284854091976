import { VStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface StepProps {
  step: number
  of: number
}

export default function Step({ step, of }: StepProps) {
  const { t } = useTranslation()

  return (
    <VStack textAlign="left" pb="10px" alignItems="flex-start">
      <Text fontSize="13px" color="mio-secondaryText">
        {t('signup.step')} {step} {t('signup.of')} {of}
      </Text>
    </VStack>
  )
}
