import { Stack } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CLink from '../../../components/CLink'
import { useRegisterMutation } from '../../../redux/services/mio-api'
import EmployeeSignupForm from './containers/EmployeeSignupForm'
import { UserType } from '../../../types/domainTypes'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

interface EmployeeSignupScreenProps {}

export interface CompleteSignupFormData {
  organisation?: string
  cvr?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  password?: string
  confirmpassword?: string
}

const EmployeeSignupScreen: React.FC<EmployeeSignupScreenProps> = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [register, { isLoading: isUpdating }] = useRegisterMutation()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  if (token == null) {
    history.push('/')
  }

  return (
    <Stack height="inherit" alignItems="center" justifyContent="center">
      <EmployeeSignupForm
        isSubmitting={isUpdating}
        onSubmit={data => {
          if (data.password && data.phone && data.firstName && data.lastName) {
            register({
              password: data.password,
              userType: 'Employee' as UserType,
              phoneNumber: data.phone,
              firstName: data.firstName,
              lastName: data.lastName,
              token: token as string,
            })
              .unwrap()
              .then(data => {
                toast({
                  status: 'success',
                  description: t('signup.toast.account-success.description'),
                  title: t('signup.toast.account-success.title'),
                  isClosable: true,
                })
                history.push('/', { email: data.email })
              })
          }
        }}
      />
      <CLink title="Back to login" href="/" mt="20px !important" />
    </Stack>
  )
}

export default EmployeeSignupScreen
