import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import { CButton } from '../components/index'
import React, { useRef, useState } from 'react'

interface AsyncAlertProps<T, Y> {
  title: ((data?: T) => string) | string
  bodyText: ((data?: T) => string) | string
  cancelButtonText: string
  submitButtonText: string
  alertState: { isOpen: boolean; data?: T; onClickPayload?: Y }
  setAlertState: (alertState: { isOpen: boolean; data?: T }) => void
  confirmPromiseRef: React.MutableRefObject<((value: boolean) => void) | null>
  onClick: (payload?: Y) => Promise<void>
  isWarning?: boolean
}

const AsyncAlert = <T extends {}, Y extends {}>({
  title,
  bodyText,
  cancelButtonText,
  submitButtonText,
  alertState,
  setAlertState,
  confirmPromiseRef,
  onClick,
  isWarning,
}: AsyncAlertProps<T, Y>) => {
  const onClose = () => {
    setAlertState({ isOpen: false })
    confirmPromiseRef.current?.(false)
  }
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <AlertDialog
      isOpen={alertState.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius={16} p="3" pt="3">
          <AlertDialogHeader
            fontSize="17px"
            color="mio-headline"
            fontWeight="700"
          >
            {typeof title === 'function' ? title(alertState.data) : title}
          </AlertDialogHeader>

          <AlertDialogBody
            fontSize="15px"
            color="mio-primaryText"
            fontWeight="400"
          >
            {typeof bodyText === 'function'
              ? bodyText(alertState.data)
              : bodyText}
          </AlertDialogBody>

          <AlertDialogFooter>
            {/* @ts-ignore */}
            <CButton btnType="regular" ref={cancelRef} onClick={onClose}>
              {cancelButtonText}
            </CButton>
            <CButton
              btnType={isWarning ? 'warning' : 'dark'}
              onClick={async () => {
                try {
                  setIsLoading(true)
                  await onClick(alertState.onClickPayload)
                  confirmPromiseRef.current?.(true)
                  onClose()
                } catch (e) {
                  confirmPromiseRef.current?.(false)
                } finally {
                  setIsLoading(false)
                }
              }}
              ml={3}
              isLoading={isLoading}
            >
              {submitButtonText}
            </CButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AsyncAlert
