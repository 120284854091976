import {
  Box,
  VStack,
  useDisclosure,
  Stack,
  useToast,
  Wrap,
  HStack,
} from '@chakra-ui/react'
import DeviceInfo from '../components/DeviceInfo'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import CLink from '../../../../../components/CLink'
import { IoIosStarOutline, IoIosStar } from 'react-icons/io'
import {
  useGetAllDevicesQuery,
  useGetAllDistrictsQuery,
  useGetCitizenByIdQuery,
  useLazyFavoriteCitizenQuery,
  useAssignDeviceMutation,
} from '../../../../../redux/services/mio-api'
import PillBox from '../components/PillBox'
import CButton from '../../../../../components/CButton'
import { IoMdSettings } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import EditTimeDrawer from '../components/EditTimeDrawer'
import CitizenInfo from '../components/CitizenInfo'
import { fullName, formattedImei } from '../../../../../utils/utils'
import { IconButton } from '../../../../../components/index'
import { CText, PageHeader, CBadge } from '../../../../../components/index'
import CitizenSettingsModal from '../containers/CitizenSettingsModal'
import useRoleCheck from '../../../../../utils/useRoleCheck'
import { Role } from '../../../../../types/domainTypes'
import PillBoxPlaceholder from '../components/PillBoxPlaceholder'
import AssignEntitySelect from '../../../../../containers/AssignEntitySelect'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import AsyncAlert from '../../../../../containers/AsyncAlert'
import { MdEdit } from 'react-icons/md'
import CitizenNoteDrawer from '../containers/CitizenNoteDrawer'
import { IoIosWarning } from 'react-icons/io'
import Badge from '../../../../../components/Badge'
import moment from 'moment'
import EditVolumeDrawer from '../components/EditVolumeDrawer'

interface CitizenScreenProps {}

const CitizenScreen: React.FC<CitizenScreenProps> = () => {
  const { id } = useParams<{ id: string }>()
  // eslint-disable-next-line
  const { data, isFetching, refetch, fulfilledTimeStamp } =
    useGetCitizenByIdQuery(parseInt(id), {
      refetchOnMountOrArgChange: true,
      pollingInterval: 15000,
    })

  console.log(data)

  const [favorite, favoriteResult] = useLazyFavoriteCitizenQuery()
  const { t } = useTranslation()
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const editVolumeDrawer = useDisclosure()
  const settingsModal = useDisclosure()
  const noteDrawer = useDisclosure()

  const toast = useToast()

  const [isFavorite, setFavorite] = useState<boolean>(false)
  const devices = useGetAllDevicesQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const districts = useGetAllDistrictsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const [assignDevice] = useAssignDeviceMutation()

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  useEffect(() => {
    if (data) {
      setFavorite(data.favorite)
    }

    if (favoriteResult.data) {
      setFavorite(favoriteResult.data.favorite)
    }
  }, [data, favoriteResult])

  let filteredDevices =
    devices.data?.devices
      .filter(device => !device.citizenId)
      .map(filteredDevice => ({
        value: filteredDevice.id,
        name: filteredDevice.imei,
      })) || []

  if (data?.deviceId) {
    // DeviceId with the value -1 in api call will unassign the device
    filteredDevices.push({ value: -1, name: t('devices.table.unassign') })
  }

  const filteredDistricts =
    districts.data?.districts
      .filter(d => d.id !== data?.districtId)
      .map(d => ({
        value: d.id,
        name: d.name,
      })) || []

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      imei: string
      name: string
    },
    {
      id: number
    }
  >()

  const renderDevice = () => {
    return (
      <>
        <Stack direction="row" mt="30px !important" spacing="40px">
          {data?.deviceId && (
            <Wrap spacing="30px">
              <Box>
                <CText.SectionHeader mb="7px">
                  {t('citizen.status')}
                </CText.SectionHeader>

                <CBadge pillBoxIn={data?.mioStatus?.box} />
              </Box>
              <Box>
                <CText.SectionHeader mb="7px">
                  {t('citizen.battery')}
                </CText.SectionHeader>
                <CText.PrimaryText>
                  {data?.mioStatus.battery + '%'}
                </CText.PrimaryText>
              </Box>
              {/* <Box>
                <CText.SectionHeader mb="7px">Volume</CText.SectionHeader>
                <HStack spacing="4px">
                  <CText.PrimaryText>
                    {data?.mioStatus.configuration.buzzerVolume}{' '}
                  </CText.PrimaryText>
                  <CText.PrimaryText>
                    <Box
                      _hover={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      change
                    </Box>
                  </CText.PrimaryText>
                </HStack>
              </Box> */}
              <Box>
                <CText.SectionHeader mb="7px">
                  {t('citizen.imei')}
                </CText.SectionHeader>
                <CText.PrimaryText>
                  {formattedImei(data?.deviceImei)}
                </CText.PrimaryText>
              </Box>
              <Box>
                <CText.SectionHeader mb="7px">
                  {t('citizen.last-updated')}
                </CText.SectionHeader>
                <CText.PrimaryText>
                  {data?.mioStatus?.lastUpdate
                    ? moment(data?.mioStatus.lastUpdate).format(
                        'DD MMM YYYY, hh:mm:ss'
                      )
                    : '-'}
                  {/* <div>ehej</div>
                  {data?.mioStatus?.lastUpdate
                    ? new Date(data?.mioStatus?.lastUpdate)
                        .toLocaleString()
                        .replaceAll('.', ':')
                    : '-'} */}
                </CText.PrimaryText>
              </Box>

              <Box>
                <CText.SectionHeader mb="7px">
                  {t('citizen.lying-flat')}
                </CText.SectionHeader>
                <CText.PrimaryText>
                  {data.mioStatus.isFlat ? (
                    <Badge status="green">
                      {t('citizen.yes').toUpperCase()}
                    </Badge>
                  ) : (
                    <Badge status="red">{t('citizen.no').toUpperCase()}</Badge>
                  )}
                </CText.PrimaryText>
              </Box>
            </Wrap>
          )}
        </Stack>
        <VStack
          w="100%"
          my={{ base: '20px !important', lg: '30px !important' }}
        >
          <PillBox
            status={data?.mioStatus.rooms}
            pillInRooms={data?.mioStatus.pillInRooms}
          />
        </VStack>
        {data?.mioStatus?.configuration?.rooms && (
          <Box>
            <Stack mb="15px !important" spacing="4px">
              <CText.SectionHeaderLg>
                {t('citizen.time-setting')}
              </CText.SectionHeaderLg>
              <CText.PrimaryText>
                {t('citizen.time-setting-underTitle')}
              </CText.PrimaryText>
            </Stack>
            <HStack alignItems="flex-start">
              <DeviceInfo
                onClick={isAdminRoles ? onOpen : () => {}}
                configuration={data.mioStatus.configuration.rooms}
                volume={data.mioStatus.configuration.buzzerVolume}
              />
              <Box
                boxShadow="base"
                borderRadius="16px"
                p={{ base: '22px', md: '26px' }}
                bg="#fff"
                h="full"
                onClick={editVolumeDrawer.onOpen}
              >
                <CText.PrimaryText>Volume</CText.PrimaryText>
                <CText.SectionHeaderLg>
                  {data.mioStatus.configuration.buzzerVolume} / 6
                </CText.SectionHeaderLg>
                <CButton
                  btnType="regular"
                  w={{ base: 'full', md: 'auto' }}
                  mt="14px"
                >
                  Edit
                </CButton>
              </Box>
            </HStack>
          </Box>
        )}
        {data && (
          <>
            <Stack direction="row" mt="30px !important" alignItems="center">
              <CText.SectionHeaderLg mr="4px">
                {t('citizen.notes')}
              </CText.SectionHeaderLg>
              {isAdminRoles && (
                <IconButton
                  tinted
                  tooltip={t('citizen.edit-note')}
                  aria-label="Rediger note"
                  icon={<MdEdit size="18px" />}
                  onClick={noteDrawer.onOpen}
                />
              )}
            </Stack>

            {data.note === '' ? (
              <CText.PrimaryText w="400px">
                {t('citizen.no-note')}
              </CText.PrimaryText>
            ) : (
              <CText.PrimaryText w="400px">{data.note}</CText.PrimaryText>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <VStack
        alignItems="flex-start"
        p={{ base: '16px', md: '30px', lg: '40px' }}
      >
        <Box display={{ base: 'none', lg: 'block' }} mb="10px">
          <CLink
            title={t('global.back-button')}
            onClick={() => history.goBack()}
          />
        </Box>
        <PageHeader
          title={data && fullName(data.firstName, data.lastName)}
          maxW="200px"
          underTitle={data?.districtName}
          sampleText="Fornavn Efternavn"
        >
          <>
            {isAdminRoles && (
              <CButton
                disabled={isFetching}
                leftIcon={<IoMdSettings size="18px" />}
                btnType="tinted"
                onClick={settingsModal.onOpen}
              >
                {t('citizen.edit')}
              </CButton>
            )}
            <CButton
              disabled={isFetching}
              btnType="tinted"
              onClick={() =>
                history.push(history.location.pathname + '/timeline')
              }
            >
              Timeline
            </CButton>
            <IconButton
              disabled={isFetching || favoriteResult.isFetching}
              tooltip={isFavorite ? 'Unfavourite' : 'Favorite'}
              aria-label="Favourite"
              m="0"
              tinted
              onClick={() => {
                favorite(parseInt(id))
              }}
              icon={
                isFavorite ? (
                  <IoIosStar size="18px" />
                ) : (
                  <IoIosStarOutline size="18px" />
                )
              }
            />
          </>
        </PageHeader>
        <CitizenInfo address={data?.address} phone={data?.phoneNumber} />
        {data && data?.mioStatus?.isAlive === false && (
          <Box>
            <VStack
              border="1px solid #ccc"
              rounded="14px"
              p="10px"
              alignItems="flex-start"
              w="460px"
              mt="30px !important"
            >
              <IoIosWarning size="18px" color="F8C925" />
              <CText.SectionHeader mb="7px">
                {t('citizens.device-no-communication')}
              </CText.SectionHeader>
            </VStack>
          </Box>
        )}
        {data?.deviceId && renderDevice()}
        {!isFetching && !data?.deviceId && (
          <PillBoxPlaceholder
            children={
              <AssignEntitySelect
                placeholder={
                  data?.deviceImei
                    ? data?.deviceImei
                    : t('devices.table.not-assigned')
                }
                options={filteredDevices}
                confirmChoice={async option => {
                  return await openAlertAndAwaitConfirmation(
                    {
                      imei: option.name,
                      name: fullName(data!.firstName, data!.lastName),
                    },
                    {
                      id: option.value,
                    }
                  )
                }}
              />
            }
          />
        )}
      </VStack>
      {data?.mioStatus?.configuration && (
        <EditTimeDrawer
          configuration={data.mioStatus.configuration.rooms}
          volume={data.mioStatus.configuration.buzzerVolume}
          isOpen={isOpen}
          onClose={onClose}
          id={parseInt(id)}
          refetch={refetch}
        />
      )}
      {data?.mioStatus?.configuration && (
        <EditVolumeDrawer
          volume={data.mioStatus.configuration.buzzerVolume}
          isOpen={editVolumeDrawer.isOpen}
          onClose={editVolumeDrawer.onClose}
          id={parseInt(id)}
          configuration={data.mioStatus.configuration.rooms}
          refetch={refetch}
        />
      )}

      <CitizenNoteDrawer
        isOpen={noteDrawer.isOpen}
        onOpen={noteDrawer.onOpen}
        onClose={noteDrawer.onClose}
        refetch={refetch}
        note={data?.note}
        citizenId={parseInt(id)}
      />
      <CitizenSettingsModal
        isOpen={settingsModal.isOpen}
        onClose={settingsModal.onClose}
        onOpen={settingsModal.onOpen}
        refetch={() => {
          refetch()
          devices.refetch()
        }}
        firstName={data?.firstName}
        lastName={data?.lastName}
        address={data?.address}
        phoneNumber={data?.phoneNumber}
        district={data?.districtName}
        districtId={data?.districtId}
        deviceImei={data?.deviceImei}
        deviceId={data?.deviceId}
        devices={filteredDevices}
        districts={filteredDistricts}
        id={parseInt(id)}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={elm =>
          t('citizen.assign-alert.description', {
            imei: elm?.imei,
            name: elm?.name,
          })
        }
        cancelButtonText={t('citizen.assign-alert.cancel-button')}
        submitButtonText={t('citizen.assign-alert.assign-button')}
        onClick={async payload => {
          await assignDevice({
            deviceId: payload?.id as number,
            citizenId: parseInt(id),
          })
            .unwrap()
            .then(() => {
              refetch()
              devices.refetch()
              toast({
                title: t('citizen.assign-toast.title'),
                description: t('citizen.assign-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...alertProps}
      />
    </>
  )
}

export default CitizenScreen
