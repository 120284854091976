import { Stack, HStack, Skeleton, Wrap } from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  CText,
  Tab,
  CitizenBox,
  IconButton,
} from '../../../../components/index'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  mioRoomStatus,
  useGetFavoriteCitizensQuery,
  useGetLastSeenCitizensQuery,
  useGetUserByIdQuery,
} from '../../../../redux/services/mio-api'
import { firstCharToUpper, fullName } from '../../../../utils/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { IoIosStar, IoIosStarOutline } from 'react-icons/io'

interface DashboardHomeScreenProps {}

const sampleData: {
  name: string
  address: string
  phone: string
  status: mioRoomStatus[]
}[] = [
  {
    name: 'Fornavn Efternavn',
    address: 'Adresse navn 1',
    phone: '71827594',
    status: ['taken', 'taken', 'taken', 'taken'],
  },
  {
    name: 'Fornavn Efternavn',
    address: 'Adresse navn 1',
    phone: '71827594',
    status: ['taken', 'taken', 'taken', 'taken'],
  },
  {
    name: 'Fornavn Efternavn',
    address: 'Adresse navn 1',
    phone: '71827594',
    status: ['taken', 'taken', 'taken', 'taken'],
  },
]

const DashboardHomeScreen: React.FC<DashboardHomeScreenProps> = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState<'favorites' | 'last-visited'>('favorites')
  const lastSeenCitizens = useGetLastSeenCitizensQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const favoriteCitizens = useGetFavoriteCitizensQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const session = useSelector((state: RootState) => state.session)

  const { data, isFetching } = useGetUserByIdQuery(session.id)

  const [favourite, setFavourite] = useState(false)

  const history = useHistory()

  const renderSkeletons = () => {
    return sampleData.map((item, index) => (
      <Skeleton borderRadius="20px" w={{ base: '100%', lg: '380px' }}>
        <CitizenBox
          key={index}
          name={item.name}
          address={item.address}
          phone={item.phone}
          status={item.status}
        />
      </Skeleton>
    ))
  }

  return (
    <>
      <Stack p={{ base: '16px', md: '30px', lg: '40px' }}>
        <CText.Header
          mb="6px"
          isLoading={!data}
          sampleText="Welcome, username"
        >{`${t('home.hello')}, ${firstCharToUpper(
          data?.firstName || ''
        )}`}</CText.Header>

        {!isFetching && (
          <>
            <HStack mb="10px !important">
              <Tab
                selected={page === 'favorites'}
                onClick={() => setPage('favorites')}
              >
                {t('home.favourites')}
              </Tab>
              <Tab
                selected={page === 'last-visited'}
                onClick={() => setPage('last-visited')}
              >
                {t('home.last-visited')}
              </Tab>
            </HStack>
            {page === 'favorites' && (
              <>
                {favoriteCitizens.data?.citizens.length === 0 && (
                  <span>
                    <CText.PrimaryTextMd display="inline">
                      {t('home.no-favourites-1')}
                    </CText.PrimaryTextMd>
                    <span>
                      <IconButton
                        aria-label="Favourite"
                        m="0"
                        mx="10px"
                        tinted
                        onClick={() => {
                          setFavourite(!favourite)
                        }}
                        icon={
                          favourite ? (
                            <IoIosStar size="18px" />
                          ) : (
                            <IoIosStarOutline size="18px" />
                          )
                        }
                      />
                    </span>

                    <CText.PrimaryTextMd display="inline">
                      {t('home.no-favourites-2')}
                    </CText.PrimaryTextMd>
                  </span>
                )}

                <Wrap spacing="10px">
                  {favoriteCitizens.data?.citizens.map((item, index) => (
                    <CitizenBox
                      key={index}
                      name={fullName(item.firstName, item.lastName)}
                      address={item.address}
                      phone={item.phoneNumber}
                      status={item?.mioStatus?.rooms?.map(
                        r => r.status as mioRoomStatus
                      )}
                      onClick={() =>
                        history.push('/dashboard/citizens/' + item.id)
                      }
                    />
                  ))}
                  {favoriteCitizens.isLoading && renderSkeletons()}
                </Wrap>
              </>
            )}
            {page === 'last-visited' && (
              <>
                {lastSeenCitizens.data?.citizens.length === 0 && (
                  <CText.PrimaryTextMd display="inline">
                    {t('home.no-last-seen')}
                  </CText.PrimaryTextMd>
                )}
                <Wrap spacing="10px">
                  {lastSeenCitizens.data?.citizens.map((item, index) => (
                    <CitizenBox
                      key={index}
                      name={fullName(item.firstName, item.lastName)}
                      address={item.address}
                      phone={item.phoneNumber}
                      status={item?.mioStatus?.rooms?.map(
                        r => r.status as mioRoomStatus
                      )}
                      onClick={() =>
                        history.push('/dashboard/citizens/' + item.id)
                      }
                    />
                  ))}
                  {lastSeenCitizens.isLoading && renderSkeletons()}
                </Wrap>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  )
}

export default DashboardHomeScreen
