import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../../components/FormInput'
import * as yup from 'yup'
import { FormSelect, SettingsDrawer } from '../../../../../components/index'
import {
  useCreateCitizenMutation,
  useGetAllDevicesQuery,
} from '../../../../../redux/services/mio-api'

interface AddCitizenModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  districtId: string
}

type AddCitizenModalFormData = {
  firstName: string
  lastName: string
  address: string
  phoneNumber: string
  deviceId?: number
}

const AddCitizenModal: React.FC<AddCitizenModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  districtId,
}) => {
  const { t } = useTranslation()
  const [addCitizen, { isLoading }] = useCreateCitizenMutation()
  const toast = useToast()

  const devices = useGetAllDevicesQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const filteredDevices =
    devices.data?.devices
      .filter(d => !d.citizenId)
      .map(fd => ({
        value: fd.id,
        name: fd.imei,
      })) || []

  const schema: yup.SchemaOf<AddCitizenModalFormData> = yup.object({
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    address: yup.string().required(t('validation.required')),
    phoneNumber: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits')),
    deviceId: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddCitizenModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<AddCitizenModalFormData> = data => {
    addCitizen({
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      phoneNumber: data.phoneNumber,
      districtId: parseInt(districtId),
      deviceId: data.deviceId,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('citizens.modal.toast.title'),
          description: t('citizens.modal.toast.description'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        })
        onClose()
        refetch()
        devices.refetch()
      })
  }

  useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('citizens.modal.title')}
      underTitle={t('citizens.modal.under-title')}
      bodyRender={() => (
        <VStack>
          <Stack width="100%">
            <FormInput
              error={errors.firstName}
              label={t('citizens.modal.firstName')}
              placeholder={t('citizens.modal.firstName')}
              registeredForm={register('firstName')}
            />
            <FormInput
              error={errors.lastName}
              label={t('citizens.modal.lastName')}
              placeholder={t('citizens.modal.lastName')}
              registeredForm={register('lastName')}
            />
            <FormInput
              error={errors.address}
              label={t('citizens.modal.address')}
              placeholder={t('citizens.modal.address')}
              registeredForm={register('address')}
            />
            <FormInput
              error={errors.phoneNumber}
              label={t('citizens.modal.phone')}
              inputMode="tel"
              placeholder={t('citizens.modal.phone')}
              registeredForm={register('phoneNumber')}
            />
            <FormSelect
              error={errors.deviceId}
              label={t('citizens.modal.device') + ' ' + t('global.optional')}
              placeholder={t('citizens.modal.device')}
              registeredForm={register('deviceId')}
              options={filteredDevices}
            />
          </Stack>
        </VStack>
      )}
      cancelButtonTitle={t('citizens.modal.cancel-button')}
      submitButtonTitle={t('citizens.modal.add-new-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default AddCitizenModal
