import React from 'react'
import { HStack, Box, Stack, Flex, FlexProps } from '@chakra-ui/react'
import CButton from '../../../../../components/CButton'
import { useTranslation } from 'react-i18next'
import { CText } from '../../../../../components/index'
import { MioRoomConfig } from '../../../../../redux/services/mio-api'
import { formatTimeString } from '../../../../../utils/utils'
import useRoleCheck from '../../../../../utils/useRoleCheck'
import { Role } from '../../../../../types/domainTypes'

interface DeviceInfoProps {
  configuration: MioRoomConfig[]
  volume: number
}

const DeviceInfo: React.FC<DeviceInfoProps & FlexProps> = props => {
  const { configuration, volume, ...restProps } = props

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  const { t } = useTranslation()
  const renderTime = () => {
    return configuration.map(item => {
      return (
        <div style={{ marginRight: '6px' }}>
          <CText.SectionHeaderLg>
            {formatTimeString(item.hour, item.min)}
          </CText.SectionHeaderLg>
        </div>
      )
    })
  }

  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      data-testid="DeviceInfo"
      alignItems={{ base: 'stretch', md: 'center' }}
      width={{ base: '100%', md: '410px' }}
      borderRadius="16px"
      bg="#fff"
      p={{ base: '22px', md: '26px' }}
      boxShadow="base"
      overflow="hidden"
      justifyContent="space-between"
      {...restProps}
    >
      <Stack direction="column" spacing="10px">
        <Box>
          <CText.PrimaryText>Rooms</CText.PrimaryText>
          <HStack w="100%">{renderTime()}</HStack>
        </Box>
        <Stack direction="row" spacing="30px">
          <Box>
            <CText.PrimaryText>{'Alarm'}</CText.PrimaryText>
            <CText.SectionHeaderLg>
              {configuration[0].alarm + ' min'}
            </CText.SectionHeaderLg>
          </Box>
        </Stack>
      </Stack>

      {isAdminRoles && (
        <Box mt={{ base: '16px', md: '0px' }}>
          <CButton btnType="regular" w={{ base: 'full', md: 'auto' }}>
            {t('citizen.edit')}
          </CButton>
        </Box>
      )}
    </Flex>
  )
}

export default DeviceInfo
