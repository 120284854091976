import { Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../../components/FormInput'
import * as yup from 'yup'
import {
  useDeleteDistrictMutation,
  useUpdateDistrictMutation,
} from '../../../../../redux/services/mio-api'
import {
  ActionContainer,
  CButton,
  SettingsDrawer,
} from '../../../../../components/index'
import AsyncAlert from '../../../../../containers/AsyncAlert'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import { useHistory } from 'react-router-dom'

interface DistrictsAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  name: string
  phoneNumber?: string
  emptyDistrict: boolean
  id: number
}

type DistrictsAddModalFormData = {
  name: string
  phoneNumber?: string
}

const DistrictsAddModal: React.FC<DistrictsAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  name,
  phoneNumber,
  emptyDistrict,
  id,
}) => {
  const { t } = useTranslation()
  const [updateDistrict, { isLoading }] = useUpdateDistrictMutation()
  const [deleteDistrict] = useDeleteDistrictMutation()

  const toast = useToast()
  const history = useHistory()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      districtName: string
    },
    {}
  >()

  const schema: yup.SchemaOf<DistrictsAddModalFormData> = yup.object({
    name: yup.string().required(t('validation.required')),
    phoneNumber: yup
      .string()
      .matches(/^\d{8}$/, {
        message: t('validation.must-be-valid-phone'),
        excludeEmptyString: true,
      })
      .transform(value => (value === '' ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<DistrictsAddModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<DistrictsAddModalFormData> = data => {
    updateDistrict({
      id: id,
      name: data.name,
      phoneNumber: data.phoneNumber,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('district.modal.toast.title'),
          description: t('district.modal.toast.description'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        })
        onClose()
        refetch()
      })
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      if (name) {
        setValue('name', name)
      }

      if (phoneNumber) {
        setValue('phoneNumber', phoneNumber)
      }
    }
  }, [isOpen])

  return (
    <>
      <SettingsDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t('district.modal.title')}
        underTitle={t('district.modal.under-title')}
        bodyRender={() => (
          <>
            <Stack width="100%" spacing="20px" mb="20px">
              <FormInput
                error={errors.name}
                label={t('district.modal.name')}
                placeholder={t('district.modal.name')}
                registeredForm={register('name')}
              />
              <FormInput
                error={errors.phoneNumber}
                label={t('district.modal.phone') + ' ' + t('global.optional')}
                placeholder={t('district.modal.phone')}
                inputMode="tel"
                registeredForm={register('phoneNumber')}
                description={t('district.modal.phone-description')}
              />
            </Stack>
            <ActionContainer
              title={t('district.modal.delete-title')}
              description={t('district.modal.delete-description')}
            >
              <CButton
                btnType="warning"
                disabled={!emptyDistrict}
                onClick={async () => {
                  await openAlertAndAwaitConfirmation({
                    districtName: name,
                  })
                }}
              >
                {t('district.modal.delete-button')}
              </CButton>
            </ActionContainer>
          </>
        )}
        cancelButtonTitle={t('district.modal.cancel-button')}
        submitButtonTitle={t('district.modal.save-button')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitLoading={isLoading}
        formOnSubmit={handleSubmit(onSubmit)}
      />
      <AsyncAlert
        title="Are you sure?"
        isWarning
        bodyText={data => `${t('district.alert.description', { name: name })}`}
        cancelButtonText={t('district.alert.cancel-button')}
        submitButtonText={t('district.alert.delete-button')}
        onClick={async () => {
          deleteDistrict({
            id: id,
          })
            .unwrap()
            .then(() => {
              toast({
                title: t('district.delete-toast.title'),
                description: t('district.delete-toast.description'),
                duration: 5000,
                isClosable: true,
                status: 'success',
              })
              onClose()
              history.push('/dashboard/districts')
            })
        }}
        {...alertProps}
      />
    </>
  )
}

export default DistrictsAddModal
