import { useToast } from '@chakra-ui/toast'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { bindHooksToRTKErrorLogger } from '../redux/rtkQueryErrorLogger'

const RTKErrorLoggerHooksProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const toast = useToast()

  bindHooksToRTKErrorLogger(toast, t)

  return <>{children}</>
}

export default RTKErrorLoggerHooksProvider
