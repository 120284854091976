import { Button } from '@chakra-ui/button'
import React from 'react'
import i18n from '../i18n'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

interface LanguagePickerProps {}

const LanguagePicker: React.FC<LanguagePickerProps> = props => {
  const { t } = useTranslation()

  const renderLanguage = () => {
    switch (i18n.language) {
      case 'en':
        return 'English'
      case 'da':
        return 'Dansk'
      default:
        return 'Dansk'
    }
  }

  return (
    <Menu>
      <MenuButton
        fontSize={{ base: '14px', lg: '13px' }}
        h={{ base: '46px', lg: '40px' }}
        borderRadius={12}
        as={Button}
        bg="white !important"
        _
        shadow="base"
        rightIcon={<FaChevronDown size={10} />}
      >
        {/* {t('language')} */}
        {renderLanguage()}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => i18n.changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => i18n.changeLanguage('da')}>Dansk</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default LanguagePicker
