import { Stack, Text, Link } from '@chakra-ui/react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import FormInput from '../../../../components/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormCheckbox from '../../../../components/FormCheckbox'
import Step from '../../../../components/Step'
import { CButton, CText } from '../../../../components/index'
import { CompleteSignupFormData } from '../SignupScreen'

interface OrganisationFormData {
  organisation: string
  cvr: string
  termsOfUse: boolean
}

interface OrganisationFormProps {
  onSubmit: SubmitHandler<OrganisationFormData>
  data?: CompleteSignupFormData
}

const OrganisationForm: React.FC<OrganisationFormProps> = ({
  onSubmit,
  data,
}) => {
  const { t } = useTranslation()

  const schema: yup.SchemaOf<OrganisationFormData> = yup.object({
    organisation: yup.string().required(t('validation.required')),
    cvr: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits')),
    termsOfUse: yup
      .boolean()
      .isTrue(t('validation.checkbox-required'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<OrganisationFormData>({
    resolver: yupResolver(schema),
  })

  if (data?.organisation) {
    setValue('organisation', data.organisation)
  }

  if (data?.cvr) {
    setValue('cvr', data.cvr)
  }

  if (data?.termsOfUse) {
    setValue('termsOfUse', data.termsOfUse)
  }

  return (
    <Stack
      w={{ base: '90%', md: '350px' }}
      flexDirection="column"
      alignItems="flex-start"
      textAlign="left"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step step={1} of={3} />
        <CText.HeaderLg mb="40px">
          {t('signup.organisation-title')}
        </CText.HeaderLg>

        <Stack pb="6">
          <FormInput
            label={t('signup.name-of-organisation')}
            placeholder={t('signup.name-of-organisation')}
            error={errors.organisation}
            registeredForm={register('organisation')}
            id="organisation"
            tinted={true}
          />
          <FormInput
            label={t('signup.cvr')}
            placeholder={t('signup.cvr')}
            inputMode="numeric"
            error={errors.cvr}
            registeredForm={register('cvr')}
            id="cvr"
            tinted={true}
          />
          <FormCheckbox
            label={
              <Text fontSize="14px" textAlign="left" color="mio-headline">
                <Trans i18nKey="signup.agree-to-terms">
                  I have read and agree to the{' '}
                  <Link
                    fontWeight="bold"
                    href="/legal/terms-of-use"
                    target="_blank"
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    fontWeight="bold"
                    href="/legal/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </Trans>
              </Text>
            }
            error={errors.termsOfUse}
            registeredForm={register('termsOfUse')}
            id="termsOfUse"
          />
        </Stack>

        <CButton btnType="mio" type="submit" w="full">
          {t('signup.continue-button')}
        </CButton>
      </form>
    </Stack>
  )
}

export default OrganisationForm
