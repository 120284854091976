import { Tbody, Tr, Td, Skeleton } from '@chakra-ui/react'
import { TablePropGetter, TableProps, Row } from 'react-table'

interface TableBodyProps {
  getTableBodyProps: (
    propGetter?: TablePropGetter<{}> | undefined
  ) => TableProps
  rows: Row<{}>[]
  prepareRow: (row: Row<{}>) => void
  isLoading?: boolean
}

const TableBody: React.FC<TableBodyProps> = ({
  getTableBodyProps,
  rows,
  prepareRow,
  isLoading,
}) => {
  return (
    <Tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row)
        return (
          <Tr {...row.getRowProps()} mr="10px">
            {row.cells.map(cell => (
              <Td fontSize="14px" {...cell.getCellProps()} pl="0px" pr="36px">
                <Skeleton
                  isLoaded={!isLoading}
                  borderRadius="6px"
                  m={-2}
                  p={1.5}
                >
                  {cell.render('Cell')}
                </Skeleton>
              </Td>
            ))}
          </Tr>
        )
      })}
    </Tbody>
  )
}

export default TableBody
