import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '1025px',
  xl: '1280px',
  '2xl': '1500px',
})

export default extendTheme({
  colors: {
    mio: '#3B9ABC',
    'mio-light': '#51ADCE',
    'mio-headline': '#3d494d',
    'mio-primaryText': '#738084',
    'mio-secondaryText': '#939798',
    'mio-gray': '#e3e3e3',
    'mio-background': '#f2f5f6',
    'mio-white': '#fff',
    'mio-greenLight': '#59c385',
    'mio-greenDark': '#4d946b',
    'mio-greenTint': '#D4EBE0',
    'mio-red': '#d95959',
    "mio-redDark": "#C74E4E",
    'mio-redTint': '#EED6D7',
    'mio-darkBackground': '#E9F0F2',
    "mio-yellowTint": "#F2E9B5",
    'mio-yellow': "#B29E01",
    "mio-yellowLight": "#F5D62E"
  },
  styles: {
    global: {
      body: {
        bg: 'gray.300',
      },
    },
  },
  breakpoints,
  config: {
    cssVarPrefix: 'chakra',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  // components: {
  //   Button: {
  //     defaultProps: {
  //       colorScheme: 'blue',
  //     },
  //   },
  // },
  components: {
    // Badge: {
    //   baseStyle: {
    //     padding: '2px',
    //   },
    // },
  },
})
