import { Heading } from '@chakra-ui/layout'
import React from 'react'

const LegalSubHeading: React.FC = ({ children }) => {
  return (
    <Heading as="h2" mb="2" mt="4" fontSize="16px">
      {children}
    </Heading>
  )
}

export default LegalSubHeading
