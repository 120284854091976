import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router'
import NotFoundScreen from '../../components/NotFoundScreen'
import CitizensScreen from './screens/Citizens/CitizensScreen'
import CitizenScreen from './screens/Citizens/screens/CitizenScreen'
import DevicesScreen from './screens/Devices/DevicesScreen'
import DistrictsScreen from './screens/Districts/DistrictsScreen'
import DistrictScreen from './screens/Districts/screens/DistrictScreen'
import EmployeesScreen from './screens/Employees/EmployeesScreen'
import DashboardHomeScreen from './screens/Home/DashboardHomeScreen'
import DeviceScreen from './screens/Devices/screens/DeviceScreen'
import EmployeeScreen from './screens/Employees/screens/EmployeeScreen'
import AccountScreen from './screens/Account/AccountScreen'
import SupportScreen from './screens/Support/SupportScreen'
import { Redirect } from 'react-router-dom'
import useRoleCheck from '../../utils/useRoleCheck'
import { Role, UserType } from '../../types/domainTypes'
import StatisticsScreen from './screens/Citizens/screens/StatisticsScreen'
import OnboardingScreen from './screens/Onboarding/OnboardingScreen'
import useUserTypeCheck from '../../utils/useUserTypeCheck'
import TimelineScreen from './screens/Citizens/screens/TimelineScreen'

interface DashboardRouterProps {}

interface ProtectedRouteProps {
  children: JSX.Element
  predicate: boolean
  path: string
  to: string
}

function ProtectedRoute({
  children,
  predicate,
  to,
  ...rest
}: ProtectedRouteProps) {
  return (
    <Route
      {...rest}
      render={props =>
        predicate ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: to,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const DashboardRouter: React.FC<DashboardRouterProps> = () => {
  const { path } = useRouteMatch()

  // Scroll to top if path changes
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const role = useRoleCheck() as Role
  const userType = useUserTypeCheck() as UserType

  console.log(userType)

  const isSuperUser = role === 'superUser'
  const isEmployeeAdmin = role === 'employeeAdmin'

  return (
    <Switch>
      <Route path={`${path}/citizens/:id/statistics`}>
        <StatisticsScreen />
      </Route>
      <Route path={`${path}/citizens/:id/timeline`}>
        <TimelineScreen />
      </Route>
      <Route path={`${path}/citizens/:id`}>
        <CitizenScreen />
      </Route>
      <Route path={`${path}/citizens`}>
        <CitizensScreen />
      </Route>
      <Route path={`${path}/districts/:id`}>
        <DistrictScreen />
      </Route>
      <Route path={`${path}/districts`}>
        <DistrictsScreen />
      </Route>
      <ProtectedRoute
        path={`${path}/employees/:id`}
        predicate={isSuperUser || isEmployeeAdmin}
        to={`${path}/home`}
      >
        <EmployeeScreen />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/employees`}
        predicate={isSuperUser || isEmployeeAdmin}
        to={`${path}/home`}
      >
        <EmployeesScreen />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/devices/:id`}
        predicate={isSuperUser || isEmployeeAdmin}
        to={`${path}/home`}
      >
        <DeviceScreen />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/devices`}
        predicate={isSuperUser || isEmployeeAdmin}
        to={`${path}/home`}
      >
        <DevicesScreen />
      </ProtectedRoute>
      <ProtectedRoute
        path={`${path}/get-started`}
        predicate={userType === 'Organisation'}
        to={`${path}/home`}
      >
        <OnboardingScreen />
      </ProtectedRoute>
      <Route path={`${path}/home`}>
        <DashboardHomeScreen />
      </Route>
      <Route path={`${path}/account`}>
        <AccountScreen />
      </Route>
      <Route path={`${path}/support`}>
        <SupportScreen />
      </Route>
      <Route path="*">
        <NotFoundScreen />
      </Route>
    </Switch>
  )
}

export default DashboardRouter
