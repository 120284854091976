import React from 'react'
import { Stack } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLoginMutation } from '../../../../redux/services/mio-api'
import FormInput from '../../../../components/FormInput'
import { useDispatch } from 'react-redux'
import { startSession } from '../../../../redux/slices/sessionSlice'
import { CButton, CText } from '../../../../components/index'
import { useEffect } from 'react'

type LoginFormData = {
  email: string
  password: string
}

interface LoginFormProps {
  email?: string
}

const LoginForm: React.FC<LoginFormProps> = ({ email }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useDispatch()

  const schema: yup.SchemaOf<LoginFormData> = yup.object({
    email: yup
      .string()
      .email(t('validation.must-be-valid-email'))
      .required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email,
    },
  })

  useEffect(() => {
    if (email) {
      setValue('email', email)
    }
  }, [email])

  const onSubmit: SubmitHandler<LoginFormData> = data => {
    login(data)
      .unwrap()
      .then(res => {
        // Future support for multiple user roles
        const role = res.roles[0]

        window.localStorage.setItem('token', res.accessToken)
        window.localStorage.setItem('refreshToken', res.refreshToken)
        window.localStorage.setItem('tokenExpires', res.expires.toString())
        window.localStorage.setItem('userId', res.id.toString())
        window.localStorage.setItem('userRole', role)
        window.localStorage.setItem('userType', res.userType)
        dispatch(
          startSession({
            token: res.accessToken,
            refreshToken: res.refreshToken,
            tokenExpires: res.expires.toString(),
            id: res.id,
            role: role,
            userType: res.userType,
          })
        )
        history.push('/dashboard/get-started')
      })
  }

  return (
    <Stack textAlign="left">
      <CText.HeaderLg mb="40px" as="h1">
        {t('login.title')}
      </CText.HeaderLg>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label={t('login.email')}
          placeholder={t('login.email')}
          error={errors.email}
          registeredForm={register('email')}
          id="email"
          inputMode="email"
          tinted={true}
        />
        <FormInput
          label={t('login.password')}
          placeholder={t('login.password')}
          error={errors.password}
          registeredForm={register('password')}
          id="password"
          tinted={true}
          inputType="password"
        />
        <CButton
          btnType="mio"
          mt="4"
          type="submit"
          isLoading={isLoading}
          width="full"
        >
          {t('login.sign-in-button')}
        </CButton>
      </form>
    </Stack>
  )
}

export default LoginForm
