import {
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormControlProps,
} from '@chakra-ui/react'
import React from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { CText, CTextarea } from './index'

interface FormInputProps {
  label?: string
  placeholder: string
  error?: FieldError
  registeredForm: UseFormRegisterReturn
  id?: string
  tinted?: boolean
  leftAddon?: string
  rightAddon?: string
  inputType?: string
  description?: string
  inputMode?: 'tel' | 'email' | 'text' | 'numeric'
}

const FormTextArea: React.FC<FormInputProps & FormControlProps> = ({
  label,
  placeholder,
  error,
  registeredForm,
  id,
  tinted,
  leftAddon,
  rightAddon,
  inputType,
  description,
  inputMode,
}) => {
  return (
    <FormControl isInvalid={!!error} mt="3">
      {label && <CText.SectionHeader mb="1">{label}</CText.SectionHeader>}
      <InputGroup>
        {leftAddon && (
          <InputLeftAddon
            h={{ base: '54px', lg: '40px' }}
            borderLeftRadius={{ base: 14, lg: 10 }}
            children={leftAddon}
          />
        )}
        <CTextarea
          inputMode={inputMode}
          borderLeftRadius={{
            base: leftAddon ? '0' : 14,
            lg: leftAddon ? '0' : 10,
          }}
          h="200px"
          tinted={tinted}
          id={id}
          placeholder={placeholder}
          {...registeredForm}
        />
        {rightAddon && <InputRightAddon children={rightAddon} />}
      </InputGroup>
      <CText.Warning mt="6px">{error && error.message}</CText.Warning>
      {description && (
        <CText.PrimaryText mt="0px">{description}</CText.PrimaryText>
      )}
    </FormControl>
  )
}

export default FormTextArea
