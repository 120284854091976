import { Box, Stack, Skeleton, useToast } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  useGetDeviceByIdQuery,
  useGetAllCitizensQuery,
  useAssignDeviceMutation,
  useDeleteDeviceMutation,
  useResetDeviceMutation,
} from '../../../../../redux/services/mio-api'
import { useHistory } from 'react-router-dom'
import {
  CLink,
  CText,
  ActionContainer,
  CButton,
  CBadge,
} from '../../../../../components/index'
import AssignEntitySelect from '../../../../../containers/AssignEntitySelect'
import {
  colorByBatteryStatus,
  formattedImei,
  fullName,
} from '../../../../../utils/utils'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import AsyncAlert from '../../../../../containers/AsyncAlert'

interface DeviceScreenProps {}

const DeviceScreen: React.FC<DeviceScreenProps> = () => {
  const { id } = useParams<{ id: string }>()
  // eslint-disable-next-line
  const { data, isFetching, refetch } = useGetDeviceByIdQuery(parseInt(id))

  const history = useHistory()

  const [assignDevice] = useAssignDeviceMutation()
  const [deleteDevice] = useDeleteDeviceMutation()
  const [resetDevice] = useResetDeviceMutation()

  const toast = useToast()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      citizenName: string
      deviceImei: string
    },
    {
      citizenId: number
      deviceId: number
    }
  >()

  //Reset device
  const {
    openAlertAndAwaitConfirmation: openResetAlert,
    alertProps: resetAlertProps,
  } = useAsyncAlert<
    {
      imei: string | undefined
    },
    any
  >()

  //Delete device
  const {
    openAlertAndAwaitConfirmation: openDeleteAlert,
    alertProps: deleteAlertProps,
  } = useAsyncAlert<
    {
      imei: string | undefined
    },
    any
  >()

  let name
  if (data?.citizenFirstName && data?.citizenLastName) {
    name = fullName(data.citizenFirstName, data.citizenLastName)
  }
  const citizens = useGetAllCitizensQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const filteredCitizens =
    citizens.data?.citizens
      .filter(c => !c.deviceId)
      .map(fc => ({
        value: fc.id,
        name: fullName(fc.firstName, fc.lastName),
      })) || []

  const { t } = useTranslation()

  return (
    <>
      <Box p={{ base: '16px', md: '30px', lg: '40px' }}>
        <Box display={{ base: 'none', lg: 'block' }} mb="20px">
          <CLink
            title={t('global.back-button')}
            onClick={() => history.goBack()}
          />
        </Box>
        <CText.Header
          isLoading={!data?.imei}
          sampleText="#1234123412341234"
          mb="20px"
        >
          {formattedImei(data?.imei)}
        </CText.Header>
        <Stack direction="row" mt="30px !important" spacing="40px">
          <Box>
            <CText.SectionHeader mb="7px">
              {t('device.status')}
            </CText.SectionHeader>
            {data ? (
              <CBadge pillBoxIn={data.box} />
            ) : (
              <Skeleton>
                <CBadge pillBoxIn={true} />
              </Skeleton>
            )}
          </Box>
          <Box>
            <CText.SectionHeader mb="7px">
              {t('device.battery')}
            </CText.SectionHeader>
            {data ? (
              <CText.PrimaryText
                isLoading={!data}
                color={colorByBatteryStatus(data?.battery)}
                sampleText="69%"
              >
                {data?.battery + '%'}
              </CText.PrimaryText>
            ) : (
              <Skeleton>
                <CText.PrimaryText display="inline-block">
                  69%
                </CText.PrimaryText>
              </Skeleton>
            )}
          </Box>
        </Stack>
        <Stack direction="column" display="inline-block">
          <Box mt="30px">
            <CText.SectionHeader mb="14px">
              {t('device.assigned')}
            </CText.SectionHeader>
            <AssignEntitySelect
              disabled={isFetching}
              placeholder={name ? name : t('devices.table.not-assigned')}
              options={
                data?.citizenId && !filteredCitizens.length
                  ? [
                      ...filteredCitizens,
                      { value: -1, name: t('devices.table.unassign') },
                    ]
                  : filteredCitizens
              }
              value={name}
              tinted
              confirmChoice={async option => {
                return await openAlertAndAwaitConfirmation(
                  {
                    citizenName: option.name,
                    //@ts-ignore
                    deviceImei: data?.imei,
                  },
                  {
                    citizenId: option.value,
                    deviceId: data?.id,
                  }
                )
              }}
            ></AssignEntitySelect>
          </Box>
        </Stack>
        <Stack dir="column" spacing="10px" mt="40px">
          <ActionContainer
            dir="row"
            title={t('device.reset.title')}
            description={t('device.reset.description')}
          >
            <CButton
              disabled={isFetching}
              btnType="dark"
              onClick={async () => {
                await openResetAlert({
                  imei: data?.imei,
                })
              }}
            >
              {t('device.reset.button')}
            </CButton>
          </ActionContainer>
          <ActionContainer
            dir="row"
            title={t('device.delete.title')}
            description={t('device.delete.description')}
          >
            <CButton
              disabled={isFetching}
              btnType="warning"
              onClick={async () => {
                await openDeleteAlert({
                  imei: data?.imei,
                })
              }}
            >
              {t('device.delete.button')}
            </CButton>
          </ActionContainer>
        </Stack>
      </Box>
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `${t('devices.alert.assign')} ${formattedImei(data?.deviceImei)} ${t(
            'devices.alert.to'
          )} ${data?.citizenName}`
        }
        cancelButtonText={t('devices.alert.cancel-button')}
        submitButtonText={t('devices.alert.assign-button')}
        onClick={async payload => {
          await assignDevice({
            deviceId: payload?.deviceId as number,
            citizenId: payload?.citizenId as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              citizens.refetch()
              toast({
                title: t('device.assigned-toast.title'),
                description: t('device.assigned-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...alertProps}
      />
      <AsyncAlert
        title={'Are you sure?'}
        bodyText={data =>
          `${t('devices.alert.reset')} ${formattedImei(data?.imei)}`
        }
        cancelButtonText={t('devices.alert.cancel-button')}
        submitButtonText={t('devices.alert.reset-button')}
        onClick={async () => {
          if (data?.id) {
            resetDevice({
              id: data.id,
            })
              .unwrap()
              .then(() => {
                toast({
                  title: t('device.reset-toast.title'),
                  description: t('device.reset-toast.description'),
                  duration: 4000,
                  isClosable: true,
                  status: 'success',
                })
              })
          }
        }}
        {...resetAlertProps}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `${t('devices.alert.delete')} ${formattedImei(data?.imei)}`
        }
        isWarning
        cancelButtonText={t('devices.alert.cancel-button')}
        submitButtonText={t('devices.alert.delete-button')}
        onClick={async () => {
          if (data?.id) {
            deleteDevice({
              id: data.id,
            })
              .unwrap()
              .then(() => {
                toast({
                  title: t('device.delete-toast.title'),
                  description: t('device.delete-toast.description'),
                  duration: 4000,
                  isClosable: true,
                  status: 'success',
                })
                history.push('/dashboard/devices')
              })
          }
        }}
        {...deleteAlertProps}
      />
    </>
  )
}

export default DeviceScreen
