import { Box, HStack } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import { LanguagePicker } from './index'
import logo from '../assets/Logo.svg'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const history = useHistory()

  return (
    <Box position="absolute" width="100%">
      <HStack justifyContent="space-between" padding="20px">
        <Image h="50px" src={logo} />
        <LanguagePicker />
      </HStack>
    </Box>
  )
}

export default Header
