import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsDrawer } from '../../../../../components/index'
import FormInput from '../../../../../components/FormInput'
import * as yup from 'yup'
import FormSelect from '../../../../../components/FormSelect'
import { useCreateDeviceMutation } from '../../../../../redux/services/mio-api'
import { Option } from '../../../../../types/domainTypes'

interface DevicesAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  options: Option[]
}

type DevicesAddModalFormData = {
  imei: string
  citizen?: number
}

const DevicesAddModal: React.FC<DevicesAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  options,
}) => {
  const { t } = useTranslation()
  const [addDevice, { isLoading }] = useCreateDeviceMutation()
  const toast = useToast()

  const regex = /^[0-9]{15}$/

  const schema: yup.SchemaOf<DevicesAddModalFormData> = yup.object({
    imei: yup
      .string()
      .required(t('validation.required'))
      .min(15, t('validation.imei-length'))
      .max(15, t('validation.imei'))
      .matches(regex, t('validation.imei')),
    citizen: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DevicesAddModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<DevicesAddModalFormData> = data => {
    addDevice({
      imei: data.imei,
      citizenId: data.citizen,
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('devices.modal.toast.title'),
          description: t('devices.modal.toast.description'),
          duration: 5000,
          isClosable: true,
          status: 'success',
        })
        onClose()
        refetch()
      })
  }

  useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('devices.modal.title')}
      underTitle={t('devices.modal.under-title')}
      bodyRender={() => (
        <VStack>
          <Stack width="100%" spacing="20px">
            <FormInput
              error={errors.imei}
              label={t('devices.modal.imei')}
              placeholder={t('devices.modal.imei')}
              registeredForm={register('imei')}
              description={t('devices.modal.imei-description')}
              inputMode="numeric"
            />
            <FormSelect
              error={errors.citizen}
              label={t('devices.modal.citizen') + ' ' + t('global.optional')}
              placeholder={t('devices.modal.citizen')}
              registeredForm={register('citizen')}
              options={options}
            />
          </Stack>
        </VStack>
      )}
      cancelButtonTitle={t('devices.modal.cancel-button')}
      submitButtonTitle={t('devices.modal.add-new-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default DevicesAddModal
