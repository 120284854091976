import React from 'react'
import { VStack, Box, StackProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CText } from '../../../../../components/index'

interface PillBoxProps {
  children?: React.ReactChild
}

const PillBoxPlaceholder: React.FC<PillBoxProps & StackProps> = ({
  children,
  ...props
}: PillBoxProps) => {
  const { t } = useTranslation()
  return (
    <VStack
      mt="30px !important"
      alignSelf="flex-start"
      data-testid="PillBoxPlaceholder"
      width={{ base: '100%', md: '80%', lg: '600px' }}
      h={{ base: '110px', md: '110px' }}
      borderRadius={{ base: '26px', md: '26px' }}
      bg="#fff"
      boxShadow="base"
      alignItems="flex-start"
      justifyContent="center"
      pl="20px"
      overflow="hidden"
      {...props}
    >
      <CText.SectionHeader>
        {t('citizen.no-assigned-device')}
      </CText.SectionHeader>
      <Box w={{ base: '70%', lg: '50%' }}>{children}</Box>
    </VStack>
  )
}

export default PillBoxPlaceholder
