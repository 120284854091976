import { Box, Center, VStack } from '@chakra-ui/layout'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../components/FormInput'
import * as yup from 'yup'
import { useResetPasswordMutation } from '../../../redux/services/mio-api'
import { useToast } from '@chakra-ui/toast'
import { useHistory } from 'react-router'
import { CButton, CLink, CText } from '../../../components/index'
import passwordValidator from '../Signup/utils/passwordValidator'
import { useLocation } from 'react-router-dom'

interface ResetPasswordScreenProps {}

interface ResetPasswordFormData {
  password: string
  confirmPassword: string
}

interface locationState {
  password: string
}

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = () => {
  const { t } = useTranslation()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const toast = useToast()
  const history = useHistory()

  const location = useLocation<locationState>()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const email = queryParams.get('email')

  const schema: yup.SchemaOf<ResetPasswordFormData> = yup.object({
    password: passwordValidator(t).required(t('validation.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('validation.password-must-match'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({ resolver: yupResolver(schema) })

  const onSubmit: SubmitHandler<ResetPasswordFormData> = data => {
    resetPassword({
      password: data.password,
      token: token || '',
    })
      .unwrap()
      .then(() => {
        toast({
          title: t('reset-password.toast.title'),
          description: t('reset-password.toast.description'),
          duration: 10000,
          isClosable: true,
          status: 'success',
        })
        history.push('/', { email: email || '' })
      })
  }

  return (
    <Center w="100%" h="100vh">
      <VStack textAlign="left">
        <Box w={{ base: '90%', md: '350px' }}>
          <CText.HeaderLg as="h1" mb="8px">
            {t('reset-password.title')}
          </CText.HeaderLg>
          <CText.PrimaryText mb="40px">
            {t('reset-password.sub-title')}
          </CText.PrimaryText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label={t('reset-password.password')}
              placeholder={t('reset-password.password')}
              error={errors.password}
              registeredForm={register('password')}
              id="password"
              tinted={true}
              inputType="password"
            />
            <FormInput
              label={t('reset-password.confirm-password')}
              placeholder={t('reset-password.confirm-password')}
              error={errors.confirmPassword}
              registeredForm={register('confirmPassword')}
              id="confirmPassword"
              tinted={true}
              inputType="password"
            />
            <CButton
              btnType="mio"
              mt={4}
              w="full"
              colorScheme="blue"
              type="submit"
              isLoading={isLoading}
            >
              {t('reset-password.submit-button')}
            </CButton>
          </form>
        </Box>
        <Box mt="30px !important">
          <CLink title="Back to login" href="/" />
        </Box>
      </VStack>
    </Center>
  )
}

export default ResetPasswordScreen
