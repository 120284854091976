import { Stack, Wrap, Box } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import {
  CText,
  OnboardingStep,
  OnboardingProgress,
} from '../../../../components/index'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  mioRoomStatus,
  useGetAllCitizensQuery,
  useGetAllDevicesQuery,
  useGetAllDistrictsQuery,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
} from '../../../../redux/services/mio-api'
import { firstCharToUpper } from '../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import useRoleCheck from '../../../../utils/useRoleCheck'
import { Role } from '../../../../types/domainTypes'
import { updateIsConfigured } from '../../../../redux/slices/sessionSlice'

interface OnboardingScreenProps {}

const OnboardingScreen: React.FC<OnboardingScreenProps> = () => {
  const { t } = useTranslation()
  const session = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()

  const { data, isFetching } = useGetUserByIdQuery(session.id)

  const [isOrganisationConfigured, setIsOrganisationConfigured] = useState<
    boolean | undefined
  >()
  const [currentProgress, setCurrentProgress] = useState<number>(0)

  // Could make a service in the backend that checks if device is setup but fow we can simply query all data and see if anything is missing
  const citizens = useGetAllCitizensQuery(null)
  const districts = useGetAllDistrictsQuery(null)
  const employees = useGetAllUsersQuery(null)
  const devices = useGetAllDevicesQuery(null)

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  useEffect(() => {
    if (isAdminRoles) {
      const isOrganisationConfigured =
        districts.data?.districts.length !== 0 &&
        devices.data?.devices.length !== 0 &&
        citizens.data?.citizens.length !== 0 &&
        employees.data?.users.length !== 0

      let progress = 0

      if (districts.data?.districts.length !== 0) {
        progress += 1
      }
      if (devices.data?.devices.length !== 0) {
        progress += 1
      }
      if (citizens.data?.citizens.length !== 0) {
        progress += 1
      }
      if (employees.data?.users.length !== 0) {
        progress += 1
      }

      if (
        citizens.isSuccess &&
        devices.isSuccess &&
        districts.isSuccess &&
        employees.isSuccess
      ) {
        setCurrentProgress(progress)
        setIsOrganisationConfigured(isOrganisationConfigured)
        dispatch(updateIsConfigured(isOrganisationConfigured))
      }
    }
  }, [citizens, districts, employees, devices])

  const history = useHistory()

  if (data && data.organisation?.configured) {
    history.push('/dashboard/home')
  }

  return (
    <>
      {data && !data.organisation?.configured && (
        <Stack p={{ base: '16px', md: '30px', lg: '40px' }}>
          <CText.Header
            mb="6px"
            isLoading={!data}
            sampleText="Welcome, username"
          >{`${t('home.hello')}, ${firstCharToUpper(
            data?.firstName || ''
          )}`}</CText.Header>

          {isAdminRoles && (
            <Box mb="10px">
              <CText.PrimaryTextBig w="500px" display="inline">
                {t('home.onboarding.under-title')}
              </CText.PrimaryTextBig>
              <OnboardingProgress
                progress={currentProgress}
                isLoading={
                  districts.isLoading ||
                  devices.isLoading ||
                  citizens.isLoading ||
                  employees.isLoading
                }
              />
              <Wrap
                direction={{ base: 'column', lg: 'row' }}
                spacing="20px"
                mt="30px"
              >
                <OnboardingStep
                  isComplete={districts.data?.districts.length !== 0}
                  title={t('home.onboarding.district.title')}
                  description={t('home.onboarding.district.description')}
                  isLoading={!districts.data}
                  stepNumber={1}
                  onClick={() => history.push('districts')}
                />
                <OnboardingStep
                  isComplete={devices.data?.devices.length !== 0}
                  title={t('home.onboarding.device.title')}
                  description={t('home.onboarding.device.description')}
                  isLoading={!devices.data}
                  stepNumber={2}
                  onClick={() => history.push('devices')}
                />
                <OnboardingStep
                  isComplete={citizens.data?.citizens.length !== 0}
                  title={t('home.onboarding.citizen.title')}
                  description={t('home.onboarding.citizen.description')}
                  isLoading={!citizens.data}
                  stepNumber={3}
                  onClick={() => history.push('citizens')}
                />
                <OnboardingStep
                  isComplete={employees.data?.users.length !== 0}
                  title={t('home.onboarding.employee.title')}
                  description={t('home.onboarding.employee.description')}
                  isLoading={!employees.data}
                  stepNumber={4}
                  onClick={() => history.push('employees')}
                />
              </Wrap>
            </Box>
          )}
        </Stack>
      )}
    </>
  )
}

export default OnboardingScreen
