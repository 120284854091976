import { Box, Flex, HStack, Spinner, Tooltip, VStack } from '@chakra-ui/react'

//@ts-nocheck

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CLink from '../../../../../components/CLink'
import { useTranslation } from 'react-i18next'
import { VscDebugRestart } from 'react-icons/vsc'
import { useHistory, useParams } from 'react-router-dom'

import { CText, PageHeader } from '../../../../../components/index'
import PillBox from '../components/PillBox'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import {
  Config,
  Status,
  useGetCitizenByIdQuery,
  useGetCitizinHistoryMutation,
} from '../../../../../redux/services/mio-api'
import Badge from '../../../../../components/Badge'

interface StatisticsScreenProps {}

interface ItemProps {
  title: string
  value: string | React.ReactNode
}

const Item: React.FC<ItemProps> = ({ title, value }) => {
  return (
    <Box>
      <CText.SectionHeader>{title}</CText.SectionHeader>
      <CText.PrimaryText mt="4px">{value}</CText.PrimaryText>
    </Box>
  )
}

const TimelineScreen: React.FC<StatisticsScreenProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  // eslint-disable-next-line
  const { data, isFetching, refetch, fulfilledTimeStamp } =
    useGetCitizenByIdQuery(parseInt(id))

  const [getCitizenHistory, { isLoading }] = useGetCitizinHistoryMutation()

  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [startTime, setStartTime] = useState<string | null>('00:00')

  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [endTime, setEndTime] = useState<string | null>('23:59')

  const [show, setShow] = useState<string>('All')

  const [timeArray, setTimeArray] = useState<any[]>([])

  type StatusOrConfig = Status | Config

  const [statuses, setStatuses] = useState<StatusOrConfig[]>([])

  const generateTimestamps = () => {
    const arr = []
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        arr.push(`${i}:${j === 0 ? `00` : 30 * j}`)
      }
    }
    return arr
  }

  useEffect(() => {
    const arr = generateTimestamps()
    setTimeArray(arr)

    search()
  }, [])

  const search = async () => {
    if (!startDate || !endDate || !data) return
    setStatuses([])

    let sDate = moment(startDate)
    if (startTime) {
      let hm = startTime.split(':')
      sDate.set({ h: parseInt(hm[0]), m: parseInt(hm[1]) })
    }

    let eDate = moment(endDate)
    if (endTime) {
      let hm = endTime.split(':')
      eDate.set({ h: parseInt(hm[0]), m: parseInt(hm[1]) })
    } else {
      eDate.set({ h: 23, m: 59 })
    }

    console.log(`/citizen/history/id/${sDate.format()}/${eDate.format()}`)

    let res = await getCitizenHistory({
      id: data.deviceImei,
      startDate: sDate.format(),
      endDate: eDate.format(),
    }).unwrap()

    console.log(res)

    let arrayToSort: StatusOrConfig[] = [...res.statuses, ...res.configs]

    arrayToSort.sort(function (a: any, b: any) {
      let aDate = a.timestamp ?? a.lastUpdate
      let bDate = b.timestamp ?? b.lastUpdate

      return +new Date(bDate) - +new Date(aDate)
    })

    setStatuses(arrayToSort)
  }

  return (
    <Box
      alignItems="flex-start"
      p={{ base: '16px', md: '30px', lg: '40px' }}
      h="100%"
      w="100%"
    >
      <Box display={{ base: 'none', lg: 'block' }} mb="10px">
        <CLink
          title={t('global.back-button')}
          onClick={() => history.goBack()}
        />
      </Box>
      <PageHeader
        title={`Timeline`}
        sampleText="Timeline"
        underTitle={`IMEI: ${data?.deviceImei}`}
      />

      <HStack mb="20px" spacing="24px" alignItems="end">
        <div>
          <CText.PrimaryText>Start</CText.PrimaryText>
          <div style={{ display: 'flex', gap: '6px' }}>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />

            <Menu>
              <MenuButton>
                <button className="time-input" style={{ minWidth: '100px' }}>
                  {startTime}
                </button>
              </MenuButton>
              <MenuList maxHeight={200} overflowY={'scroll'}>
                {timeArray.map((item, i) => {
                  return (
                    <MenuItem key={i} onClick={() => setStartTime(item)}>
                      {item}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          </div>
        </div>

        <div>
          <CText.PrimaryText>End</CText.PrimaryText>
          <div style={{ display: 'flex', gap: '6px' }}>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
            />

            <Menu>
              <MenuButton>
                <button className="time-input" style={{ minWidth: '100px' }}>
                  {endTime}
                </button>
              </MenuButton>
              <MenuList maxHeight={200} overflowY={'scroll'}>
                {timeArray.map((item, i) => {
                  return (
                    <MenuItem key={i} onClick={() => setEndTime(item)}>
                      {item}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Menu>
          </div>
        </div>

        {/* <div>
          <CText.PrimaryText>Show</CText.PrimaryText>

          <Menu>
            <MenuButton>
              <button className="time-input" style={{ minWidth: '100px' }}>
                {show}
              </button>
            </MenuButton>
            <MenuList maxHeight={200} overflowY={'scroll'}>
              <MenuItem onClick={() => setShow('All')}>All</MenuItem>
              <MenuItem onClick={() => setShow('Status')}>Status</MenuItem>
              <MenuItem onClick={() => setShow('Configuration change')}>
                Configuration change
              </MenuItem>
            </MenuList>
          </Menu>
        </div> */}

        <HStack spacing="6px">
          {startDate && (
            <Tooltip label="Reset filters">
              <button
                className="time-input"
                style={{ minHeight: '39.5px', minWidth: '39.5px' }}
                onClick={() => {
                  setStartDate(null)
                  setStartTime(null)
                  setEndDate(null)
                  setEndTime(null)
                  setShow('All')
                }}
              >
                <VscDebugRestart color="#000" size="16px" />
              </button>
            </Tooltip>
          )}
          <button
            className="time-input"
            disabled={!startDate || !endDate || !data || isLoading}
            onClick={() => search()}
          >
            Search
            {/* {isLoading && <Spinner size="xs" />} */}
          </button>
        </HStack>
      </HStack>

      <Box w="100%" overflow="scroll">
        {statuses.length === 0 && (
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isLoading ? (
              <Spinner size="lg" />
            ) : (
              <p style={{ opacity: 0.8 }}>{t('citizen.no-results')}</p>
            )}
          </div>
        )}

        {statuses.length > 0 &&
          statuses.map((it, i) => {
            let item = it as any
            let date = item.timestamp ?? item.lastUpdate
            //@ts-ignore
            console.log(item)
            if (item.timestamp) {
              return (
                <HStack
                  p="20px"
                  bg="#fff"
                  rounded="8px"
                  justifyContent="space-between"
                  mb="6px"
                  fontSize="14px"
                  key={i}
                >
                  <HStack spacing="28px">
                    <Item
                      title="Date"
                      value={moment(date).format('DD-MM-YYYY')}
                    />
                    <Item
                      title="Time"
                      value={moment(item.timestamp).format('HH:mm:ss')}
                    />
                    <Item title="Volume" value={`${item.buzzerVolume} / 6`} />
                    <Item title="Alarm" value={`${item.rooms[0].alarm} min`} />
                  </HStack>
                  <HStack>
                    <Box>{t('citizen.updated-tonight')}</Box>
                    <HStack
                      border="1px solid #C2C6D6"
                      rounded="8px"
                      overflow="hidden"
                    >
                      {item.rooms.map((item: any, i: number) => {
                        return (
                          <div
                            key={i}
                            style={{
                              fontWeight: 600,
                              fontSize: '13px',
                              color: '#3E445C',
                              borderRight:
                                i === item.rooms?.length - 1
                                  ? '0px solid #fff'
                                  : '1px solid #C2C6D6',
                              padding: '6px',
                              margin: '0px',
                              marginRight: '-1px',
                            }}
                          >
                            {item.hour < 10 ? `0${item.hour}` : item.hour}:
                            {item.min === 0 ? '00' : item.min}
                          </div>
                        )
                      })}
                    </HStack>
                  </HStack>
                </HStack>
              )
            }

            return (
              <Flex
                key={i}
                flexDirection={{ base: 'column', lg: 'row' }}
                p="20px"
                bg="#fff"
                rounded="8px"
                justifyContent="space-between"
                mb="6px"
              >
                <Flex flexDirection="column">
                  <HStack spacing="28px">
                    <Item
                      title="Date"
                      value={moment(date).format('DD-MM-YYYY')}
                    />
                    <Item
                      title="Time"
                      value={moment(date).format('HH:mm:ss')}
                    />
                    <Item
                      title="Status"
                      value={`Pillbox ${item.box ? 'in' : 'out'} ${
                        item.changedBox ? '(Changed box)' : ''
                      }`}
                    />
                    <Item
                      title="Battery level"
                      value={
                        item.shutdown
                          ? 'SHUT DOWN'
                          : `${item.battery.toString()} %`
                      }
                    />
                    <Item
                      title="Alarm"
                      value={`${item.configuration.rooms[0].alarm.toString()} min`}
                    />
                  </HStack>
                  <HStack mt="5" spacing="28px">
                    {/* <Item
                      title="Is alive"
                      value={item.isAlive ? 'true' : 'false'}
                    /> */}

                    <Item
                      title={t('citizen.lying-flat')}
                      value={
                        item.isFlat ? (
                          <Badge status="green">
                            {t('citizen.yes').toUpperCase()}
                          </Badge>
                        ) : (
                          <Badge status="red">
                            {t('citizen.no').toUpperCase()}
                          </Badge>
                        )
                      }
                    />

                    <Item
                      title={t('citizen.shutdown')}
                      value={
                        item.shutdown ? (
                          <Badge status="red">
                            {t('citizen.no').toUpperCase()}
                          </Badge>
                        ) : (
                          <Badge status="green">
                            {t('citizen.yes').toUpperCase()}
                          </Badge>
                        )
                      }
                    />
                  </HStack>
                </Flex>

                <PillBox status={item.rooms} pillInRooms={item.pillInRooms} />
              </Flex>
            )
          })}
        {/* <HStack p="20px" bg="#fff" rounded="8px" justifyContent="space-between">
          <HStack spacing="28px">
            <Item title="Date" value="Fri Jun 9 2023" />
            <Item title="Time" value="14:42:01" />
            <Item title="Status" value="Pillbox in" />
            <Item title="Battery level" value="83%" />
            <Item title="Alarm" value="30 min" />
          </HStack>
          <PillBox
            status={[
              { status: 'empty', timeToTake: '08:00', timeTaken: '08:30' },
              { status: 'empty', timeToTake: '08:00', timeTaken: '08:30' },
              { status: 'empty', timeToTake: '08:00', timeTaken: '08:30' },
              { status: 'empty', timeToTake: '08:00', timeTaken: '08:30' },
            ]}
            pillInRooms={[false, true, true, false]}
          />
        </HStack> */}
      </Box>
    </Box>
  )
}

export default TimelineScreen
