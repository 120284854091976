import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { NODE_ENV, SENTRY_DSN } from './constants'
import { initializeApp } from 'firebase/app'
import { initializeAnalytics } from 'firebase/analytics'

if (NODE_ENV === 'production') {
  // Setup Sentry
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })

  // Setup Firebase and Google Analytics
  const firebaseConfig = {
    apiKey: 'AIzaSyCJo0AvEqN_6aj0JuUFJMpVQG5sY1Zf4hM',
    authDomain: 'kibodan-mio.firebaseapp.com',
    projectId: 'kibodan-mio',
    storageBucket: 'kibodan-mio.appspot.com',
    messagingSenderId: '747428329036',
    appId: '1:747428329036:web:97fd4d4798a82285c5a6e9',
    measurementId: 'G-QP61T0G4CH',
  }
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig)
  initializeAnalytics(firebaseApp)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
