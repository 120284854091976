import { Box, Center } from '@chakra-ui/layout'
import React from 'react'
import { CText, CButton } from './index'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Skeleton } from '@chakra-ui/skeleton'
import { useTranslation } from 'react-i18next'

interface OnboardingStepProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  isComplete?: boolean
  title: string
  description: string
  isLoading: boolean
  stepNumber: number
}

const OnboardingStep: React.FC<OnboardingStepProps> = ({
  onClick,
  isComplete,
  title,
  description,
  isLoading,
  stepNumber,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {isLoading ? (
        <Skeleton borderRadius="22px">
          <Box
            p="20px"
            borderRadius="22px"
            maxW={{ base: '100%', lg: '270px' }}
            backgroundColor="white"
            shadow="base"
          >
            {isComplete ? (
              <IoIosCheckmarkCircle color="#59c385" size="56px" />
            ) : (
              <Center
                bg="mio-darkBackground"
                boxSize="56px"
                borderRadius="15px"
              >
                <CText.Header>{stepNumber}</CText.Header>
              </Center>
            )}
            <CText.Header my="10px">{title}</CText.Header>
            <Box h={{ base: 'fit-content', lg: '100px' }}>
              <CText.PrimaryTextMd bg="#999">{description}</CText.PrimaryTextMd>
            </Box>

            <Box mt={{ base: '40px', lg: '30px' }} w="full">
              {isComplete ? (
                <CButton btnType="regular" w="full">
                  + Add more
                </CButton>
              ) : (
                <CButton btnType="dark" w="full">
                  + Add
                </CButton>
              )}
            </Box>
          </Box>
        </Skeleton>
      ) : (
        <Box
          p="20px"
          borderRadius="22px"
          maxW={{ base: '100%', lg: '270px' }}
          backgroundColor="white"
          shadow="base"
        >
          {isComplete ? (
            <IoIosCheckmarkCircle color="#59c385" size="56px" />
          ) : (
            <Center bg="mio-darkBackground" boxSize="56px" borderRadius="15px">
              <CText.Header>{stepNumber}</CText.Header>
            </Center>
          )}
          <CText.Header my="10px">{title}</CText.Header>
          <Box h={{ base: 'fit-content', lg: '120px' }}>
            <CText.PrimaryTextMd>{description}</CText.PrimaryTextMd>
          </Box>
          <Box mt="30px" w="full">
            {isComplete ? (
              <CButton btnType="regular" w="full" onClick={onClick}>
                {t('global.add-more')}
              </CButton>
            ) : (
              <CButton btnType="dark" w="full" onClick={onClick}>
                {t('global.add-new')}
              </CButton>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default OnboardingStep
