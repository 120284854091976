import { Stack } from '@chakra-ui/react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../components/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import passwordValidator from '../utils/passwordValidator'
import { CButton, Step, CText } from '../../../../components/index'

interface PasswordFormData {
  password: string
  confirmPassword: string
}

interface PasswordFormProps {
  isSubmitting: boolean
  onSubmit: SubmitHandler<PasswordFormData>
}

const PasswordForm: React.FC<PasswordFormProps> = ({
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()

  const schema: yup.SchemaOf<PasswordFormData> = yup.object({
    password: passwordValidator(t).required(t('validation.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('validation.password-must-match'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordFormData>({ resolver: yupResolver(schema) })

  return (
    <Stack w={{ base: '90%', md: '350px' }} flexDirection="column">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Step step={3} of={3} />
        <CText.HeaderLg mb="40px">{t('signup.password-title')}</CText.HeaderLg>
        <Stack paddingBottom="6">
          <FormInput
            label={t('signup.password')}
            placeholder={t('signup.password')}
            error={errors.password}
            registeredForm={register('password')}
            id="password"
            tinted={true}
            inputType="password"
          />
          <FormInput
            label={t('signup.confirm-password')}
            placeholder={t('signup.confirm-password')}
            error={errors.confirmPassword}
            registeredForm={register('confirmPassword')}
            id="confirmPassword"
            tinted={true}
            inputType="password"
          />
        </Stack>

        <CButton btnType="mio" type="submit" w="full" isLoading={isSubmitting}>
          {t('signup.finish-button')}
        </CButton>
      </form>
    </Stack>
  )
}

export default PasswordForm
