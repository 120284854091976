import { Box, VStack, HStack, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, TableState } from 'react-table'
import { useDisclosure } from '@chakra-ui/react'
import ListSearch from '../components/ListSearch'
import { useIsDesktop } from '../utils/useIsDesktop'
import LoadingGenericTable from './TableBuilder/LoadingGenericTable'
import LoadingCardList from './LoadingCardList'
import {
  CLink,
  CText,
  CButton,
  AddNew,
  OnboardingContinue,
} from '../components/index'
import { useHistory } from 'react-router'
import { IoMdSettings } from 'react-icons/io'
import useRoleCheck from '../utils/useRoleCheck'
import { Role } from '../types/domainTypes'

interface EntitySearchViewProps<T> {
  header?: string
  searchPlaceholder: string
  sampleData: T[]
  columns: ({ accessor: keyof T; show?: boolean } & Column)[]
  tableInitialState?: Partial<TableState<{}>>
  data?: T[]
  isFetching: boolean
  cardRender: (elm: T) => JSX.Element
  keyFn: (elm: T) => string
  filterFn: (search: string, elm: T) => boolean
  modalRender?: (
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void
  ) => JSX.Element
  renderOnlyCards?: boolean
  wrapCards?: boolean
  settingsRender?: (
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void
  ) => JSX.Element
  settingsIcon?: boolean
  goBack?: boolean
  tabs?: React.ReactChild
  contentDescription?: React.ReactChild
  noDataDescription?: string
}

const EntitySearchView = <T extends object>({
  header,
  searchPlaceholder,
  sampleData,
  columns,
  tableInitialState,
  data,
  isFetching,
  cardRender,
  keyFn,
  filterFn,
  modalRender,
  renderOnlyCards,
  wrapCards,
  settingsRender,
  settingsIcon,
  goBack,
  tabs,
  contentDescription,
  noDataDescription,
}: EntitySearchViewProps<T>) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')
  const isDesktop = useIsDesktop()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const settings = useDisclosure()
  const history = useHistory()

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  const filteredData = data?.filter(elm => filterFn(search, elm))

  return (
    <>
      <Box h="100%" w="100%" zIndex="999">
        {goBack && (
          <Box
            display={{ base: 'none', lg: 'block' }}
            mb="20px"
            pt={{ base: '16px', md: '30px', lg: '40px' }}
            px={{ base: '16px', md: '30px', lg: '40px' }}
          >
            <CLink
              title={t('global.back-button')}
              onClick={() => history.goBack()}
            />
          </Box>
        )}

        <Stack
          mb={6}
          pt={{ base: '16px', md: '30px', lg: goBack ? '0px' : '40px' }}
          px={{ base: '16px', md: '30px', lg: '40px' }}
        >
          <OnboardingContinue />
          <HStack alignItems="center">
            <CText.Header sampleText="Header name" isLoading={!header} mr={2}>
              {header}
            </CText.Header>
            {modalRender && isAdminRoles && <AddNew onClick={onOpen} />}
            {settingsRender && (
              <CButton
                disabled={isFetching}
                leftIcon={<IoMdSettings size="18px" />}
                btnType="tinted"
                onClick={settings.onOpen}
              >
                {t('citizen.edit')}
              </CButton>
            )}
          </HStack>
        </Stack>
        <Box
          px={{ base: '16px', md: '30px', lg: '40px' }}
          pb={{ base: '20px', lg: '30px' }}
          // pb={{ base: '20px', lg: '20px' }}
        >
          {tabs}

          <ListSearch
            placeholder={searchPlaceholder}
            query={search}
            setQuery={setSearch}
          />
        </Box>
        <Box px={{ base: '16px', md: '30px', lg: '40px' }}>
          {contentDescription}
        </Box>
        {!isFetching && data?.length === 0 ? (
          <Box px={{ base: '16px', md: '30px', lg: '40px' }}>
            <CText.PrimaryTextMd>{noDataDescription || ''}</CText.PrimaryTextMd>
          </Box>
        ) : (
          <>
            {isDesktop && !renderOnlyCards ? (
              <Box px={{ base: '16px', md: '30px', lg: '40px' }}>
                <LoadingGenericTable
                  isLoading={isFetching}
                  data={filteredData}
                  sampleData={sampleData}
                  columns={columns}
                  initialState={tableInitialState}
                />
              </Box>
            ) : (
              <LoadingCardList
                data={filteredData}
                isFetching={isFetching}
                sampleData={sampleData}
                cardRender={cardRender}
                keyFn={keyFn}
                wrap={wrapCards}
              />
            )}
          </>
        )}
      </Box>
      {settingsRender &&
        settingsRender(settings.isOpen, settings.onOpen, settings.onClose)}
      {modalRender && modalRender(isOpen, onOpen, onClose)}
    </>
  )
}

export default EntitySearchView
