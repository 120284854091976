import { useEffect, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Box,
  HStack,
  Grid,
  Stack,
} from '@chakra-ui/react'
import * as CText from '../../../../../components/CText'
import FormInput from '../../../../../components/FormInput'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import CButton from '../../../../../components/CButton'
import VolumeSlider from './VolumeSlider'
import {
  MioRoomConfig,
  useUpdateDeviceConfigurationMutation,
} from '../../../../../redux/services/mio-api'
import {
  formatTimeString,
  timeStringToNumbers,
} from '../../../../../utils/utils'
import { useToast } from '@chakra-ui/toast'

interface Props {
  isOpen: boolean
  onClose: () => void
  configuration: MioRoomConfig[]
  volume: number
  id: number
  refetch: () => void
}

type PillFormData = {
  room1: string
  room2: string
  room3: string
  room4: string
  alarm: number
  //warning: number
}

export default function EditTimeDrawer({
  isOpen,
  onClose,
  configuration,
  volume,
  id,
  refetch,
}: Props) {
  const { t } = useTranslation()
  const [slider, setSlider] = useState(volume)

  const [updateDeviceConfiguration, { isLoading }] =
    useUpdateDeviceConfigurationMutation()

  const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  const reg = /^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/

  const schema: yup.SchemaOf<PillFormData> = yup.object({
    room1: yup
      .string()
      .trim()
      .matches(reg, t('validation.hours-minutes'))
      .required(t('validation.required')),
    room2: yup
      .string()
      .trim()
      .matches(regex, t('validation.hours-minutes'))
      .required(t('validation.required')),
    room3: yup
      .string()
      .trim()
      .matches(regex, t('validation.hours-minutes'))
      .required(t('validation.required')),
    room4: yup
      .string()
      .trim()
      .matches(regex, t('validation.hours-minutes'))
      .required(t('validation.required')),
    // alarm: yup
    //   .number()
    //   .required(t('validation.required'))
    //   .transform(value => (isNaN(value) ? undefined : value)),
    alarm: yup
      .number()
      .required(t('validation.required'))
      .transform(value => (isNaN(value) ? undefined : value)),
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<PillFormData>({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const toast = useToast()

  const onSubmit: SubmitHandler<PillFormData> = data => {
    updateDeviceConfiguration({
      id: id,
      buzzerVolume: volume,
      roomConfigs: [
        {
          warning: 0,
          alarm: data.alarm,
          ...timeStringToNumbers(data.room1),
        },
        {
          warning: 0,
          alarm: data.alarm,
          ...timeStringToNumbers(data.room2),
        },
        {
          warning: 0,
          alarm: data.alarm,
          ...timeStringToNumbers(data.room3),
        },
        {
          warning: 0,
          alarm: data.alarm,
          ...timeStringToNumbers(data.room4),
        },
      ],
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('citizen.configuration-toast.title'),
          description: t('citizen.configuration-toast.description'),
          duration: 10000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }

    setValue(
      'room1',
      formatTimeString(configuration[0].hour, configuration[0].min)
    )
    setValue(
      'room2',
      formatTimeString(configuration[1].hour, configuration[1].min)
    )
    setValue(
      'room3',
      formatTimeString(configuration[2].hour, configuration[2].min)
    )
    setValue(
      'room4',
      formatTimeString(configuration[3].hour, configuration[3].min)
    )

    setValue('alarm', configuration[0].alarm)

    setSlider(volume)
  }, [isOpen])

  return (
    <div>
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader p="24px">
              {t('citizen.drawer.title-editing')}
              <CText.PrimaryText>
                {t('citizen.drawer.under-title')}
              </CText.PrimaryText>
            </DrawerHeader>

            <DrawerBody p="0px">
              <Box p="24px">
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(4, 1fr)',
                  }}
                  gap="12px"
                >
                  <FormInput
                    label={t('citizen.drawer.room') + ' 1'}
                    placeholder={t('citizen.drawer.input-placeholder')}
                    error={errors.room1}
                    registeredForm={register('room1')}
                    id="room1"
                  />
                  <FormInput
                    label={t('citizen.drawer.room') + ' 2'}
                    placeholder={t('citizen.drawer.input-placeholder')}
                    error={errors.room2}
                    registeredForm={register('room2')}
                    id="room2"
                  />
                  <FormInput
                    label={t('citizen.drawer.room') + ' 3'}
                    placeholder={t('citizen.drawer.input-placeholder')}
                    error={errors.room3}
                    registeredForm={register('room3')}
                    id="room3"
                  />
                  <FormInput
                    label={t('citizen.drawer.room') + ' 4'}
                    placeholder={t('citizen.drawer.input-placeholder')}
                    error={errors.room4}
                    registeredForm={register('room4')}
                    id="room4"
                  />
                </Grid>
                <CText.PrimaryText>
                  {t('citizen.drawer.room-description')}
                </CText.PrimaryText>
              </Box>
              <Divider />
              <Stack p="24px" spacing="20px">
                {/* <FormInput
                  label={t('citizen.drawer.alarm')}
                  placeholder={t('citizen.drawer.alarm-placeholder')}
                  error={errors.alarm}
                  registeredForm={register('alarm')}
                  description={t('citizen.drawer.alarm-description')}
                  id="alarm"
                /> */}
                <FormInput
                  label={t('citizen.drawer.alarm')}
                  placeholder={t('citizen.drawer.alarm-placeholder')}
                  error={errors.alarm}
                  registeredForm={register('alarm')}
                  description={t('citizen.drawer.alarm-description')}
                  id="alarm"
                />
              </Stack>
              {/* <Divider /> */}
              {/* <Box p="24px">
                <HStack justifyContent="space-between" alignItems="center">
                  <CText.SectionHeader>
                    {t('citizen.drawer.volume')}
                  </CText.SectionHeader>
                  <CText.SectionHeader>{slider}</CText.SectionHeader>
                </HStack>

                <Box w="100%" mt="6px">
                  <VolumeSlider
                    defaultValue={slider}
                    onChange={e => setSlider(e)}
                  />
                  <CText.PrimaryText>
                    {t('citizen.drawer.volume-description')}
                  </CText.PrimaryText>
                </Box>
              </Box> */}
            </DrawerBody>

            <DrawerFooter alignItems="center">
              <HStack>
                <CButton btnType="regular" onClick={onClose}>
                  {t('citizen.drawer.cancel')}
                </CButton>
                <CButton type="submit" btnType="dark" isLoading={isLoading}>
                  {t('citizen.drawer.save')}
                </CButton>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </div>
  )
}
