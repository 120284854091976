import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import EntitySearchView from '../../../../containers/EntitySearchView'
import { useGetAllDistrictsQuery } from '../../../../redux/services/mio-api'
import { useIsDesktop } from '../../../../utils/useIsDesktop'
import DistrictsCard from './components/DistrictsCard'
import DistrictsAddModal from './containers/DistrictsAddModal'
import { useHistory } from 'react-router'
import OnboardingContinue from '../../../../components/OnBoardingContinue'

const sampleData = [
  {
    id: 1,
    name: 'NORD1',
    citizensCount: 7,
  },
  {
    id: 2,
    name: 'NORD2',
    citizensCount: 12,
  },
  {
    id: 3,
    name: 'NORD3',
    citizensCount: 15,
  },
  {
    id: 4,
    name: 'SYD1',
    citizensCount: 15,
  },
]

interface DistrictsScreenProps {}

const DistrictsScreen: React.FC<DistrictsScreenProps> = () => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const history = useHistory()
  const { data, isFetching, refetch } = useGetAllDistrictsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const columns: ({
    accessor: keyof typeof sampleData[0]
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: t('districts.header.name') as string,
        accessor: 'name',
      },
      {
        Header: t('districts.header.citizens') as string,
        accessor: 'citizensCount',
      },
    ],
    []
  )

  return (
    <>
      <EntitySearchView
        header={t('districts.header')}
        searchPlaceholder={t('districts.search-placeholder')}
        sampleData={sampleData}
        columns={columns}
        data={data?.districts}
        noDataDescription={t('districts.no-data')}
        isFetching={isFetching}
        cardRender={elm => (
          <DistrictsCard
            name={elm.name}
            citizensCount={elm.citizensCount}
            onClick={() =>
              history.push(history.location.pathname + '/' + elm.id)
            }
          />
        )}
        keyFn={elm => elm.id.toString()}
        filterFn={(search, elm) =>
          elm.name.toLowerCase().startsWith(search.toLowerCase())
        }
        modalRender={(isOpen, onOpen, onClose) => (
          <DistrictsAddModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            refetch={refetch}
          />
        )}
        renderOnlyCards={true}
        wrapCards={isDesktop}
      />
    </>
  )
}

export default DistrictsScreen
