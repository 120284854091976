import * as React from 'react'
import { Text, TextProps, Skeleton } from '@chakra-ui/react'

interface CustomProps {
  sampleText?: string
  isLoading?: boolean
}

type StyledSkeletonTextProps = TextProps & CustomProps

export const StyledSkeletonText = (props: StyledSkeletonTextProps) => {
  if (props.sampleText && props.isLoading) {
    return (
      <Skeleton
        display="inline-block"
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        borderRadius="10px"
      >
        {props.sampleText}
      </Skeleton>
    )
  }
  return <Text {...props} />
}

export const SectionHeader = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-headline"
    fontSize={{ base: '15px', lg: '14px' }}
    fontWeight="600"
    {...props}
  />
)

export const SectionHeaderLg = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-headline"
    fontSize={{ base: '17px', lg: '16px' }}
    fontWeight="600"
    {...props}
  />
)

export const TableText = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-headline"
    fontSize={{ base: '15px', lg: '14px' }}
    fontWeight="400"
    {...props}
  />
)

export const PrimaryText = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-primaryText"
    fontSize={{ base: '15px', lg: '13px' }}
    fontWeight="400"
    {...props}
  />
)

export const PrimaryTextMd = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-primaryText"
    fontSize={{ base: '15px', lg: '15px' }}
    fontWeight="600"
    {...props}
  />
)

export const PrimaryTextBig = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-primaryText"
    fontSize={{ base: '18px', lg: '18px' }}
    fontWeight="600"
    {...props}
  />
)

export const SecondaryText = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-secondaryText"
    fontSize={{ base: '13px', lg: '12px' }}
    fontWeight="600"
    {...props}
  />
)

export const Warning = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-red"
    fontSize={{ base: '14px', lg: '12px' }}
    fontWeight="500"
    {...props}
  />
)

export const Header = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-headline"
    fontSize={{ base: '22px', lg: '24px' }}
    fontWeight="700"
    {...props}
  />
)

export const HeaderLg = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-headline"
    fontSize={{ base: '26px', lg: '28px' }}
    fontWeight="700"
    {...props}
  />
)

export const Battery = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="mio-greenDark"
    fontSize={{ base: '24px', lg: '24px' }}
    fontWeight="600"
    {...props}
  />
)
