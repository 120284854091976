import { Thead, Tr, Th, chakra, Icon } from '@chakra-ui/react'
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go'
import { HeaderGroup } from 'react-table'

interface TableHeaderProps<T extends object> {
  headerGroups: HeaderGroup<T>[]
}

const TableHeader = <T extends object>({
  headerGroups,
}: TableHeaderProps<T>) => {
  return (
    <Thead>
      {headerGroups.map(headerGroup => (
        <Tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <Th
              fontSize="11px"
              fontWeight="500"
              letterSpacing="0"
              color="mio-secondaryText"
              pl="0px"
              pr="38px"
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              {column.render('Header')}
              <chakra.span pl="4">
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <Icon as={GoTriangleDown} aria-label="sorted descending" />
                  ) : (
                    <Icon as={GoTriangleUp} aria-label="sorted ascending" />
                  )
                ) : null}
              </chakra.span>
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
  )
}

export default TableHeader
