import React from 'react'
import {
  Badge as ChakraBadge,
  BadgeProps as ChakraProps,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react'
import { mioRoomStatus } from '../../../../../redux/services/mio-api'
import { useTranslation } from 'react-i18next'

export interface PillBadgeProps {
  children?: React.ReactNode
  lg?: boolean
  type?: mioRoomStatus
}

const PillBadge: React.FC<PillBadgeProps & ChakraProps> = props => {
  const { children, lg, type, ...restProps } = props
  const { t } = useTranslation()

  const getType = (roomStatus: mioRoomStatus) => {
    switch (roomStatus) {
      case 'taken':
        return {
          bg: 'mio-greenTint',
          color: '#3CA86A',
          text: t('citizen.pill-status.taken.name'),
          tooltip: t('citizen.pill-status.taken.description'),
        }
      case 'overdue':
        return {
          bg: 'mio-redTint',
          color: 'mio-red',
          text: t('citizen.pill-status.overdue.name'),
          tooltip: t('citizen.pill-status.overdue.description'),
        }
      case 'notTaken':
        return {
          bg: 'mio-gray',
          color: 'mio-headline',
          text: t('citizen.pill-status.not-taken.name'),
          tooltip: t('citizen.pill-status.not-taken.description'),
        }
      case 'takenLate':
        return {
          bg: 'mio-yellowTint',
          color: 'mio-yellow',
          text: t('citizen.pill-status.taken-late.name'),
          tooltip: t('citizen.pill-status.taken-late.description'),
        }
      case 'takenEarly':
        return {
          bg: 'mio-yellowTint',
          color: 'mio-yellow',
          text: t('citizen.pill-status.taken-early.name'),
          tooltip: t('citizen.pill-status.taken-early.description'),
        }
      case 'empty':
        return {
          bg: 'mio-gray',
          color: 'mio-headline',
          text: t('citizen.pill-status.empty.name'),
          tooltip: t('citizen.pill-status.empty.description'),
        }
    }
  }

  return (
    <>
      {type ? (
        <Tooltip label={getType(type).tooltip}>
          <ChakraBadge
            textTransform="uppercase"
            fontSize="12"
            data-testid="Badge"
            color={getType(type).color}
            bg={getType(type).bg}
            p="3px 10px 3px 10px"
            borderRadius="7px"
            {...restProps}
          >
            {children || getType(type).text.toUpperCase()}
          </ChakraBadge>
        </Tooltip>
      ) : (
        <Skeleton>NotTaken</Skeleton>
      )}
    </>
  )
}

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
PillBadge.id = 'PillBadge'

export default PillBadge
