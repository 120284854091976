import { Center, CenterProps, HStack } from '@chakra-ui/react'
import React, { MouseEventHandler } from 'react'
import { CText } from './index'
import { initialsFromFullName } from '../utils/utils'

interface Props {
  fullName: string
  onPress: MouseEventHandler<HTMLDivElement>
}

const InitialsBox: React.FC<Props & CenterProps> = props => {
  const { fullName, onPress, ...restProps } = props

  return (
    <HStack alignItems="center" role="group" onClick={onPress} cursor="pointer">
      <Center
        bg="#fff"
        w="40px"
        h="40px"
        borderRadius="full"
        shadow="base"
        userSelect="none"
        transition="0.4s all"
        cursor="pointer"
        _groupHover={{ shadow: 'lg' }}
        {...restProps}
      >
        <CText.SectionHeader fontSize="13px">
          {initialsFromFullName(fullName)}
        </CText.SectionHeader>
      </Center>
      <CText.TableText>{fullName}</CText.TableText>
    </HStack>
  )
}

export default InitialsBox
