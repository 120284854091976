import React from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'

export interface CInputProps {
  placeholder: string
  tinted?: boolean
  label?: string
}

const CTextarea = React.forwardRef<
  HTMLTextAreaElement,
  CInputProps & TextareaProps
>((props, ref) => {
  const tintedStyles: React.CSSProperties = {
    borderColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    color: '#3D494D',
  }
  const regularStyles: React.CSSProperties = {
    borderColor: '#F2F5F6',
    backgroundColor: '#F2F5F6',
    color: 'mio-headline',
  }

  const { placeholder, tinted, label, ...restProps } = props

  return (
    <Textarea
      ref={ref}
      style={props.tinted ? tintedStyles : regularStyles}
      backgroundColor="#FFFFFF"
      boxShadow={props.tinted ? 'base' : 'none'}
      minH={{ base: '54px', lg: '40px' }}
      fontSize={{ base: '16px', lg: '13px' }}
      borderRadius={{ base: 14, lg: 10 }}
      pt="14px"
      focusBorderColor="#7C7C7C"
      borderWidth="1"
      resize="vertical"
      w="100% !important"
      data-test={`input-${placeholder}`}
      placeholder={props.placeholder}
      {...restProps}
    />
  )
})

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
CTextarea.id = 'CTextarea'

export default CTextarea
