import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsDrawer } from '../../../../../components/index'
import * as yup from 'yup'
import { useUpdatePasswordMutation } from '../../../../../redux/services/mio-api'
import { FormInput } from '../../../../../components/index'
import passwordValidator from '../../../../Onboarding/Signup/utils/passwordValidator'

interface ResetPasswordModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
}

type ResetPasswordModalForm = {
  existingPW: string
  newPW: string
  confirmPW: string
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isOpen,
  onClose,
  refetch,
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  //TODO:
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation()

  const schema: yup.SchemaOf<ResetPasswordModalForm> = yup.object({
    existingPW: yup.string().required(t('validation.required')),
    newPW: passwordValidator(t).required(t('validation.required')),
    confirmPW: yup
      .string()
      .oneOf([yup.ref('newPW')], t('validation.password-must-match'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPasswordModalForm>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<ResetPasswordModalForm> = data => {
    updatePassword({
      currentPassword: data.existingPW,
      password: data.newPW,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('account.password-toast.title'),
          description: t('account.password-toast.description'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('account.reset-password.title')}
      bodyRender={() => (
        <VStack>
          <Stack width="100%" mb="30px" spacing="20px">
            <FormInput
              error={errors.existingPW}
              label={t('account.reset-password.existing-pw')}
              placeholder={t('account.reset-password.existing-pw')}
              registeredForm={register('existingPW')}
              inputType="password"
            />
            <FormInput
              error={errors.newPW}
              label={t('account.reset-password.new-pw')}
              placeholder={t('account.reset-password.new-pw')}
              registeredForm={register('newPW')}
              inputType="password"
            />
            <FormInput
              error={errors.confirmPW}
              label={t('account.reset-password.confirm-pw')}
              placeholder={t('account.reset-password.confirm-pw')}
              registeredForm={register('confirmPW')}
              inputType="password"
            />
          </Stack>
        </VStack>
      )}
      cancelButtonTitle={t('account.reset-password.cancel-button')}
      submitButtonTitle={t('account.reset-password.reset-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default ResetPasswordModal
