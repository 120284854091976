import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Center, Link, Text } from '@chakra-ui/layout'
import { Slide } from '@chakra-ui/transition'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CButton from '../components/CButton'

interface CookieConsentProviderProps {}

const CookiesConsentProvider: React.FC<CookieConsentProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    const consent = window.localStorage.getItem('cookies-consent')
    if (!consent || consent !== 'true') {
      onToggle()
    }
  }, [])

  return (
    <>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 9999 }}>
        <Box
          bgColor="mio-headline"
          color="white"
          w="100%"
          p="6"
          zIndex="sticky"
        >
          <Center>
            <Text>
              {t('cookies.you-agree-notice')}{' '}
              <Link fontWeight="bold" href="/legal/cookies">
                {t('cookies.cookie-policy-link')}
              </Link>
            </Text>

            <CButton
              btnType="mio"
              mx="3"
              minW="5em"
              onClick={() => {
                window.localStorage.setItem('cookies-consent', 'true')
                onToggle()
              }}
              data-test="cookies-accept-button"
            >
              {t('cookies.accept-button')}
            </CButton>
          </Center>
        </Box>
      </Slide>
      {children}
    </>
  )
}

export default CookiesConsentProvider
