import React from 'react'
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderProps,
} from '@chakra-ui/react'

interface VolumeSliderProps {
  children?: React.ReactChild
}

const VolumeSlider: React.FC<VolumeSliderProps & SliderProps> = (
  props: VolumeSliderProps
) => {
  return (
    <Slider w={{ base: '100%' }} {...props} max={6} min={1} step={1}>
      <SliderTrack h="7px" borderRadius="full">
        <SliderFilledTrack bg="mio-headline" />
      </SliderTrack>
      <SliderThumb boxSize={{ base: '30px', lg: '26px' }} />
    </Slider>
  )
}

export default VolumeSlider
