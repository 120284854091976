import {
  useDisclosure,
  Stack,
  Wrap,
  HStack,
  Box,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  useAssignEmployeeDistrictMutation,
  useGetAllDistrictsQuery,
  useGetUserByIdQuery,
} from '../../../../../redux/services/mio-api'
import { useHistory } from 'react-router-dom'
import {
  CLink,
  CText,
  CButton,
  PageHeader,
  AddNew,
} from '../../../../../components/index'
import {
  firstCharToUpper,
  fullName,
  roleToI18nKey,
} from '../../../../../utils/utils'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import { IoMdSettings } from 'react-icons/io'
import EmployeeSettingsModal from '../containers/EmployeeSettingsModal'
import DistrictAddModal from '../containers/DistrictAddModal'
import DistrictsCard from '../../Districts/components/DistrictsCard'
import AsyncAlert from '../../../../../containers/AsyncAlert'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import useRoleCheck from '../../../../../utils/useRoleCheck'
import { Role } from '../../../../../types/domainTypes'

interface EmployeeScreenProps {}

const EmployeeScreen: React.FC<EmployeeScreenProps> = () => {
  const { id } = useParams<{ id: string }>()

  const { data, isFetching, refetch } = useGetUserByIdQuery(parseInt(id), {
    refetchOnMountOrArgChange: true,
  })

  const districtQuery = useGetAllDistrictsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const { t } = useTranslation()
  const history = useHistory()

  const toast = useToast()

  const settingsModal = useDisclosure()
  const addDistrictModal = useDisclosure()

  const session = useSelector((state: RootState) => state.session)

  if (parseInt(id) === session.id) {
    history.goBack()
  }

  const [assignEmployeeDistrict] = useAssignEmployeeDistrictMutation()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    {
      districtName: string
    },
    { districtId: number }
  >()

  const role = useRoleCheck() as Role
  const isAdminRoles = role === 'superUser' || role === 'employeeAdmin'

  const employeeData = [
    {
      title: t('employee.modal.role'),
      value: t(roleToI18nKey(data?.roles[0]) || ''),
    },
    {
      title: t('employee.modal.phoneNumber'),
      value: data?.phoneNumber,
    },
    {
      title: t('employee.email'),
      value: data?.email,
    },
  ]

  return (
    <Box p={{ base: '16px', md: '30px', lg: '40px' }}>
      <Box display={{ base: 'none', lg: 'block' }} mb="10px">
        <CLink
          title={t('global.back-button')}
          onClick={() => history.goBack()}
        />
      </Box>
      <PageHeader
        title={data && fullName(data?.firstName, data?.lastName)}
        sampleText="FornavnEfternavn"
      >
        <CButton
          disabled={isFetching}
          btnType="tinted"
          leftIcon={<IoMdSettings size="18px" />}
          onClick={settingsModal.onOpen}
        >
          {t('employee.edit')}
        </CButton>
      </PageHeader>
      <Stack>
        {employeeData.map(item => (
          <Box mb="10px">
            <CText.SectionHeader>{item.title}</CText.SectionHeader>
            <CText.PrimaryText
              sampleText="email@example.com"
              isLoading={isFetching}
            >
              {item.value}
            </CText.PrimaryText>
          </Box>
        ))}
        {/* <CText.PrimaryText sampleText="superUser" isLoading={isFetching}>
          {data?.userType}
        </CText.PrimaryText>
        <CText.PrimaryText sampleText="12121212" isLoading={isFetching}>
          {data?.phoneNumber}
        </CText.PrimaryText>
        <CText.PrimaryText
          sampleText="email@example.com"
          isLoading={isFetching}
        >
          {data?.email}
        </CText.PrimaryText> */}
      </Stack>
      <HStack mt="34px" mb="10px">
        <CText.SectionHeaderLg fontSize="20px" mb="0">
          {t('employee.districts')}
        </CText.SectionHeaderLg>

        {/* <IconButton
          tooltip={t('employee.add-district.title')}
          aria-label="Tilføj distrikt"
          icon={<AiFillPlusCircle size="28px" />}
          onClick={addDistrictModal.onOpen}
        /> */}
        {isAdminRoles && <AddNew onClick={addDistrictModal.onOpen} />}
      </HStack>
      {data?.employee?.districts?.length === 0 && data ? (
        <CText.PrimaryText mb="16px">
          {t('employee.no-districts', {
            name: firstCharToUpper(data.firstName),
          })}
        </CText.PrimaryText>
      ) : (
        <CText.PrimaryText mb="16px">
          {t('employee.districts-description')}
        </CText.PrimaryText>
      )}

      <Wrap>
        {data &&
          data.employee &&
          data.employee.districts &&
          data?.employee.districts.map((item, index) => (
            <DistrictsCard
              key={item.id}
              citizensCount={item.citizensCount}
              name={item.name}
              onPressDelete={async e => {
                e.stopPropagation()
                await openAlertAndAwaitConfirmation(
                  {
                    districtName: item.name,
                  },
                  { districtId: item.id }
                )
              }}
              onClick={() => history.push('/dashboard/districts/' + item.id)}
            />
          ))}
      </Wrap>

      <EmployeeSettingsModal
        id={parseInt(id)}
        isOpen={settingsModal.isOpen}
        firstName={data?.firstName}
        lastName={data?.lastName}
        phoneNumber={data?.phoneNumber}
        role={data?.roles[0]}
        onClose={settingsModal.onClose}
        onOpen={settingsModal.onOpen}
        refetch={refetch}
      />
      {districtQuery.data && (
        <DistrictAddModal
          isOpen={addDistrictModal.isOpen}
          onClose={addDistrictModal.onClose}
          onOpen={addDistrictModal.onOpen}
          refetch={() => {
            districtQuery.refetch()
            refetch()
          }}
          id={parseInt(id)}
          districts={districtQuery.data?.districts
            .filter(d => {
              const district = data?.employee?.districts?.find(
                e => d.id === e.id
              )

              return district === undefined
            })
            .map(d => ({
              value: d.id,
              name: d.name,
            }))}
        />
      )}
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          `${t('employee.alert.remove', { name: data?.districtName })}`
        }
        cancelButtonText={t('employee.alert.cancel-button')}
        submitButtonText={t('employee.alert.remove-button')}
        onClick={async payload => {
          assignEmployeeDistrict({
            districtId: payload?.districtId as number,
            employeeId: parseInt(id),
            delete: true,
          })
            .unwrap()
            .then(() => {
              refetch()
              toast({
                title: t('employee.toast.title'),
                description: t('employee.toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...alertProps}
      />
    </Box>
  )
}

export default EmployeeScreen
