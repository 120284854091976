import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/layout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import IconButton from '../../../components/IconButton'
import MenuItem from '../../../components/MenuItem'
import { useIsDesktop } from '../../../utils/useIsDesktop'
import { IoIosMenu } from 'react-icons/io'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Icon,
  Image,
} from '@chakra-ui/react'
import { TFunction } from 'i18next'
import DrawerMenuItem from '../../../components/DrawerMenuItem'
import { useSwipeable } from 'react-swipeable'
import useRoleCheck from '../../../utils/useRoleCheck'
import { Role } from '../../../types/domainTypes'
import { CgRemove } from 'react-icons/cg'
import { IoMdClose } from 'react-icons/io'
import {
  useGetUserByIdQuery,
  useUpdateOrganisationConfiguredMutation,
} from '../../../redux/services/mio-api'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import logo from '../../../assets/Logo.svg'
import logo_small from '../../../assets/Logo_small.svg'

export type PageData = {
  title: string
  href: string
  text: string
  active?: (page: string) => boolean
  authorized: (role: Role) => boolean
}[]

const allowAllRoles = (role: Role) => {
  return role === 'employee' || role === 'employeeAdmin' || role === 'superUser'
}

const allowAdminRoles = (role: Role) => {
  return role === 'employeeAdmin' || role === 'superUser'
}

const pages: (t: TFunction) => PageData = t => [
  {
    title: 'home',
    href: '/dashboard/home',
    text: t(`dashboard-header.home`),
    authorized: allowAllRoles,
  },
  {
    title: 'citizens',
    href: '/dashboard/citizens',
    text: t(`dashboard-header.citizens`),
    authorized: allowAllRoles,
  },
  {
    title: 'districts',
    href: '/dashboard/districts',
    text: t(`dashboard-header.districts`),
    authorized: allowAllRoles,
  },
  {
    title: 'employees',
    href: '/dashboard/employees',
    text: t(`dashboard-header.employees`),
    authorized: allowAdminRoles,
  },
  {
    title: 'devices',
    href: '/dashboard/devices',
    text: t(`dashboard-header.devices`),
    authorized: allowAdminRoles,
  },
]

const accountPages: (t: TFunction) => PageData = t => [
  {
    title: 'support',
    href: '/dashboard/support',
    text: t(`dashboard-header.support`),
    authorized: allowAllRoles,
  },
  {
    title: 'account',
    href: '/dashboard/account',
    text: t(`dashboard-header.account`),
    authorized: allowAllRoles,
  },
]

interface DashboardHeaderProps {}

const DashboardHeader: React.FC<DashboardHeaderProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { pathname } = useLocation()
  const path = pathname.split('/')
  const activePage = path[path.indexOf('dashboard') + 1]

  const isDesktop = useIsDesktop()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const role = useRoleCheck() as Role

  const session = useSelector((state: RootState) => state.session)
  const { data, isFetching, refetch } = useGetUserByIdQuery(session.id)

  const [updateOrganisationConfiguration, { isLoading }] =
    useUpdateOrganisationConfiguredMutation()

  const getStartedPage = {
    title: 'get-started',
    href: '/dashboard/get-started',
    text: `👋 \xa0 ${t(`dashboard-header.get-started`)}`,
    authorized: allowAdminRoles,
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isOpen) onOpen()
    },
  })

  if (isDesktop) {
    return (
      <Stack height="100vh" backgroundColor="white" minW="220px">
        <VStack
          pt="25px"
          pb="10px"
          onClick={() => history.push('/dashboard/home')}
        >
          <Image src={logo} h="40px" />
        </VStack>

        <Divider />
        <Stack height="100%" justifyContent="space-between" p="4">
          <Stack>
            {/* Customized for only showing sometime when organsiation is not configured */}
            {data &&
              data.organisation?.configured !== undefined &&
              data.organisation.configured === false && (
                <HStack>
                  <MenuItem
                    key={getStartedPage.title}
                    onClick={() => {
                      history.replace(getStartedPage.href)
                    }}
                    text={getStartedPage.text}
                    active={activePage === getStartedPage.title}
                  >
                    <Box
                      opacity="0.7"
                      transition="0.3s all"
                      _hover={{ opacity: 1 }}
                    >
                      <Icon
                        as={IoMdClose}
                        size="24px !important"
                        onClick={() =>
                          updateOrganisationConfiguration({ configured: true })
                            .unwrap()
                            .then(() => {
                              refetch()
                            })
                        }
                      />
                    </Box>
                  </MenuItem>
                </HStack>
              )}

            {pages(t).map(page =>
              page.authorized(role) ? (
                <>
                  <MenuItem
                    key={page.title}
                    onClick={() => {
                      history.replace(page.href)
                    }}
                    text={page.text}
                    active={
                      page.active?.(activePage) || activePage === page.title
                    }
                  ></MenuItem>
                </>
              ) : null
            )}
          </Stack>
          <Stack>
            {accountPages(t).map(page =>
              page.authorized(role) ? (
                <MenuItem
                  key={page.title}
                  onClick={() => {
                    history.replace(page.href)
                  }}
                  text={page.text}
                  active={
                    page.active?.(activePage) || activePage === page.title
                  }
                />
              ) : null
            )}
            {/* <LoggedInAs firstName={data?.firstName} lastName={data?.lastName} /> */}
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      {/* Swipe handler */}
      <Box
        position="absolute"
        width="10vw"
        height="100%"
        right="0"
        {...handlers}
      ></Box>
      <Flex
        py={5}
        px={[2, 4, 8, 12]}
        w="full"
        position="sticky"
        top="0"
        zIndex="modal"
        alignItems="center"
        bg="mio-background"
        justifyContent="space-between"
      >
        <Image h="30px" src={logo_small} />
        {/* <Heading size="lg" color="mio-secondaryText">
          Innopendence
        </Heading> */}

        <IconButton
          tinted={true}
          aria-label={t('tooltip.burger-menu')}
          icon={<IoIosMenu size={24} />}
          onClick={onOpen}
          data-test="burger-menu"
        />
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader mb="4vh">Innopendence </DrawerHeader>

            <DrawerBody display="flex" flexDir="column">
              {data &&
                data.organisation?.configured !== undefined &&
                data.organisation.configured === false && (
                  <HStack>
                    <DrawerMenuItem
                      key={getStartedPage.title}
                      onClick={() => {
                        onClose()
                        history.replace(getStartedPage.href)
                      }}
                      text={getStartedPage.text}
                      active={activePage === getStartedPage.title}
                    >
                      <HStack
                        justifyContent="flex-end"
                        h="full"
                        px="16px"
                        onClick={() =>
                          updateOrganisationConfiguration({
                            configured: true,
                          })
                            .unwrap()
                            .then(() => {
                              refetch()
                            })
                        }
                      >
                        <Icon as={IoMdClose} size="24px !important" />
                      </HStack>
                    </DrawerMenuItem>
                  </HStack>
                )}
              <Box>
                {pages(t).map(page =>
                  page.authorized(role) ? (
                    <DrawerMenuItem
                      key={page.title}
                      onClick={() => {
                        onClose()
                        history.replace(page.href)
                      }}
                      text={page.text}
                      active={
                        page.active?.(activePage) || activePage === page.title
                      }
                    />
                  ) : null
                )}
              </Box>
              <Box mt="3vh">
                {accountPages(t).map(page =>
                  page.authorized(role) ? (
                    <DrawerMenuItem
                      key={page.title}
                      onClick={() => {
                        onClose()
                        history.replace(page.href)
                      }}
                      text={page.text}
                      active={
                        page.active?.(activePage) || activePage === page.title
                      }
                    />
                  ) : null
                )}
                {/* <LoggedInAs firstName="thomas" lastName="qvortrup" /> */}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  )
}

export default DashboardHeader
