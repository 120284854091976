import { Box, Flex } from '@chakra-ui/layout'
import React from 'react'
import { useIsDesktop } from '../../utils/useIsDesktop'
import DashboardAuthorizationChecker from './containers/DashboardAuthorizationChecker'
import DashboardHeader from './containers/DashboardHeader'
import DashboardErrorBoundary from './DashboardErrorBoundary'
import DashboardRouter from './DashboardRouter'

interface DashboardScreenProps {}

const DashboardScreen: React.FC<DashboardScreenProps> = () => {
  const isDesktop = useIsDesktop()

  return (
    <DashboardAuthorizationChecker>
      <Flex direction={isDesktop ? 'row' : 'column'} h="100vh">
        <DashboardHeader />
        <Box
          overflow={{ base: 'unset', lg: 'auto' }}
          w="100%"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              width: '0px',
            },
          }}
        >
          {/* p={{ base: '16px', md: '30px', lg: '40px' }} */}
          <DashboardErrorBoundary>
            <DashboardRouter />
          </DashboardErrorBoundary>
        </Box>
      </Flex>
    </DashboardAuthorizationChecker>
  )
}

export default DashboardScreen
//p={[2, 4, 8, 10]} pt={[4, 4, 4, 10]}
