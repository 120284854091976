import { Button, ButtonProps as ChakraProps } from '@chakra-ui/react'
import React from 'react'

interface TabProps {
  selected: boolean

  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Tab: React.FC<TabProps & ChakraProps> = props => {
  const { selected, onClick, ...restProps } = props

  const getType = () => {
    switch (selected) {
      case true:
        return {
          bg: '#fff',
          color: 'mio-headline',
          shadow: 'base',
          hover: '#fff',
        }
      case false:
        return {
          bg: 'transparent',
          color: 'mio-primaryText',
          shadow: 'none',
          hover: 'none',
        }
      default:
    }
  }

  return (
    <Button
      color={getType()?.color}
      bg={getType()?.bg}
      boxShadow={getType()?.shadow}
      onClick={onClick}
      _hover={{ bg: getType()?.hover }}
      p="10px"
      fontSize={{ base: '14px', lg: '13px' }}
      h={{ base: '46px', lg: '38px' }}
      borderRadius={12}
      {...restProps}
    >
      {props.children}
    </Button>
  )
}

export default Tab
