import { Stack } from '@chakra-ui/react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../../../../components/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import passwordValidator from '../utils/passwordValidator'
import { CButton, CText } from '../../../../components/index'

interface EmployeeSignupFormData {
  firstName: string
  lastName: string
  phone: string
  password: string
  confirmPassword: string
}

interface EmployeeSignupFormProps {
  onSubmit: SubmitHandler<EmployeeSignupFormData>
  isSubmitting: boolean
}

const EmployeeSignupForm: React.FC<EmployeeSignupFormProps> = ({
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()

  const schema: yup.SchemaOf<EmployeeSignupFormData> = yup.object({
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    phone: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits')),
    password: passwordValidator(t).required(t('validation.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('validation.password-must-match'))
      .required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeSignupFormData>({
    resolver: yupResolver(schema),
  })

  return (
    <Stack w={{ base: '90%', md: '350px' }} flexDirection="column">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <CText.HeaderLg mb="8px">{t('signup.employee-title')}</CText.HeaderLg>
        <CText.PrimaryText mb="40px">
          {t('signup.employee-subtitle')}
        </CText.PrimaryText>
        <Stack paddingBottom="6">
          <FormInput
            label={t('signup.first-name')}
            placeholder={t('signup.first-name')}
            error={errors.firstName}
            registeredForm={register('firstName', {
              required: t('signup.required') as string,
            })}
            id="first_name"
            tinted={true}
          />
          <FormInput
            label={t('signup.last-name')}
            placeholder={t('signup.last-name')}
            error={errors.firstName}
            registeredForm={register('lastName', {
              required: t('signup.required') as string,
            })}
            id="last_name"
            tinted={true}
          />
          <FormInput
            label={t('signup.phone')}
            inputMode="tel"
            placeholder={t('signup.phone')}
            error={errors.phone}
            registeredForm={register('phone', {
              required: t('signup.required') as string,
            })}
            id="phone"
            tinted={true}
            leftAddon="+45"
          />
          <FormInput
            label={t('signup.password')}
            placeholder={t('signup.password')}
            error={errors.password}
            registeredForm={register('password')}
            id="password"
            tinted={true}
            inputType="password"
          />
          <FormInput
            label={t('signup.confirm-password')}
            placeholder={t('signup.confirm-password')}
            error={errors.confirmPassword}
            registeredForm={register('confirmPassword')}
            id="confirmPassword"
            tinted={true}
            inputType="password"
          />
        </Stack>

        <CButton btnType="mio" type="submit" w="full" isLoading={isSubmitting}>
          {t('signup.continue-button')}
        </CButton>
      </form>
    </Stack>
  )
}

export default EmployeeSignupForm
