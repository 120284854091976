import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDeleteInvitationMutation,
  useGetAllUsersQuery,
  useResendInviteMutation,
  useGetAllInvitesQuery,
  useDeleteUserMutation,
} from '../../../../redux/services/mio-api'
import { Column } from 'react-table'
import EntitySearchView from '../../../../containers/EntitySearchView'
import EmployeeAddModal from './containers/EmployeeAddModal'
import { firstCharToUpper, fullName } from '../../../../utils/utils'
import { useHistory } from 'react-router'
import useAsyncAlert from '../../../../utils/useAsyncAlert'
import AsyncAlert from '../../../../containers/AsyncAlert'
import { User } from '../../../../redux/services/mio-api'

import {
  CText,
  CButton,
  Tab,
  CitizenBox,
  InitialsBox,
} from '../../../../components/index'
import { HStack } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/react'
import OnboardingContinue from '../../../../components/OnBoardingContinue'

const sampleData = [
  {
    email: 'sample@sample.dk',
    fullName: 'Alexander Magnus',
    firstName: 'Sample',
    lastName: 'Lol',
    id: 1,
    phoneNumber: '+ 45 22 67 80 25',
  },
  {
    email: 'sample@sample.dk',
    fullName: 'Alexander Magnus',
    firstName: 'Sample',
    lastName: 'Lol',
    phoneNumber: '+ 45 22 67 80 25',
    id: 2,
  },
]

const invited_sampleData = [
  {
    email: 'sample@sample.dk',
    id: 1,
    firstName: 'Sample',
    lastName: 'Lol',
  },
  {
    email: 'sample@sample.dk',
    id: 2,
    firstName: 'Sample',
    lastName: 'Lol',
  },
]

interface EmployeesScreenProps {}

const EmployeesScreen: React.FC<EmployeesScreenProps> = () => {
  const { t } = useTranslation()
  const { data, isFetching, refetch } = useGetAllUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const employeeData = data?.users.map(u => ({
    fullName: fullName(u.firstName, u.lastName),
    ...u,
  }))

  const [resend] = useResendInviteMutation()
  const [deleteInvitation] = useDeleteInvitationMutation()
  const [deleteUser] = useDeleteUserMutation()

  const invites = useGetAllInvitesQuery(null)

  const toast = useToast()

  const [page, setPage] = useState<'employees' | 'invited'>('employees')
  const history = useHistory()

  //Delete employee
  const {
    openAlertAndAwaitConfirmation: openDeleteEmployeeAlert,
    alertProps: deleteEmployeeProps,
  } = useAsyncAlert<
    {
      name: string
    },
    { id: number }
  >()

  //Delete invitation
  const {
    openAlertAndAwaitConfirmation: openDeleteInviteAlert,
    alertProps: deleteInviteProps,
  } = useAsyncAlert<
    {
      email: string
    },
    { id: number }
  >()

  //Resend invitation
  const {
    openAlertAndAwaitConfirmation: openSendAlert,
    alertProps: sendInviteProps,
  } = useAsyncAlert<
    {
      email: string
    },
    { id: number }
  >()

  const renderInvitationButtons = (
    employee: User,
    btnType: 'tinted' | 'regular'
  ) => {
    return (
      <HStack>
        <CButton
          btnType={btnType}
          onClick={async () => {
            await openDeleteInviteAlert(
              {
                email: employee.email,
              },
              { id: employee.id }
            )
          }}
        >
          {t('employees.delete-invitation')}
        </CButton>
        <CButton
          btnType={btnType}
          onClick={async () => {
            await openSendAlert(
              {
                email: employee.email,
              },
              { id: employee.id }
            )
          }}
        >
          {t('employees.send-again')}
        </CButton>
      </HStack>
    )
  }

  const columns: ({
    accessor: keyof typeof sampleData[0]
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: t('employees.table-header.fullName') as string,
        accessor: 'fullName',
        Cell: props => {
          let employee = props.row.original as User
          return (
            <HStack>
              <InitialsBox
                fullName={fullName(employee.firstName, employee.lastName)}
                onPress={() => {
                  history.push(history.location.pathname + '/' + employee.id)
                }}
              />
              {/* <CText.TableText
                cursor="pointer"
                onClick={() => {
                  history.push(history.location.pathname + '/' + employee.id)
                }}
              >
                {firstCharToUpper(props.cell.value)}
              </CText.TableText> */}
            </HStack>
          )
        },
      },
      {
        Header: t('employees.table-header.email') as string,
        accessor: 'email',
      },
      {
        Header: t('employees.table-header.phone-number') as string,
        accessor: 'phoneNumber',
      },
      // {
      //   Header: t('employees.table-header.status') as string,
      //   accessor: 'status',
      // },
      // {
      //   Header: ' ',
      //   // @ts-ignore
      //   accessor: 'null_0',
      //   disableSortBy: true,
      //   Cell: props => {
      //     const rows = [
      //       {
      //         title: 'Slet medarbejder',
      //         onPress: async () => {
      //           const employee = props.cell.row.original as User
      //           await openDeleteEmployeeAlert(
      //             {
      //               name: fullName(employee.firstName, employee.lastName),
      //             },
      //             { id: employee.id }
      //           )
      //         },
      //         warning: true,
      //       },
      //     ]

      //     return (
      //       <HStack>
      //         <TableSettings rows={rows} />
      //       </HStack>
      //     )
      //   },
      // },
    ],
    []
  )

  const invited_columns: ({
    accessor: keyof typeof invited_sampleData[0]
  } & Column)[] = React.useMemo(
    () => [
      {
        Header: t('employees.table-header.email') as string,
        accessor: 'email',
      },
      {
        Header: ' ',
        // @ts-ignore
        accessor: 'null_0',
        disableSortBy: true,
        Cell: props => {
          return renderInvitationButtons(
            props.cell.row.original as User,
            'tinted'
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <EntitySearchView
        header={t('employees.title')}
        searchPlaceholder={t('employees.search-placeholder')}
        sampleData={page === 'employees' ? sampleData : invited_sampleData}
        //@ts-ignore
        columns={page === 'employees' ? columns : invited_columns}
        noDataDescription={t('employees.no-data')}
        tabs={
          <HStack mb="14px">
            <Tab
              onClick={() => setPage('employees')}
              selected={page === 'employees'}
            >
              {t('employees.employees')}
            </Tab>
            <Tab
              onClick={() => setPage('invited')}
              selected={page === 'invited'}
            >
              {t('employees.invited')}
            </Tab>
          </HStack>
        }
        data={page === 'employees' ? employeeData : invites?.data?.invitations}
        isFetching={isFetching}
        cardRender={elm => (
          <CitizenBox
            key={elm.id}
            notDevice={true}
            address={page === 'employees' ? elm.email : ''}
            onClick={() => {
              if (page === 'invited') return
              history.push(history.location.pathname + '/' + elm.id)
            }}
            //@ts-ignore
            phone={page === 'employees' ? elm.phoneNumber : ''}
            name={
              page === 'employees'
                ? //@ts-ignore
                  firstCharToUpper(elm.firstName) +
                  ' ' +
                  //@ts-ignore
                  firstCharToUpper(elm.lastName)
                : elm.email
            }
          >
            {page === 'invited' ? (
              renderInvitationButtons(elm as User, 'regular')
            ) : (
              <></>
            )}
          </CitizenBox>
        )}
        keyFn={elm => elm.id.toString()}
        filterFn={(search, elm) => {
          if (page === 'employees') {
            //@ts-ignore
            return fullName(elm.firstName, elm.lastName)
              .toLowerCase()
              .startsWith(search.toLowerCase())
          } else return elm.email.toLowerCase().startsWith(search.toLowerCase())
        }}
        modalRender={(isOpen, onOpen, onClose) => (
          <EmployeeAddModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            refetch={() => {
              refetch()
              invites.refetch()
            }}
          />
        )}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          t('employees.delete-alert.description', { name: data?.name })
        }
        cancelButtonText={t('employees.delete-alert.cancel-button')}
        submitButtonText={t('employees.delete-alert.delete-button')}
        isWarning
        onClick={async payload => {
          deleteUser({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              toast({
                title: t('employees.delete-toast.title'),
                description: t('employees.delete-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...deleteEmployeeProps}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          t('employees.delete-invite-alert.description', { email: data?.email })
        }
        isWarning
        cancelButtonText={t('employees.delete-invite-alert.cancel-button')}
        submitButtonText={t('employees.delete-invite-alert.delete-button')}
        onClick={async payload => {
          deleteInvitation({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              invites.refetch()
              toast({
                title: t('employees.delete-invite-toast.title'),
                description: t('employees.delete-invite-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...deleteInviteProps}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          t('employees.resend-invite-alert.description', { email: data?.email })
        }
        cancelButtonText={t('employees.resend-invite-alert.cancel-button')}
        submitButtonText={t('employees.resend-invite-alert.send-button')}
        onClick={async payload => {
          resend({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              refetch()
              toast({
                title: t('employees.resend-invite-toast.title'),
                description: t('employees.resend-invite-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
            })
        }}
        {...sendInviteProps}
      />
    </>
  )
}

export default EmployeesScreen
