import React from 'react'
import {
  HStack,
  VStack,
  Stack,
  Text,
  Skeleton,
  StackProps,
  Box,
} from '@chakra-ui/react'
import { IoMdArrowDropright } from 'react-icons/io'
import { useIsTablet } from '../../../../../utils/useIsTablet'
import { mioRoom } from '../../../../../redux/services/mio-api'
import PillBadge from './PillBadge'
import { CText } from '../../../../../components/index'
import { CgPill } from 'react-icons/cg'
import { useIsDesktop } from '../../../../../utils/useIsDesktop'

const ROOM_DATA: mioRoom[] = [
  {
    timeToTake: '08:00',
    timeTaken: '08:12',
    status: 'taken',
  },
  {
    timeToTake: '12:00',
    timeTaken: '12:03',
    status: 'taken',
  },
  {
    timeToTake: '18:00',
    timeTaken: '12:03',
    status: 'overdue',
  },
  {
    timeToTake: '21:00',
    timeTaken: '12:03',
    status: 'notTaken',
  },
]

interface PillBoxProps {
  status?: mioRoom[]
  pillInRooms?: boolean[]
}

const PillBox: React.FC<PillBoxProps> = ({
  status,
  pillInRooms,
  ...props
}: PillBoxProps) => {
  const rightBorder: StackProps = {
    borderRight: '2px',
    borderColor: '#F0F0F0',
    borderRightStyle: 'solid',
  }

  const bottomBorder: StackProps = {
    borderBottom: '2px',
    borderColor: '#F0F0F0',
    borderBottomStyle: 'solid',
  }

  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  const data = status ? status : ROOM_DATA

  //TODO: better solution to this please
  // const getBgByStatus = (roomStatus: mioRoomStatus) => {
  //   switch (roomStatus) {
  //     case 'taken':
  //       return 'mio-greenLight'
  //     case 'overdue':
  //       return 'mio-red'
  //     case 'notTaken':
  //       return '#fff'
  //     case 'takenLate':
  //       return 'mio-yellow'
  //     case 'takenEarly':
  //       return 'mio-yellowLight'
  //     case 'empty':
  //       return 'mio-white'
  //   }
  // }

  const renderDesktopRooms = () => {
    return data.map(function (item, index) {
      return (
        <VStack
          key={index}
          flexDirection="column"
          bg="#fff"
          h="100%"
          w="25%"
          m="0 !important"
          justifyContent="center"
          // @ts-ignore
          style={index === data.length - 1 ? {} : rightBorder}
          borderColor="#f0f0f0 !important"
        >
          {pillInRooms && (
            <CgPill
              size="15px"
              color={pillInRooms[index] ? '#EFEFEF ' : '#3d494d'}
            />
          )}
          <PillBadge type={status ? item.status : undefined} lg></PillBadge>
          <HStack alignItems="center">
            {
              <Text color="mio-primaryText" fontSize="13" mr="0px">
                {status ? item.timeToTake : <Skeleton>12:25</Skeleton>}
              </Text>
            }
            a
            {item.timeTaken && (
              <>
                <IoMdArrowDropright
                  color="#3D494D"
                  size="20"
                  style={{ marginLeft: '0px', marginRight: '0px' }}
                />
                <Text
                  color="mio-headline"
                  fontSize="13"
                  fontWeight="700"
                  m="0px !important"
                >
                  {status ? item.timeTaken : <Skeleton>12:25</Skeleton>}
                </Text>
              </>
            )}
          </HStack>
        </VStack>
      )
    })
  }

  const renderMobileRooms = () => {
    return data.map(function (item, index) {
      return (
        <VStack
          index={index}
          flexDirection="column"
          bg="#fff"
          key="index"
          h="120px"
          w="100%"
          m="0 !important"
          justifyContent="center"
          //@ts-ignore
          style={index === data.length - 1 ? {} : bottomBorder}
          borderColor="#f0f0f0 !important"
        >
          {pillInRooms && (
            <CgPill
              size="15px"
              color={pillInRooms[index] ? '#EFEFEF ' : '#3d494d'}
            />
          )}

          <PillBadge type={status ? item.status : undefined} lg></PillBadge>
          <HStack alignItems="center">
            {
              <Text color="mio-primaryText" fontSize="13" mr="0px">
                {status ? item.timeToTake : <Skeleton>12:25</Skeleton>}
              </Text>
            }
            {item.timeTaken && (
              <>
                <IoMdArrowDropright
                  color="#3D494D"
                  size="20"
                  style={{ marginLeft: '0px', marginRight: '0px' }}
                />
                <Text
                  color="mio-headline"
                  fontSize="13"
                  fontWeight="700"
                  m="0px !important"
                >
                  {status ? item.timeTaken : <Skeleton>12:25</Skeleton>}
                </Text>
              </>
            )}
          </HStack>
        </VStack>
      )
    })
  }

  return (
    <Stack
      alignSelf="flex-start"
      data-testid="PillBox"
      width={{ base: '100%', md: '80%', lg: '600px' }}
      h={{ base: 'fit-content', md: '110px' }}
      borderRadius={{ base: '26px', md: '30px' }}
      bg="#fff"
      flexDirection={{ base: 'column', md: 'row' }}
      boxShadow="base"
      overflow="hidden"
      {...props}
    >
      {isTablet ? renderDesktopRooms() : renderMobileRooms()}
    </Stack>
  )
}

export default PillBox
