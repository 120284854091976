import { VStack, Stack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAssignEmployeeDistrictMutation } from '../../../../../redux/services/mio-api'
import { FormSelect, SettingsDrawer } from '../../../../../components/index'
import { Option } from '../../../../../types/domainTypes'

interface DistrictAddModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  id: number
  districts: Option[]
}

type DistrictAddModalFormData = {
  district: string
}

const DistrictAddModal: React.FC<DistrictAddModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  id,
  districts,
}) => {
  const { t } = useTranslation()

  //TODO:
  const [assignEmployeeDistrict, { isLoading }] =
    useAssignEmployeeDistrictMutation()

  const toast = useToast()

  const schema: yup.SchemaOf<DistrictAddModalFormData> = yup.object({
    district: yup.string().required(t('validation.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DistrictAddModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<DistrictAddModalFormData> = data => {
    assignEmployeeDistrict({
      districtId: parseInt(data.district),
      employeeId: id,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('employee.assign-district-toast.title'),
          description: t('employee.assign-district-toast.description'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])

  return (
    <SettingsDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('employee.add-district.title')}
      underTitle={t('employee.add-district.under-title')}
      bodyRender={() => (
        <Stack width="100%" mb="4" p="1px">
          <FormSelect
            label={t('employee.add-district.district')}
            error={errors.district}
            placeholder={t('employee.add-district.district')}
            registeredForm={register('district')}
            options={districts}
          />
        </Stack>
      )}
      cancelButtonTitle={t('employee.add-district.cancel-button')}
      submitButtonTitle={t('employee.add-district.add-button')}
      onSubmit={() => handleSubmit(onSubmit)}
      onSubmitLoading={isLoading}
      formOnSubmit={handleSubmit(onSubmit)}
    />
  )
}

export default DistrictAddModal
