import React from 'react'
import { Badge as CBadge } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export interface BadgeProps {
  children?: React.ReactNode
  status: "green" | "red"
}

const Badge: React.FC<BadgeProps> = props => {
  const { children, status, ...restProps } = props
  const { t } = useTranslation()

  const getType = () => {
    switch (status) {
      case "green":
        return {
          bg: 'mio-greenTint',
          color: '#3CA86A',
        }
      case "red":
        return {
          bg: 'mio-redTint',
          color: 'mio-red',
        }
    }
  }

  return (
    <CBadge
      textTransform="uppercase"
      fontSize="11"
      data-testid="Badge"
      color={getType().color}
      bg={getType().bg}
      display="flex"
      h="25px"
      justifyContent="center"
      alignItems="center"
      px="8px"
      borderRadius="6px"
      {...restProps}
    >
      {children}
    </CBadge>
  )
}

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
CBadge.id = 'CBadge'

export default Badge
