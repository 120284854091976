import RootRouter from './RootRouter'
import { Provider } from 'react-redux'
import store from './redux/store'
// import {
//   ChakraProvider as WChakraProvider,
//   theme as chakraTheme,
// } from '@wemuda/react-components'
import { ChakraProvider } from '@chakra-ui/react'
import chakraTheme from './chakraTheme'
import './i18n'
import RTKErrorLoggerHooksProvider from './containers/RTKErrorLoggerHooksProvider'
import JWTProvider from './containers/JWTProvider'
import { Suspense } from 'react'
import SuspenseLoader from './components/SuspenseLoader'
import CookiesConsentProvider from './containers/CookiesConsentProvider'

function App() {
  return (
    <Provider store={store}>
      <JWTProvider>
        <ChakraProvider theme={chakraTheme}>
          <Suspense fallback={<SuspenseLoader />}>
            <RTKErrorLoggerHooksProvider>
              <CookiesConsentProvider>
                <RootRouter />
              </CookiesConsentProvider>
            </RTKErrorLoggerHooksProvider>
          </Suspense>
        </ChakraProvider>
      </JWTProvider>
    </Provider>
  )
}

export default App
