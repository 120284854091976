import { Flex } from '@chakra-ui/layout'
import React from 'react'

interface MenuItemProps {
  text: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
}

const MenuItem: React.FC<MenuItemProps> = ({
  active,
  text,
  onClick,
  onMouseEnter,
  children,
}) => {
  return (
    <Flex
      onClick={onClick}
      padding="2"
      justifyContent="space-between"
      alignItems="center"
      pl="3"
      fontSize="13px"
      width="100%"
      backgroundColor={active ? 'mio-darkBackground' : 'white'}
      borderRadius="8"
      color={active ? 'mio-headline' : 'mio-secondaryText'}
      fontWeight={active ? '600' : '600'}
      transition="0.3s all"
      _hover={{ cursor: 'pointer', color: 'mio-headline' }}
      onMouseEnter={onMouseEnter}
      data-test={`menuItem-${text}`}
    >
      {text}
      {children}
    </Flex>
  )
}

export default MenuItem
