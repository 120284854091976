import { VStack, Stack, useToast, Switch } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
} from '../../../../../redux/services/mio-api'
import { firstCharToUpper, fullName } from '../../../../../utils/utils'
import {
  ActionContainer,
  CButton,
  FormInput,
  FormSelect,
  SettingsDrawer,
} from '../../../../../components/index'
import { Role, RoleEnum } from '../../../../../types/domainTypes'
import AsyncAlert from '../../../../../containers/AsyncAlert'
import useAsyncAlert from '../../../../../utils/useAsyncAlert'
import { useHistory } from 'react-router'
import useRoleCheck from '../../../../../utils/useRoleCheck'

interface EmployeeSettingsModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  refetch: () => void
  firstName?: string
  lastName?: string
  phoneNumber?: string
  role?: string
  id: number
}

type EmployeeSettingsModalFormData = {
  firstName: string
  lastName: string
  phoneNumber: string
  role?: string
}

const EmployeeSettingsModal: React.FC<EmployeeSettingsModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  refetch,
  firstName,
  lastName,
  phoneNumber,
  role,
  id,
}) => {
  const { t } = useTranslation()

  const history = useHistory()

  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [status, setStatus] = useState<'activated' | 'deactivated'>('activated')

  const toast = useToast()

  const schema: yup.SchemaOf<EmployeeSettingsModalFormData> = yup.object({
    firstName: yup.string().required(t('validation.required')),
    lastName: yup.string().required(t('validation.required')),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, t('validation.only-digits'))
      .min(8, t('validation.8-digits'))
      .max(8, t('validation.8-digits'))
      .transform(value => (value === '' ? undefined : value))
      .required(t('validation.required')),
    role: yup.string().transform(value => {
      if (value === '0') {
        return 'superUser'
      }
      if (value === '1') {
        return 'employeeAdmin'
      }
      if (value === '2') {
        return 'employee'
      }
      return undefined
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<EmployeeSettingsModalFormData>({ resolver: yupResolver(schema) })
  const onSubmit: SubmitHandler<EmployeeSettingsModalFormData> = data => {
    updateUser({
      id: id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      role: data.role as Role,
    })
      .unwrap()
      .then(() => {
        refetch()
        toast({
          title: t('employee.updated-toast.title'),
          description: t('employee.updated-toast.description'),
          duration: 4000,
          isClosable: true,
          status: 'success',
        })
        onClose()
      })
  }

  const {
    openAlertAndAwaitConfirmation: openDeleteEmployeeAlert,
    alertProps: deleteEmployeeProps,
  } = useAsyncAlert<
    {
      name: string
    },
    { id: number }
  >()

  // Hacky code - will replace
  let currentRole = ''

  let roles = [
    { value: RoleEnum.SUPERUSER, name: 'Superuser' },
    { value: RoleEnum.EMPLOYEEADMIN, name: 'Employee admin' },
    { value: RoleEnum.EMPLOYEE, name: 'Employee' },
  ]

  if (role === 'superUser') {
    currentRole = roles[0].name
    roles.splice(0, 1)
  } else if (role === 'employeeAdmin') {
    currentRole = roles[1].name
    roles.splice(1, 1)
  } else if (role === 'employee') {
    currentRole = roles[2].name
    roles.splice(2, 1)
  }

  const loggedInUserRole = useRoleCheck() as Role

  roles = roles.filter(r => {
    if (r.name === 'Superuser' && loggedInUserRole === 'employeeAdmin') {
      return false
    }
    return true
  })

  useEffect(() => {
    if (isOpen) {
      reset()
    }

    if (firstName) {
      setValue('firstName', firstCharToUpper(firstName))
    }

    if (lastName) {
      setValue('lastName', firstCharToUpper(lastName))
    }

    if (phoneNumber) {
      setValue('phoneNumber', phoneNumber)
    }
  }, [isOpen])

  return (
    <>
      <SettingsDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t('district.modal.title')}
        underTitle={t('employee.modal.under-title')}
        bodyRender={() => (
          <VStack>
            <Stack width="100%" spacing="20px" mb="30px">
              <FormInput
                error={errors.firstName}
                label={t('employee.modal.firstName')}
                placeholder={t('employee.modal.firstName')}
                registeredForm={register('firstName')}
              />
              <FormInput
                error={errors.lastName}
                label={t('employee.modal.lastName')}
                placeholder={t('employee.modal.lastName')}
                registeredForm={register('lastName')}
              />
              <FormInput
                error={errors.phoneNumber}
                label={t('employee.modal.phoneNumber')}
                placeholder={t('employee.modal.phoneNumber')}
                inputMode="tel"
                registeredForm={register('phoneNumber')}
              />
              <FormSelect
                error={errors.role}
                label={t('employee.modal.role')}
                placeholder={currentRole} // {role ? role : t('employee.modal.role')}
                registeredForm={register('role')}
                description={t('employee.modal.role-description')}
                options={roles}
              />
            </Stack>
            {/* <ActionContainer
              w="100%"
              title={t('employee.modal.status.de-activate')}
              description={t('employee.modal.status.description')}
            >
              <Switch size="lg" colorScheme="green" />
            </ActionContainer> */}
            <ActionContainer
              w="100%"
              title={t('employee.modal.delete-title')}
              description={t('employee.modal.delete-description')}
            >
              <CButton
                btnType="warning"
                onClick={async () => {
                  await openDeleteEmployeeAlert(
                    {
                      name: fullName(firstName as string, lastName as string),
                    },
                    { id: id }
                  )
                }}
              >
                {t('employee.modal.delete-button')}
              </CButton>
            </ActionContainer>
          </VStack>
        )}
        cancelButtonTitle={t('district.modal.cancel-button')}
        submitButtonTitle={t('district.modal.save-button')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitLoading={isLoading}
        formOnSubmit={handleSubmit(onSubmit)}
      />
      <AsyncAlert
        title="Are you sure?"
        bodyText={data =>
          t('employee.delete-alert.description', { name: data?.name })
        }
        cancelButtonText="Cancel"
        submitButtonText="Delete"
        isWarning
        onClick={async payload => {
          deleteUser({
            id: payload?.id as number,
          })
            .unwrap()
            .then(() => {
              toast({
                title: t('employee.delete-toast.title'),
                description: t('employee.delete-toast.description'),
                duration: 4000,
                isClosable: true,
                status: 'success',
              })
              history.push('/dashboard/employees')
            })
        }}
        {...deleteEmployeeProps}
      />
    </>
  )
}

export default EmployeeSettingsModal
