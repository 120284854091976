import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import NotFoundScreen from './components/NotFoundScreen'
import DashboardScreen from './screens/Dashboard/DashboardScreen'
import CookiesPolicyScreen from './screens/Legal/screens/CookiesPolicyScreen'
import PrivacyPolicyScreen from './screens/Legal/screens/PrivacyPolicyScreen'
import TermsOfUseScreen from './screens/Legal/screens/TermsOfUseScreen'
import LoginScreen from './screens/Onboarding/Login/LoginScreen'
import ResetPasswordScreen from './screens/Onboarding/ResetPassword/ResetPasswordScreen'
import EmployeeSignupScreen from './screens/Onboarding/Signup/EmployeeSignupScreen'
import SignupScreen from './screens/Onboarding/Signup/SignupScreen'
import ForgotPasswordScreen from './screens/Onboarding/ResetPassword/ForgotPasswordScreen'

const RootRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/sign-up">
          <Header />
          <SignupScreen />
        </Route>
        <Route path="/forgot-password">
          <Header />
          <ForgotPasswordScreen />
        </Route>
        <Route path="/reset-password">
          <Header />
          <ResetPasswordScreen />
        </Route>
        <Route path="/confirm-email">
          <Header />
          <LoginScreen isConfirmingEmail={true} />
        </Route>
        <Route path="/accept-invite">
          <Header />
          <EmployeeSignupScreen />
        </Route>
        <Route path="/dashboard">
          <DashboardScreen />
        </Route>
        <Route path="/legal/cookies">
          <CookiesPolicyScreen />
        </Route>
        <Route path="/legal/terms-of-use">
          <TermsOfUseScreen />
        </Route>
        <Route path="/legal/privacy-policy">
          <PrivacyPolicyScreen />
        </Route>
        <Route exact path="/">
          <Header />
          <LoginScreen />
        </Route>
        <Route path="*">
          <NotFoundScreen />
        </Route>
      </Switch>
    </Router>
  )
}

export default RootRouter
