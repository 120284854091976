import { Box, BoxProps } from '@chakra-ui/layout'
import { BackgroundProps, LayoutProps } from '@chakra-ui/styled-system'
import React from 'react'

interface Props {
  w?: LayoutProps['w']
  minW?: LayoutProps['minW']
  bgColor?: BackgroundProps['bgColor']
}

const Card: React.FC<Props & BoxProps> = props => {
  const { w, minW, bgColor, children, ...restProps } = props
  return (
    <Box
      boxShadow="base"
      borderRadius="20px"
      p="5"
      bgColor={bgColor || 'white'}
      w={w}
      minW={minW}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default Card
