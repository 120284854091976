import React from 'react'
import { Flex, HStack, Link, Icon, Skeleton } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { ImPhone } from 'react-icons/im'

interface Props {
  address?: string
  phone?: string
}

export default function CitizenInfo({ address, phone }: Props) {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
      alignItems={{ base: 'flex-start', md: 'flex-start', lg: 'flex-start' }}
    >
      <HStack
        mb={{ base: '10px', md: '0px', lg: '10px' }}
        mr={{ base: '0px', md: '20px', lg: '0px' }}
      >
        <Icon as={FaMapMarkerAlt} color="mio-headline" />
        <Link fontSize="14px" color="mio-headline">
          {address ? address : <Skeleton>Niels Hemmingensesgade 20B</Skeleton>}
        </Link>
      </HStack>
      <HStack>
        <Icon as={ImPhone} color="mio-headline" />
        <Link fontSize="14px" color="mio-headline">
          {phone ? phone : <Skeleton>12121212</Skeleton>}
        </Link>
      </HStack>
    </Flex>
  )
}
