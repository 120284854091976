import Icon from '@chakra-ui/icon'
import { Box, Flex, Spacer } from '@chakra-ui/layout'
import React, { MouseEventHandler } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { CText, IconButton } from '../../../../../components/index'

interface DistrictsCardProps {
  name: string
  citizensCount: number
  onClick: MouseEventHandler<HTMLDivElement>
  onPressDelete?: MouseEventHandler<HTMLButtonElement>
}

const DistrictsCard: React.FC<DistrictsCardProps> = ({
  name,
  citizensCount,
  onClick,
  onPressDelete,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      padding="20px"
      borderRadius="20px"
      minW="230px"
      bg="mio-white"
      cursor="pointer"
      shadow="base"
      role="group"
      _hover={{ shadow: 'lg' }}
      transition="0.4s all"
      onClick={onClick}
    >
      <Flex alignItems="center">
        <Box>
          <CText.SectionHeaderLg>{name}</CText.SectionHeaderLg>
          <CText.SecondaryText>
            <span>{citizensCount}</span> {t('districts.card.citizens')}
          </CText.SecondaryText>
        </Box>
        <Spacer />
        {onPressDelete ? (
          <IconButton
            aria-label={t('employee.add-district.remove-district')}
            tooltip={t('employee.add-district.remove-district')}
            icon={<FaTrash />}
            onClick={onPressDelete}
          />
        ) : (
          <Icon
            _groupHover={{ transform: 'translateX(4px)' }}
            transition="0.5s all"
            color="mio-primaryText"
            h="22px"
            w="22px"
            as={MdChevronRight}
          />
        )}
      </Flex>
    </Box>
  )
}

export default DistrictsCard
