import React from 'react'
import { Box, Stack, BoxProps } from '@chakra-ui/react'

import { CText } from './index'

interface PageHeaderProps {
  title: string | undefined
  sampleText: string
  underTitle?: string
  children?: React.ReactChild
}

const PageHeader: React.FC<PageHeaderProps & BoxProps> = ({
  title,
  sampleText,
  underTitle,
  children,
}) => {
  return (
    <>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        //alignItems={underTitle ? 'center' : 'center'}
        alignItems={{
          base: 'flex-start',
          lg: underTitle ? 'flex-start' : 'center',
        }}
        mb="30px !important"
      >
        <Box>
          <CText.Header
            isLoading={!title}
            sampleText={sampleText}
            mr={{ base: '0px', lg: '20px' }}
          >
            {title}
          </CText.Header>

          <CText.PrimaryText fontSize="15px">{underTitle}</CText.PrimaryText>
        </Box>
        <Stack
          direction="row"
          mt={{ base: '16px !important', lg: '0px !important' }}
          spacing="10px"
        >
          {children}
        </Stack>
      </Stack>
    </>
  )
}

export default PageHeader
