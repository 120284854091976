import React from 'react'
import {
  Input as ChakraInput,
  InputProps as ChakraProps,
} from '@chakra-ui/react'

export interface CInputProps {
  placeholder: string
  tinted?: boolean
  label?: string
}

const CInput = React.forwardRef<HTMLInputElement, CInputProps & ChakraProps>(
  (props, ref) => {
    const tintedStyles: React.CSSProperties = {
      borderColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      color: '#3D494D',
    }
    const regularStyles: React.CSSProperties = {
      borderColor: '#F2F5F6',
      backgroundColor: '#F2F5F6',
      color: 'mio-headline',
    }

    const { placeholder, tinted, label, ...restProps } = props

    return (
      <ChakraInput
        ref={ref}
        style={props.tinted ? tintedStyles : regularStyles}
        backgroundColor="#FFFFFF"
        boxShadow={props.tinted ? 'base' : 'none'}
        h={{ base: '54px', lg: '40px' }}
        fontSize={{ base: '16px', lg: '13px' }}
        borderRadius={{ base: 14, lg: 10 }}
        focusBorderColor="#7C7C7C"
        borderWidth="1"
        w="100% !important"
        data-test={`input-${placeholder}`}
        placeholder={props.placeholder}
        {...restProps}
      />
    )
  }
)

// https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
CInput.id = 'Input'

export default CInput
